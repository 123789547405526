<template>
  <div>

    <div class="tableMainWrapBox" style="margin-top: 25px; position: relative" id="preview-record">
      <div class="tableScrollWrap" ref="newOrdertable">
        <table>
          <thead>
            <tr>
              <th>
                <div class="ctmCheckWrap">
                  <label class="ctmTableCheckContainer">
                    <input 
                      type="checkbox"
                      @click="toggleIsCheckedAll()"
                      :checked="
                        checkedItems.length != 0 &&
                        checkedItems.length == orders.length
                      "
                      :disabled="tourIsActive || addOrderForm.isOpen || updateOrderForm.isPreview"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <div class="ctmTableImgWrap">
                    <p data-toggle="tooltip" data-placement="top" title="Products">Products</p>
                  </div>
                </div>
              </th>
              <th class="ctmPr-3">
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Order No.">Order No.</p>
                </div>
              </th>
              <th class="ctmPr-3">
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                </div>
              </th>
              <th class="ctmPr-3">
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Email">Email</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Date/Time">Date/Time</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Total">Total</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Payment">Payment</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Status">Status</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Shipping Address">Shipping Address</p>
                </div>
              </th>
              <th>
                <div class="ctmTableTitleWrap">
                  <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="ordersLoading">
            <tr v-for="i in 5" :key="i">
              <td>
                <div class="ctmCheckWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="17"
                    :height="20"
                    animation="fade"
                  />
                  <div class="orderTableProducts">
                    <vue-skeleton-loader
                      type="rect"
                      :width="100"
                      :height="20"
                      animation="fade"
                    />
                  </div>
                </div>
              </td>
              <td class="mainTableTitle">
                <div class="orderIdTxtWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="80"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="110"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="newStatusTag">
                  <vue-skeleton-loader
                    type="rect"
                    :width="150"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="180"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="ctmDataTableActionBtn">
                  <vue-skeleton-loader
                    type="rect"
                    :width="50"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-if="orders.length == 0">
              <td colspan="10" style="text-align: center">
                <p class="mb-0">No Orders Available</p>
              </td>
            </tr>
            <tr v-for="(order, index) in orders" :key="index">

              <!-- checkbox and images block starts here -->
              <td @click.prevent="orderPreview(order, index,'input',$event)" >
                <div class="ctmCheckWrap" data-open="true">
                  <label class="ctmTableCheckContainer" data-open="false" @click="checkedItemHandle(order)">
                    <input 
                      type="checkbox"
                      v-model="checkedItems"
                      :value="order.id"
                      data-open="false"
                    />
                    <span class="checkmark" data-open="false"></span>
                  </label>
                  <div class="orderTableProducts" data-open="true">
                    <div class="ctmDataTableImgBox" v-for="(item, i) in order.items.slice(0,3)" :key="'item' + i" data-open="true">
                      <p v-if="i >= 3" data-open="true">+{{ order.items.length - 3 }}</p>
                      <img 
                        :src="item.image ? item.type == 'bundle' ? base_image_url + settings.store_id + '/product-bundle/' + item.image :
                        base_image_url + settings.store_id + '/product/' + item.image : '/images/no_image.png'"
                        data-open="true" 
                        @error="handleImageError"
                      />
                    </div>
                    <div class="ctmDataTableImgBox" v-if="order.items.length > 3" data-open="true">
                      <p data-open="true">+{{ order.items.length - 3 }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <!-- checkbox and images block ends here -->

              <!-- order no. block starts here -->
              <td class="mainTableTitle" @click.prevent="orderPreview(order, index,'input',$event)" data-open="true">
                <div class="orderIdTxtWrap" data-open="true">
                  <p data-open="true">#{{ order.order_no }}</p>
                  <button @click.prevent="copyOrderNo(order)" data-open="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.236 10.642">
                      <g id="copy" transform="translate(0.05 0.05)">
                        <path id="Path_127" data-name="Path 127" d="M5.625,14.516h.7v.7h5.8v-7.2h-.7v-.7h1.406v8.609h-7.2V14.516Z" transform="translate(-3.693 -5.38)" fill="#5ab734" stroke="#5ab734" stroke-width="0.1"/>
                        <path id="Path_128" data-name="Path 128" d="M19.4,1.125V9.558H12.375V4.142l3.017-3.017Zm-6.325,7.73H18.7V1.828H16.416V5.166H13.077Zm0-4.392h2.635V1.828h-.03L13.078,4.433Z" transform="translate(-12.375 -1.125)" fill="#5ab734" stroke="#5ab734" stroke-width="0.1"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </td>
              <!-- order no. block ends here -->

              <!-- customer name block starts here -->
              <td @click.prevent="orderPreview(order, index,'solid',$event)">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p data-open="true">
                    {{ order.customer.first_name + " " + order.customer.last_name }}
                  </p>
                </div>
              </td>
              <!-- customer name block ends here -->

              <!-- customer email block starts here -->
              <td @click.prevent="orderPreview(order, index,'solid',$event)">
                <div class="tableBodyCtmTxt">
                  <p>{{ order.customer.email }}</p>
                </div>
              </td>
              <!-- customer email block ends here -->

              <!-- date block starts here -->
              <td style="position: relative" @click.prevent="orderPreview(order, index,'solid',$event)" data-open="true">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p style="font-size: 12px" data-open="true">
                    {{
                      order.created_at
                        ? new Date(order.created_at).toLocaleString("en-US", {
                            year: "numeric",
                          }) +
                          "-" +
                          new Date(order.created_at).toLocaleString("en-US", {
                            month: "numeric",
                          }) +
                          "-" +
                          new Date(order.created_at).toLocaleString("en-US", {
                            day: "numeric",
                          }) +
                          " | " +
                          new Date(order.created_at).toLocaleTimeString("en-US")
                        : "--"
                      }}
                  </p>
                </div>
              </td>
              <!-- date block ends here -->

              <!-- total block starts here -->
              <td style="position: relative" @click.prevent="orderPreview(order, index,'solid',$event)" data-open="true">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p data-open="true">
                    <b data-open="true">
                      {{
                        order.payment.net_amount ? settings.currency_unit ? settings.currency_symbol + " " +parseFloat(order.payment.net_amount).toLocaleString( "ur-PK",
                            {
                              
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0
                        : settings.currency_symbol + " " + parseFloat(order.payment.net_amount).toLocaleString( "ur-PK",
                            {
                              
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                      }}
                    </b>
                  </p>
                </div>
              </td>
              <!-- total block ends here -->

              <!-- payment method block starts here -->
              <td style="position: relative" @click.prevent="orderPreview(order, index,'solid',$event)" data-open="true">
                <div class="tableBodyCtmTxt" data-open="true">
                  <p data-open="true">{{ order.payment.payment_method.type }}</p>
                </div>
              </td>
              <!-- payment method block ends here -->

              <!-- status block starts here -->
              <td style="position: relative" @click.prevent="orderPreview(order, index,'input',$event)" data-open="true">
                <div class="statusTableColumn" data-open="true">
                  <select 
                    name="status" 
                    id="status" 
                    :class="order.status" 
                    v-model="order.status" 
                    @mouseenter="orderSelection(order)" 
                    @change="handleOrderStatusChange(order, index)"
                    :disabled="tourIsActive || (!order.isOpen && (order.status == 'Delivered' || order.status == 'Cancelled' || 
                    order.status == 'Deleted' || order.status == 'Refunded'))"
                    data-open="false"
                  >
                    <option value="Paid" :class="order.status == 'Paid' ? 'Paid' : ''" data-open="false">Paid</option>
                    <option value="Pending" :class="order.status == 'Pending' ? 'Pending' : ''" data-open="false">Pending</option>
                    <option value="Confirmed" :class="order.status == 'Confirmed' ? 'Confirmed' : ''" data-open="false">Confirmed</option>
                    <option value="Dispatched" :class="order.status == 'Dispatched' ? 'Dispatched' : ''" data-open="false">Dispatched</option>
                    <option value="Delivered" :class="order.status == 'Delivered' ? 'Delivered' : ''" data-open="false">Delivered</option>
                    <option value="Cancelled" :class="order.status == 'Cancelled' ? 'Cancelled' : ''" data-open="false">Cancelled</option>
                    <option value="Refunded" :class="order.status == 'Refunded' ? 'Refunded' : ''" data-open="false">Refunded</option>
                    <option value="Deleted" :class="order.status == 'Deleted' ? 'Cancelled' : ''" data-open="false" v-if="order.status == 'Deleted'">Deleted</option>
                  </select>
                </div>
              </td>
              <!-- status block ends here -->

              <!-- shipping detail block starts here -->
              <td @click.prevent="orderPreview(order, index,'solid',$event)">
                <div class="tableBodyCtmTxt" v-if="order.order_details" data-open="true">
                  <p data-open="true">{{ order.order_details.shipping_address ? order.order_details.shipping_address.length > 25 ? order.order_details.shipping_address.substr(0, 25) + "..." : order.order_details.shipping_address : '--' }}</p>
                </div>
                <div class="tableBodyCtmTxt" v-else>
                  <p data-open="true">{{ order.customer.address_line_1 ?  order.customer.address_line_1.length > 25 ?  order.customer.address_line_1.substr(0, 25) + "..." : order.customer.address_line_1 : '--' }}</p>
                </div>
              </td>
              <!-- shipping detail block ends here -->

              <!-- action block starts here -->
              <td @click.prevent="orderPreview(order, index,'input',$event)" data-open="true">
                <div class="ctmDataTableActionBtn" v-if="isDeletingItem(order.id) && deleteLoader" data-open="true">
                  <loaderBtn/>
                </div>
                <div class="ctmDataTableActionBtn" data-open="true" v-else>
                  <button 
                    class="delFunction" 
                    @click.prevent="deleteOrder(order, index)" 
                    data-open="false" 
                    v-if="order.status != 'Deleted'" 
                    :disabled="tourIsActive"
                  >
                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                  </button>
                </div>
              </td>
              <!-- action block ends here -->

            </tr>

            <!-- load more block starts here -->
            <tr v-if="isPending">
              <td>
                <div class="ctmCheckWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="17"
                    :height="20"
                    animation="fade"
                  />
                  <div class="orderTableProducts">
                    <vue-skeleton-loader
                      type="rect"
                      :width="100"
                      :height="20"
                      animation="fade"
                    />
                  </div>
                </div>
              </td>
              <td class="mainTableTitle">
                <div class="orderIdTxtWrap">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="120"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="100"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="80"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="tableBodyCtmTxt">
                  <vue-skeleton-loader
                    type="rect"
                    :width="110"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td style="position: relative">
                <div class="newStatusTag">
                  <vue-skeleton-loader
                    type="rect"
                    :width="150"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="editFieldTitle">
                  <vue-skeleton-loader
                    type="rect"
                    :width="180"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
              <td>
                <div class="ctmDataTableActionBtn">
                  <vue-skeleton-loader
                    type="rect"
                    :width="50"
                    :height="20"
                    animation="fade"
                  />
                </div>
              </td>
            </tr>
            <!-- load more block ends here -->

          </tbody>
        </table>
      </div>

      <!-- add order form starts here -->
      <ValidationObserver ref="addOrderFormObserver">
        <div class="productSlideBox" ref="viewAddInputForm" :class="addOrderForm.isOpen ? 'activeRow' : ''">
          
          <div class="tableActionBtns" id="order-details-preview">
            <div class="productTableSideBar">
              <div class="row">
                <div class="col-md-6">
                  <div class="viewOrderTitle">
                    <p>
                      {{ 
                        addOrderForm.customer.first_name + " " + addOrderForm.customer.last_name
                      }}
                      ({{
                        addOrderForm.order_no
                      }})
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="orderViewNav">
                    <ul>
                      <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                        <li class="sm-extand-hide">
                          <button @click.prevent="expand('add-order')" :disabled="tourIsActive">
                            <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                              <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                <path d="m16.5 5.5v-4.978l-5.5.014" />
                                <path d="m16.5.522-6 5.907" />
                                <path d="m11 16.521 5.5.002-.013-5.5" />
                                <path d="m16.5 16.429-6-5.907" />
                                <path d="m.5 5.5v-5h5.5" />
                                <path d="m6.5 6.429-6-5.907" />
                                <path d="m6 16.516-5.5.007v-5.023" />
                                <path d="m6.5 10.5-6 6" />
                              </g>
                            </svg>
                          </button>
                        </li>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                        <li>
                          <button @click.prevent="newOrderOperations('close','with-confirmation')" id="closeSide" :disabled="tourIsActive">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                              <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                  <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                  <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </li>
                      </el-tooltip>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="sectionTabsLink">
              <ul onclick="window.myFunction(event)">
                <li><a href="#addOrderCustomerDetail" id="customer-details-navbtn" v-show="!tourIsActive">Customer Details</a></li>
                <li><a href="#addOrderActivities" id="order-activity-navbtn" v-show="!tourIsActive">Order Activity</a></li>
                <li><a href="#AddOrderDetailsSection" id="order-details-navbtn" v-show="!tourIsActive">Order Details</a></li>
              </ul>
              <div class="updateBtnBox">
                <loaderBtn v-if="orderLoader"/>
                <button 
                  @click.prevent="validate('add')" 
                  id="save-order-btn" 
                  :disabled="tourIsActive"
                  v-else
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div class="productTableInfoBlock">

            <!-- products block starts here -->
            <div class="productTableInfoWhiteBox" id="addOrderCustomerDetail">
              <div class="orderAdminTitle" v-if="addOrderForm.created_by">
                <p>
                  Created by: <span>{{ addOrderForm.created_by }}</span>
                </p>
              </div>
              <div class="addOrderTable" id="product-section">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="addOrderForm.items.length == 0">
                      <td colspan="7">
                        The cart has no items at the moment
                      </td>
                    </tr>
                    <tr v-for="(item, index) in addOrderForm.items" :key="index">
                      <td>
                        <div class="productOrderTableImg">
                          <img 
                            :src="item.image ? item.type == 'bundle' ? base_image_url + settings.store_id + '/product-bundle/' + item.image :
                            base_image_url + settings.store_id + '/product/' + item.image : '/images/no_image.png'"
                            @error="handleImageError"
                          />
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>{{ item.name.length > 15 ? item.name.substr(0,15) + "..." : item.name }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTagTxt">
                          <p>{{ item.type }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>
                            {{
                              settings.currency_symbol + " " + item.variant.price.toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="quantityOrderProductBox" v-if="item.type != 'bundle'">
                          <button
                            class="miniusIconBtn"
                            @click.prevent="productQuantityChangeHandle('add-order','decr',index)"
                            :disabled="tourIsActive"
                          >
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g id="_-" data-name=" -" class="cls-1">
                                  <g class="cls-1">
                                    <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                          <input
                            type="number"
                            name="stock"
                            id="stock"
                            placeholder="0"
                            v-model="item.variant.qty"
                            @blur="productQuantityChangeCheck('add-order',index)"
                            :disabled="tourIsActive"
                          />
                          <button
                            class="plusIconBtn"
                            @click.prevent="productQuantityChangeHandle('add-order','incr',index)"
                            :disabled="tourIsActive"
                          >
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g id="_" data-name=" " class="cls-1">
                                  <g class="cls-1">
                                    <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                        <div class="quantityOrderProductBox" v-else>
                          <p style="text-align: center; margin-bottom: 0px">--</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>
                            {{
                              calculateProductTotal(item,'add-order') ? settings.currency_symbol + " " + calculateProductTotal(item,'add-order').toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) : ''
                            }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="ctmDataTableActionBtn">
                          <button
                            class="delFunction"
                            @click.prevent="removeOrderItem(item, 'add-order', index)"
                            :disabled="tourIsActive"
                          >
                            <i aria-hidden="true" class="fa fa-trash-o"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span class="text-danger" v-show="addOrderForm.items.length == 0">Cart item(s) must be added.</span>
              <div class="orderAddProductBtn">
                <button 
                  @click.prevent="openCartItemsModal('add-order')"
                  :disabled="tourIsActive"
                  id="add-product-btn"
                >
                  + Add Cart Items
                </button>
              </div>
            </div>
            <!-- products block ends here -->

            <!-- customer block starts here -->
            <div class="productTableInfoWhiteBox" id="customerSection">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Customer Details</h5>
              </div>
              <form id="customer-section">

                <div class="tableFromFieldBox" @click.prevent="productSelectionMessage('add-order')" id="add-customer-dropdown">
                  <label for="addFormCustomer">Customer<span>*</span></label>
                  <div class="vueSelectBlock">
                    <Multiselect
                      ref="addCustomerMultiSelect"
                      v-model="addOrderForm.customer"
                      :options="list_customer"
                      placeholder="Select one"
                      label="first_name"
                      track-by="id"
                      :open-direction="'bottom'"
                      :allow-empty="false"
                      deselect-label="Can't remove the selected item"
                      :custom-label="customerCustomLabel"
                      @close="onTouchCustomer('add-order')"
                      @select="handleSelectedCustomer($event,'add-order')"
                      :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                    >
                      <span slot="noResult">
                        No customer found matching the searched keyword.
                      </span>
                      <li class="multiselect__element" slot="afterList" @click.prevent="openCustomerForm('add-order')">
                        <span class="multiselect__option">Add New Customer</span>
                      </li>
                    </Multiselect>
                    <span class="text-danger" v-if="customerFieldRequired">The customer field is required</span>
                  </div>
                </div>
                <div class="customerDetailList" id="customer-basic-details">
                  <ul>
                    <li>
                      <h6>Name:</h6>
                      <p>
                        {{
                          addOrderForm.customer.first_name +
                          " " +
                          addOrderForm.customer.last_name
                        }}
                      </p>
                    </li>
                    <li>
                      <h6>Email:</h6>
                      <p>{{ addOrderForm.customer.email }}</p>
                    </li>
                    <li>
                      <h6>Contact Number:</h6>
                      <p>{{ addOrderForm.customer.mobile_no }}</p>
                    </li>
                    <li>
                      <h6>Address:</h6>
                      <p>
                        {{addOrderForm.customer.address_line_2 != null ?
                          addOrderForm.customer.address_line_1 +
                          " " +
                          addOrderForm.customer.address_line_2 :
                          addOrderForm.customer.address_line_1
                        }}
                      </p>
                    </li>
                  </ul>
                </div>
                <!-- shipping details start here -->
                <div class="productTableInfoWhiteBoxTitle">
                  <h5>Shipping Details</h5>
                </div>
                <div class="row" id="customer-shipping-details">
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormShippingFirstName">First Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormShippingFirstName"
                            id="addFormShippingFirstName"
                            placeholder="First name"
                            v-model="addOrderForm.shipping.shipping_first_name"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                            @input="onChangeInField('add-order','first_name')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormShippingLastname">Last Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormShippingLastname"
                            id="addFormShippingLastname"
                            placeholder="Last Nnme"
                            v-model="addOrderForm.shipping.shipping_last_name"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                            @input="onChangeInField('add-order','last_name')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping email" rules="required|email|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormShippingEmail">Email<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormShippingEmail"
                            id="addFormShippingEmail"
                            placeholder="Email"
                            v-model="addOrderForm.shipping.shipping_email"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                            @input="onChangeInField('add-order','email')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping contact no." :rules="shippingCountryIsSelected ? 'required' : ''" v-slot="{ errors }">
                      <div class="tableFromFieldBox" id="shipping-contact">
                        <label for="addFormShippingContactNo">Contact No.<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <vue-tel-input 
                            v-model="addOrderForm.shipping.shipping_contact_no"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="ContactFieldOptions"
                            ref="shippingContactNoField"
                            :dropdownOptions="ContactFieldDropDownOptions"
                            :validCharactersOnly=true 
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                            @input="shippingContactInputHandle"
                            @validate="shippingValidateContactNo"
                            @country-changed="shippingCountryChangeHandle"
                            @focus="shippingContactFieldFocusHandle"
                            @close="shippingDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-else-if="!shippingCountryIsSelected && shippingContactFieldTouched">
                            Select a country from the dropdown menu or add the country code before the contact no.
                          </span>
                          <span class="text-danger" v-else-if="!shippingContactIsValid && shippingContactFieldTouched">
                            Enter a valid contact no.
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping country" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormShippingCountry">Country<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedShippingCountry"
                            id="addFormShippingCountry"
                            :options="availableCountries"
                            placeholder="Select country"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            @select="countrySelectHandle($event,'shipping')"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                          >
                            <span slot="noResult">No country found matching the searched keyword.</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping state" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormShippingState">State<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedShippingState"
                            id="addFormShippingState"
                            :options="selectedShippingCountryStates"
                            :placeholder="shippingCountryStatesLoading ? 'Loading states....' : 'Select state'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :loading="shippingCountryStatesLoading"
                            @select="stateSelectHandle($event,'shipping')"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the states, please select a country.</span>
                            <span slot="noResult">No state found matching the searched keyword.</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping city" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormShippingCity">City<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedShippingCity"
                            id="addFormShippingCity"
                            :options="selectedShippingStateCities"
                            :placeholder="shippingStateCitiesLoading ? 'Loading cities....' : 'Select city'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            @select="citySelectHandle($event,'shipping')"
                            :loading="shippingStateCitiesLoading"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the cities, please select a state.</span>
                            <span slot="noResult">No city found matching the searched keyword.</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping postal code" rules="required|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormCustomerShippingPostalCode">Shipping Postal Code<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormCustomerShippingPostalCode"
                            id="addFormCustomerShippingPostalCode"
                            placeholder="Shipping postal code"
                            v-model="addOrderForm.shipping.shipping_postal_code"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                            @input="onChangeInField('add-order','postal-code')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="shipping address" rules="required|max:500" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormCustomerShippingAddress">Shipping Address<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormCustomerShippingAddress"
                            id="addFormCustomerShippingAddress"
                            placeholder="Shipping address"
                            v-model="shipping_address"
                            :disabled="tourIsActive || (addOrderForm.items.length == 0)"
                            @input="onChangeInField('add-order','address')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- shipping details end here -->

                <!-- billing details start here -->
                <div class="productTableInfoWhiteBoxTitle">
                  <div class="row align-items-center">
                    <div class="col-md-6" >
                      <h5>Billing Details</h5>
                    </div>
                    <div class="col-md-6">
                      <div class="ctmShippingLink" id="same-billing-checkbox">
                        <label> 
                          Same as Shipping
                          <input 
                            type="checkbox" 
                            v-model="isSameBilling" 
                            @change="onChangeSameBilling($event,'add-order')" 
                            :disabled="tourIsActive || !addOrderForm.customer.id"
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" id="customer-billing-details">
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingFirstName">First Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormBillingFirstName"
                            id="addFormBillingFirstName"
                            placeholder="First name"
                            v-model="addOrderForm.billing.billing_first_name"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingLastName">Last Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormBillingLastName"
                            id="addFormBillingLastName"
                            placeholder="Last name"
                            v-model="addOrderForm.billing.billing_last_name"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing email" rules="required|email|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingEmail">Email<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormBillingEmail"
                            id="addFormBillingEmail"
                            placeholder="Email"
                            v-model="addOrderForm.billing.billing_email"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')" id="billing-contact">
                    <ValidationProvider name="billing contact no." :rules="billingCountryIsSelected ? 'required' : ''" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="cBCNumber">Contact No.<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <vue-tel-input 
                            v-model="addOrderForm.billing.billing_contact_no"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="ContactFieldOptions"
                            ref="billingContactNoField"
                            :dropdownOptions="ContactFieldDropDownOptions"
                            :validCharactersOnly=true 
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                            @input="billingContactInputHandle"
                            @validate="billingValidateContactNo"
                            @country-changed="billingCountryChangeHandle"
                            @focus="billingContactFieldFocusHandle"
                            @close="billingDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-else-if="!billingCountryIsSelected && billingContactFieldTouched">
                            Select a country from the dropdown menu or add the country code before the contact no.
                          </span>
                          <span class="text-danger" v-else-if="!billingContactIsValid && billingContactFieldTouched">
                            Enter a valid contact no.
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing country" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingCountry">Country<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingCountry"
                            id="addFormBillingCountry"
                            :options="availableCountries"
                            placeholder="Select country"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            @select="countrySelectHandle($event,'billing')"
                            :disabled="tourIsActive || isSameBilling  || (addOrderForm.items.length == 0)"
                          >
                            <span slot="noResult">No country found matching the searched keyword.</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing state" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingState">State<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingState"
                            id="addFormBillingState"
                            :options="selectedBillingCountryStates"
                            :placeholder="billingCountryStatesLoading ? 'Loading states....' : 'Select state'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :loading="billingCountryStatesLoading"
                            @select="stateSelectHandle($event,'billing')"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the states, please select a country.</span>
                            <span slot="noResult">No state found matching the searched keyword.</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing city" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingCity">City<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingCity"
                            id="addFormBillingCity"
                            :options="selectedBillingStateCities"
                            :placeholder="billingStateCitiesLoading ? 'Loading cities....' : 'Select city'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :loading="billingStateCitiesLoading"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the cities, please select a state.</span>
                            <span slot="noResult">No city found matching the searched keyword.</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('add-order')">
                    <ValidationProvider name="billing postal code" rules="required|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormCustomerBillingPostalCode">Billing Postal Code<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormCustomerBillingPostalCode"
                            id="addFormCustomerBillingPostalCode"
                            placeholder="Billing postal code"
                            v-model="addOrderForm.billing.billing_postal_code"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12" @click.prevent="productSelectionMessage('add-order')" id="addOrderActivities">
                    <ValidationProvider name="billing address" rules="required|max:500" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormBillingAddress">Billing Address<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="addFormBillingAddress"
                            id="addFormBillingAddress"
                            placeholder="Shipping address"
                            v-model="billing_address"
                            :disabled="tourIsActive || isSameBilling || (addOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- billing details end here -->

              </form>
            </div>
            <!-- customer block ends here -->

            <!-- order activity block starts here-->
            <div class="productTableInfoWhiteBox" id="orderActivitySection">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Order Activity</h5>
              </div>
              <div class="activityList">
                <div class="orderActivityList">
                  <ul>
                    <li>
                      <h6>No Activity Logs Available</h6>
                    </li>
                  </ul>
                </div>
                <div class="customerOrderNoteBox" id="customer-order-note">
                  <h5>
                    Customer Order Note
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.75 13.75">
                      <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" opacity="0.63" />
                    </svg>
                  </h5>
                  <p v-if="addOrderForm.order_notes">
                    {{ addOrderForm.order_notes }}
                  </p>
                  <p class="noOrderNotes" v-else>No order note available</p>
                </div>
                <div class="addOrderNoteBox" id="admin-order-notes">
                  <h5>Admin Order Notes</h5>
                  <ul v-if="addOrderForm.notes.length == 0">
                    <li>No admin notes available</li>
                  </ul>
                  <ul v-else>
                    <li>
                      <div class="row" v-for="(note, index) in addOrderForm.notes" :key="`order-note-${index}`">
                        <div class="col-md-10">
                          <p class="noteListTxt">
                            {{ note.note }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <div class="actionIconWrap">
                            <button @click.prevent="orderNoteModalPreview('add-order',note, index)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.547 15.417">
                                <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(0.65 0.65)">
                                  <path id="Path_52165" data-name="Path 52165" d="M9.314,6H4.4A1.4,1.4,0,0,0,3,7.4v9.822a1.4,1.4,0,0,0,1.4,1.4h9.822a1.4,1.4,0,0,0,1.4-1.4V12.314" transform="translate(-3 -4.512)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                  <path id="Path_52166" data-name="Path 52166" d="M19.367,3.254a1.488,1.488,0,1,1,2.1,2.1l-6.665,6.665-2.806.7.7-2.806Z" transform="translate(-7.791 -2.818)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                </g>
                              </svg>
                            </button>
                            <button @click.prevent="removeOrderNote('add-order',note, index)">
                              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="#fd4e5d">
                                <title/>
                                <g>
                                  <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z"/>
                                  <path d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z"/>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ValidationObserver ref="addFormAdminOrderNoteObserver">
                    <form>
                      <ValidationProvider name="order note" :rules="{regex:/^\S.*\S$/,max:250}" v-slot="{ errors }">
                        <div class="addOrderNoteFieldBox" id="AddOrderDetailsSection">
                          <input
                            type="text"
                            name="addFormOrderNote"
                            id="addFormOrderNote"
                            placeholder="Order note"
                            v-model="orderNote"
                            :disabled="tourIsActive"
                          />
                          <button @click.prevent="addOrderNote('add-order')" :disabled="tourIsActive">
                            Add
                          </button>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <!-- order activity block ends here-->

            <!-- shipping block starts here -->
            <div class="productTableInfoWhiteBox" id="shipping-options-section">
              
              <div class="productTableInfoWhiteBoxTitle">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <h5>Available Shipping Options</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="ctmShippingLink" v-if="!tourIsActive">
                      <a href="#add-form-custom-shipping-block">Apply Custom Shipping Charges</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="storeShippingCheckBox" v-if="regionsLoader">
                <div class="storeShippingCheckFlexBox">
                  <div class="storeShippingCheckField">
                    <vue-skeleton-loader
                      type="rect"
                      :width="360"
                      :height="70"
                      animation="fade"
                    />
                  </div>
                  <div class="storeShippingCheckField">
                    <vue-skeleton-loader
                      type="rect"
                      :width="360"
                      :height="70"
                      animation="fade"
                    />
                  </div>
                </div>
              </div>

              <div class="storeShippingCheckBox" style="text-align:center;" v-else-if="!addOrderShippingRules">
                No Shipping Options Available
              </div>

              <div class="storeShippingCheckBox" v-else>
                <p v-if="addOrderShippingRules.type != 'Free Shipping'">{{ addOrderShippingRules.type }}</p>

                <!-- free shipping block starts here -->
                <div class="storeShippingCheckFlexBox" v-if="addOrderShippingRules.type == 'Free Shipping'">

                  <div class="storeShippingCheckField">

                    <label for="add-form-free-shipping">
                      <input 
                        type="radio" 
                        name="add-form-free-shipping" 
                        id="add-form-free-shipping" 
                        :value="addOrderShippingRules" 
                        v-model="addOrderSelectedShipping"
                        :disabled="tourIsActive"
                      />
                      <h3>{{ addOrderShippingRules.type }}</h3>
                      <div class="row">
                        <div class="col-md-12">
                          <p>The customer won't be charged for shipping.</p>
                        </div>
                      </div>
                    </label>

                  </div>

                </div>
                <!-- free shipping block ends here -->

                <div class="storeShippingCheckFlexBox" v-else>

                  <div class="storeShippingCheckField" v-for="(rule, index) in addOrderShippingRules.rules" :key="index">

                    <!-- flat rate block starts here-->
                    <label :for="`add-form-${rule.id + '-' + rule.name}`" v-if="addOrderShippingRules.type == 'Flat Rate'">
                      <input 
                        type="radio" 
                        :name="`add-form-${rule.id + '-' + rule.name}`" 
                        :id="`add-form-${rule.id + '-' + rule.name}`" 
                        :value="rule" 
                        v-model="addOrderSelectedShipping"
                        :disabled="tourIsActive"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="addOrderShippingRules.isNonCodDifferent && addOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <p>
                            {{
                              settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].non_cod_price).toLocaleString( "ur-PK",
                                {
                                  
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}
                          </p>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <p>
                            {{
                              settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].price).toLocaleString( "ur-PK",
                                {
                                  
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </label>
                    <!-- flat rate block ends here-->

                    <!-- store pickup only block starts here-->
                    <label :for="`add-form-${rule.id + '-' + rule.store_name}`" v-if="addOrderShippingRules.type == 'Store Pickup Only'">
                      <input
                        type="radio" 
                        :name="`add-form-${rule.id + '-' + rule.store_name}`" 
                        :id="`add-form-${rule.id + '-' + rule.store_name}`" 
                        :value="rule" 
                        v-model="addOrderSelectedShipping"
                        :disabled="tourIsActive"
                        />
                      <h3>{{ rule.store_name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.store_contact_no }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>{{ rule.city.name }}</p>
                        </div>
                      </div>
                      <p class="mt-2">{{ rule.address }}</p>
                    </label>
                    <!-- store pickup only block ends here-->

                    <!-- rate by price block starts here -->
                    <label :for="`add-form-${rule.id + '-' + rule.name}`" v-if="addOrderShippingRules.type == 'Rate By Price'">
                      <input 
                        type="radio" 
                        :name="`add-form-${rule.id + '-' + rule.name}`" 
                        :id="`add-form-${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: addOrderShippingRules.type, rule: rule,}" 
                        v-model="addOrderSelectedShipping"
                        :disabled="tourIsActive"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row" v-if="addOrderForm.payment.coupon_discounted_amount">
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="addOrderShippingRules.isNonCodDifferent && addOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) >= parseFloat(price.from).toFixed(2) && 
                            parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount)">
                              {{ (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount)">
                              {{ (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="addOrderShippingRules.isNonCodDifferent && addOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(addOrderForm.payment.amount + addOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount + addOrderForm.payment.tax_amount)">
                              {{ (addOrderForm.payment.amount + addOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount + addOrderForm.payment.tax_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(addOrderForm.payment.amount + addOrderForm.payment.tax_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(addOrderForm.payment.amount + addOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount + addOrderForm.payment.tax_amount)">
                              {{ (addOrderForm.payment.amount + addOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (addOrderForm.payment.amount + addOrderForm.payment.tax_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(addOrderForm.payment.amount + addOrderForm.payment.tax_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>
                      
                    </label>
                    <!-- rate by price block starts here -->

                    <!-- rate by weight block starts here -->
                    <label :for="`add-form-${rule.id + '-' + rule.name}`" v-if="addOrderShippingRules.type == 'Rate By Weight'">
                      <input 
                        type="radio" 
                        :name="`add-form-${rule.id + '-' + rule.name}`" 
                        :id="`add-form-${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: addOrderShippingRules.type,rule: rule,}" 
                        v-model="addOrderSelectedShipping"
                        :disabled="tourIsActive"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="addOrderShippingRules.isNonCodDifferent && addOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(weight, index) in rule.rule_prices" :key="index + 'w'">
                            <p v-if="parseFloat(addOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(addOrderForm.total_weight)">
                              {{ parseFloat(addOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(addOrderForm.total_weight) ? 
                                settings.currency_symbol + " " + parseFloat(weight.non_cod_price).toFixed(2) : "" 
                              }}
                            </p>
                            <p v-else-if=" parseFloat(addOrderForm.total_weight) > parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(weight.non_cod_price).toFixed(2) }}
                            </p>
                            <p v-else-if="parseFloat(addOrderForm.total_weight) < parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ index == 0 ? settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].non_cod_price).toFixed(2) : '' }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(weight, index) in rule.rule_prices" :key="index + 'w'">
                            <p v-if="parseFloat(addOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(addOrderForm.total_weight)">
                              {{ parseFloat(addOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(addOrderForm.total_weight) ? 
                                settings.currency_symbol + " " + parseFloat(weight.price).toFixed(2) : "" 
                              }}
                            </p>
                            <p v-else-if="parseFloat(addOrderForm.total_weight) > parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(weight.price).toFixed(2) }}
                            </p>
                            <p v-else-if="parseFloat(addOrderForm.total_weight) < parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ index == 0 ? settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].price).toFixed(2) : '' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                    <!-- rate by weight block ends here -->

                  </div>

                </div>
              </div>
            </div>
            <!-- shipping block ends here -->

            <!-- order details block starts here -->
            <div class="productTableInfoWhiteBox" id="orderDetail">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Order Detail</h5>
              </div>
              <form>
                <div class="row">
                  <div class="col-md-6" id="add-form-custom-shipping-block">
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="order date" rules="required" v-slot="{ errors }">
                        <label for="addFormOrderDate">Order Date<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="date"
                            name="addFormOrderDate"
                            id="addFormOrderDate"
                            onkeydown="return false"
                            @input="checkDate('add-order')"
                            v-model="addOrderForm.order_date"
                            :max="minDate"
                            :disabled="tourIsActive"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox" id="customShippingBlock">
                      <ValidationProvider name="delivery date" rules="required" v-slot="{ errors }">
                        <label for="addFormDeliveryDate">Delivery Date<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="date"
                            name="addFormDeliveryDate"
                            id="addFormDeliveryDate"
                            onkeydown="return false"
                            @input="checkDate()"
                            :min="addOrderForm.order_date"
                            v-model="addOrderForm.delivery_date"
                            :disabled="tourIsActive"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="payment method" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="addFormPaymentMethod">Payment Method<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="addOrderForm.payment.payment_method"
                            id="addFormPaymentMethod"
                            :options="paymentMethodsList"
                            placeholder="Select payment method"
                            label="type"
                            track-by="id"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tourIsActive"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                        <label for="addFormOrderStatus">Status<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="addOrderForm.status"
                            id="addFormOrderStatus"
                            :options="addOrderStatusList"
                            placeholder="Select status"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tourIsActive"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="tableFromFieldBox">
                      <label for="addFormCustomShipping">Custom Shipping Charges</label>
                      <div class="addOrderNoteFieldBox">
                        <input
                          type="number"
                          name="addFormCustomShipping"
                          id="addFormCustomShipping"
                          placeholder="Custom shipping charges"
                          v-model="customShippingCharges"
                          :disabled="tourIsActive || addOrderForm.items.length == 0"
                        />
                        <button @click.prevent="applyCustomShipping('add-order')" :disabled="tourIsActive || addOrderForm.items.length == 0">
                          Add
                        </button>
                      </div>
                      <p class="fieldSeoMessage">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        If custom shipping charges will be added, the selected
                        shipping option will not be considered
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <form>
                      <div class="tableFromFieldBox">
                        <label for="addFormCouponCode">Coupon</label>
                        <div class="addOrderNoteFieldBox coupon">
                          <input
                            type="text"
                            name="addFormCouponCode"
                            id="addFormCouponCode"
                            placeholder="Coupon"
                            style="padding-right: 190px !important"
                            v-model="addOrderForm.coupon.coupon_code"
                            :disabled="tourIsActive || addOrderForm.items.length == 0"
                          />
                          <loaderBtn v-if="couponLoader"/>
                          <button @click.prevent="verifyCoupon('add-order')" :disabled="tourIsActive" v-else>
                            <p>Verify Coupon</p>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>
            </div>
            <!-- order details block ends here -->

            <!-- order total block starts here -->
            <div class="productTableInfoWhiteBox" id="order-total-section">
              <div class="priceDetailList">
                <ul>
                  <li>
                    <h4>Total Cart Items</h4>
                    <h2>{{ addOrderFormCalculate }}</h2>
                  </li>
                  <li>
                    <h4 class="greenColorTxt">Amount</h4>
                    <h2 class="greenColorTxt" style="font-weight: 500">
                      {{
                        addOrderForm.payment.amount
                          ? settings.currency_unit ? settings.currency_symbol + " " + addOrderForm.payment.amount.toLocaleString(
                              "ur-PK",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + addOrderForm.payment.amount.toLocaleString(
                              "ur-PK",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                      }}
                    </h2>
                  </li>
                  <li v-if="addOrderIsCustomTax">
                    <h4 style="font-weight: 300">Tax Percentage</h4>
                    <div class="taxDetailTxt">
                      <div class="quantityTaxDetail">
                        <button class="miniusIconBtn" 
                          @click="taxChangeHandle('add-order','decr')"
                          :disabled="tourIsActive"
                        >
                          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <g id="_-" data-name=" -" class="cls-1">
                                <g class="cls-1">
                                  <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <input 
                          type="number" 
                          name="stock" 
                          id="stock" 
                          placeholder="0" 
                          v-model="addOrderForm.payment.tax" 
                          @blur="handleTaxInput('add-order')"
                          :disabled="tourIsActive"
                        />
                        <button 
                          class="plusIconBtn" 
                          @click="taxChangeHandle('add-order','incr')"
                          :disabled="tourIsActive"
                        >
                          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <g id="_" data-name=" " class="cls-1">
                                <g class="cls-1">
                                  <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>           
                      </div>
                      <a href="#." @click.prevent="!tourIsActive ? (addOrderIsCustomTax = !addOrderIsCustomTax) : (addOrderIsCustomTax = addOrderIsCustomTax)">Save</a>
                    </div>
                  </li>
                  <li v-else>
                    <h4 style="font-weight: 300">Tax Percentage</h4>
                    <div class="taxDetailTxt">
                      <div class="quantityTaxDetail">
                        
                      </div>
                      <p>{{ addOrderForm.payment.tax }}%</p>
                      <a 
                        href="#." 
                        @click.prevent="!tourIsActive ? (addOrderIsCustomTax = !addOrderIsCustomTax) : (addOrderIsCustomTax = addOrderIsCustomTax)" 
                        id="custom-tax-btn"
                      >
                        Add Custom Tax
                      </a>
                    </div>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Tax Amount</h4>
                    <h2 style="font-weight: 500">
                      {{ settings.currency_unit ? settings.currency_symbol + " " +
                        parseFloat(
                          (addOrderForm.payment.amount - addOrderForm.payment.coupon_discounted_amount) *
                            (addOrderForm.payment.tax / 100)
                        ).toLocaleString("ur-PK", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) 
                        : ''
                      }}
                    </h2>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Shipping Fee</h4>
                    <h2 style="font-weight: 500">
                      {{
                        addOrderForm.payment.shipping_amount
                          ? settings.currency_unit ? settings.currency_symbol + " " +parseFloat(
                              addOrderForm.payment.shipping_amount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(addOrderForm.payment.shipping_amount).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Discounted Amount</h4>
                    <h2 style="font-weight: 500">
                      {{
                        addOrderForm.payment.coupon_discounted_amount
                          ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(
                              addOrderForm.payment.coupon_discounted_amount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li>
                    <div class="netTotalBlock">
                      <p>Net Total</p>
                      <h5>
                        {{
                          addOrderForm.payment.net_amount
                            ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(
                                addOrderForm.payment.net_amount
                              ).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : settings.currency_symbol + " " + (addOrderForm.payment.net_amount).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }}
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- order total block ends here -->

          </div>

        </div>
      </ValidationObserver>
      <!-- add order form ends here -->

      <!-- update order form starts here -->
      <ValidationObserver ref="updateOrderFormObserver">
        <div class="productSlideBox" ref="viewInputForm" :class="updateOrderForm.isPreview ? 'activeRow' : ''">
          
          <div class="tableActionBtns" id="update-order-details-preview">
            <div class="productTableSideBar">
              <div class="row">
                <div class="col-md-6">
                  <div class="viewOrderTitle">
                    <p>
                      {{ 
                        updateOrderForm.customer.first_name + " " + updateOrderForm.customer.last_name
                      }}
                      ({{
                        updateOrderForm.order_no
                      }})
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="orderViewNav">
                    <ul>
                      <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                        <li class="sm-extand-hide">
                          <button @click.prevent="expand('update-order')" :disabled="tourIsActive">
                            <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" id="expand-form">
                              <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                <path d="m16.5 5.5v-4.978l-5.5.014" />
                                <path d="m16.5.522-6 5.907" />
                                <path d="m11 16.521 5.5.002-.013-5.5" />
                                <path d="m16.5 16.429-6-5.907" />
                                <path d="m.5 5.5v-5h5.5" />
                                <path d="m6.5 6.429-6-5.907" />
                                <path d="m6 16.516-5.5.007v-5.023" />
                                <path d="m6.5 10.5-6 6" />
                              </g>
                            </svg>
                          </button>
                        </li>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                        <li>
                          <button @click.prevent="updateOrderOperations('close','without-confirmation')" id="closeSide">
                            <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                              <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                  <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                  <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </li>
                      </el-tooltip>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="sectionTabsLink">
              <ul onclick="window.myFunction(event)">
                <li><a href="#updateOrderCustomerDetail" id="update-customer-details-navbtn" v-show="!tourIsActive">Customer Details</a></li>
                <li><a href="#updateOrderActivities" id="update-order-activity-navbtn" v-show="!tourIsActive">Order Activity</a></li>
                <li><a href="#updateOrderDetailsSection" id="update-order-details-navbtn" v-show="!tourIsActive">Order Details</a></li>
              </ul>
              <div class="updateBtnBox">
                <loaderBtn v-if="orderLoader"/>
                <button 
                  @click.prevent="validate('update')" 
                  id="update-save-order-btn" 
                  :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || tourIsActive"
                  v-else
                >
                  Update
                </button>
              </div>
            </div>
          </div>

          <div class="productTableInfoBlock">

            <!-- products block starts here -->
            <div class="productTableInfoWhiteBox" id="updateOrderCustomerDetail">
              <div class="orderAdminTitle" v-if="updateOrderForm.created_by">
                <p>
                  Created by: <span>{{ updateOrderForm.created_by }}</span>
                </p>
              </div>
              <div class="addOrderTable" id="update-product-section">
                <table>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="updateOrderForm.items.length == 0">
                      <td colspan="7">
                        The cart has no items at the moment
                      </td>
                    </tr>
                    <tr v-for="(item, index) in updateOrderForm.items" :key="index">
                      <td>
                        <div class="productOrderTableImg">
                          <img 
                            :src="item.image ? item.type == 'bundle' ? base_image_url + settings.store_id + '/product-bundle/' + item.image :
                            base_image_url + settings.store_id + '/product/' + item.image : '/images/no_image.png'"
                            @error="handleImageError"
                          />
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>{{ item.name.length > 15 ? item.name.substr(0,15) + "..." : item.name }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTagTxt">
                          <p>{{ item.type }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>
                            {{
                              settings.currency_symbol + " " + item.variant.price.toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="quantityOrderProductBox" v-if="item.type != 'bundle'">
                          <button
                            class="miniusIconBtn"
                            @click.prevent="productQuantityChangeHandle('update-order','decr',index)"
                            :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          >
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g id="_-" data-name=" -" class="cls-1">
                                  <g class="cls-1">
                                    <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                          <input
                            type="number"
                            name="stock"
                            id="stock"
                            placeholder="0"
                            v-model="item.variant.qty"
                            @blur="productQuantityChangeCheck('update-order',index)"
                            :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <button
                            class="plusIconBtn"
                            @click.prevent="productQuantityChangeHandle('update-order','incr',index)"
                            :disabled="tourIsActive"
                          >
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g id="_" data-name=" " class="cls-1">
                                  <g class="cls-1">
                                    <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                        <div class="quantityOrderProductBox" v-else>
                          <p style="text-align: center; margin-bottom: 0px">--</p>
                        </div>
                      </td>
                      <td>
                        <div class="productOrderTableTxt">
                          <p>
                            {{
                              calculateProductTotal(item,'update-order') ? settings.currency_symbol + " " + calculateProductTotal(item,'update-order').toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) : ''
                            }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="ctmDataTableActionBtn">
                          <button
                            class="delFunction"
                            @click.prevent="removeOrderItem(item, 'update-order', index)"
                            :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          >
                            <i aria-hidden="true" class="fa fa-trash-o"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <span class="text-danger" v-show="updateOrderForm.items.length == 0">Cart item(s) must be added.</span>
              <div class="orderAddProductBtn">
                <button 
                  @click.prevent="openCartItemsModal('update-order')"
                  :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                  id="update-add-product-btn"
                >
                  + Add Cart Items
                </button>
              </div>
            </div>
            <!-- products block ends here -->

            <!-- customer block starts here -->
            <div class="productTableInfoWhiteBox" id="updateCustomerSection">
              
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Customer Details</h5>
              </div>

              <form id="update-customer-section">

                <div class="tableFromFieldBox" @click.prevent="productSelectionMessage('update-order')" id="update-add-customer-dropdown">
                  <label for="addFormCustomer">Customer<span>*</span></label>
                  <div class="vueSelectBlock">
                    <Multiselect
                      ref="updateCustomerMultiSelect"
                      v-model="updateOrderForm.customer"
                      :options="list_customer"
                      placeholder="Select customer"
                      label="first_name"
                      track-by="id"
                      :open-direction="'bottom'"
                      :allow-empty="false"
                      deselect-label="Can't remove the selected item"
                      :custom-label="customerCustomLabel"
                      @close="onTouchCustomer('update-order')"
                      @select="handleSelectedCustomer($event,'update-order')"
                      :disabled="tourIsActive || (updateOrderForm.created_by &&
                      updateOrderForm.created_by == 'customer') || (tempOrder.status == 'Delivered' || 
                      tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                    >
                      <span slot="noResult">
                        No customer found matching the searched keyword.
                      </span>
                      <li class="multiselect__element" slot="afterList" @click.prevent="openCustomerForm('update-order')">
                        <span class="multiselect__option">Add New Customer</span>
                      </li>
                    </Multiselect>
                    <span class="text-danger" v-if="customerFieldRequired">The customer field is required</span>
                  </div>
                </div>
                <div class="customerDetailList" id="update-customer-basic-details">
                  <ul>
                    <li>
                      <h6>Name:</h6>
                      <p>
                        {{
                          updateOrderForm.customer.first_name +
                          " " +
                          updateOrderForm.customer.last_name
                        }}
                      </p>
                    </li>
                    <li>
                      <h6>Email:</h6>
                      <p>{{ updateOrderForm.customer.email }}</p>
                    </li>
                    <li>
                      <h6>Contact Number:</h6>
                      <p>{{ updateOrderForm.customer.mobile_no }}</p>
                    </li>
                    <li>
                      <h6>Address:</h6>
                      <p>
                        {{updateOrderForm.customer.address_line_2 != null ?
                          updateOrderForm.customer.address_line_1 +
                          " " +
                          updateOrderForm.customer.address_line_2 :
                          updateOrderForm.customer.address_line_1
                        }}
                      </p>
                    </li>
                  </ul>
                </div>

                <!-- shipping details block starts here -->
                <div class="productTableInfoWhiteBoxTitle">
                  <h5>Shipping Details</h5>
                </div>
                <div class="row" id="update-customer-shipping-details">
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingFirstName">First Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormShippingFirstName"
                            id="updateFormShippingFirstName"
                            placeholder="First name"
                            v-model="updateOrderForm.shipping.shipping_first_name"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                            @input="onChangeInField('update-order','first_name')"
                            />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingLastName">Last Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormShippingLastName"
                            id="updateFormShippingLastName"
                            placeholder="Last name"
                            v-model="updateOrderForm.shipping.shipping_last_name"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                            @input="onChangeInField('update-order','last_name')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping email" rules="required|email|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingEmail">Email<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormShippingEmail"
                            id="updateFormShippingEmail"
                            placeholder="Email"
                            v-model="updateOrderForm.shipping.shipping_email"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                            @input="onChangeInField('update-order','email')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping contact no." :rules="shippingCountryIsSelected ? 'required' : ''" v-slot="{ errors }">
                      <div class="tableFromFieldBox" id="shipping-contact">
                        <label for="cSCNumber">Contact No. <span>*</span></label>
                        <div class="tableFromFieldItem">
                          <vue-tel-input 
                            v-model="updateOrderForm.shipping.shipping_contact_no"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="ContactFieldOptions"
                            ref="shippingContactNoField"
                            :dropdownOptions="ContactFieldDropDownOptions"
                            :validCharactersOnly=true 
                            :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded' || (updateOrderForm.items.length == 0)"
                            @input="shippingContactInputHandle"
                            @validate="shippingValidateContactNo"
                            @country-changed="shippingCountryChangeHandle"
                            @focus="shippingContactFieldFocusHandle"
                            @close="shippingDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-else-if="!shippingCountryIsSelected && shippingContactFieldTouched">
                            Select a country from the dropdown menu or add the country code before the contact no.
                          </span>
                          <span class="text-danger" v-else-if="!shippingContactIsValid && shippingContactFieldTouched">
                            Enter a valid contact no.
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping country" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingCountry">Country<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedShippingCountry"
                            id="updateFormShippingCountry"
                            :options="availableCountries"
                            placeholder="Select country"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            @select="countrySelectHandle($event,'shipping')"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          >
                            <span slot="noResult">No country found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping state" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingState">State<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedShippingState"
                            id="updateFormShippingState"
                            :options="selectedShippingCountryStates"
                            :placeholder="shippingCountryStatesLoading ? 'Loading states....' : 'Select state'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :loading="shippingCountryStatesLoading"
                            @select="stateSelectHandle($event,'shipping')"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the states, please select a country.</span>
                            <span slot="noResult">No state found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping city" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingCity">City<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedShippingCity"
                            id="updateFormShippingCity"
                            :options="selectedShippingStateCities"
                            :placeholder="shippingStateCitiesLoading ? 'Loading cities....' : 'Select city'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            @select="citySelectHandle($event,'shipping')"
                            :loading="shippingStateCitiesLoading"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the cities, please select a state.</span>
                            <span slot="noResult">No city found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping postal code" rules="required|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingPostalCode">Shipping Postal Code<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormShippingPostalCode"
                            id="updateFormShippingPostalCode"
                            placeholder="Shipping postal code"
                            v-model="updateOrderForm.shipping.shipping_postal_code"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                            @input="onChangeInField('update-order','postal-code')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="shipping address" rules="required|max:500" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormShippingAddress">Shipping Address<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormShippingAddress"
                            id="updateFormShippingAddress"
                            placeholder="Shipping address"
                            v-model="shipping_address"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                            @input="onChangeInField('update-order','address')"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- shipping details block ends here -->

                <!-- billing details block starts here -->
                <div class="productTableInfoWhiteBoxTitle">
                  <div class="row align-items-center">
                    <div class="col-md-6" >
                      <h5>Billing Details</h5>
                    </div>
                    <div class="col-md-6">
                      <div class="ctmShippingLink" id="same-billing-checkbox">
                        <label> 
                          Same as Shipping
                          <input 
                            type="checkbox" 
                            v-model="isSameBilling" 
                            @change="onChangeSameBilling($event,'update-order')" 
                            :disabled="
                              tempOrder.status == 'Delivered' || 
                              tempOrder.status == 'Cancelled' || 
                              tempOrder.status == 'Deleted' || 
                              tempOrder.status == 'Refunded' || 
                              tourIsActive || !updateOrderForm.customer.id"
                            >
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" id="update-customer-billing-details">
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormBillingFirstName">First Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormBillingFirstName"
                            id="updateFormBillingFirstName"
                            placeholder="First name"
                            v-model="updateOrderForm.billing.billing_first_name"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormBillingLastName">Last Name<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormBillingLastName"
                            id="updateFormBillingLastName"
                            placeholder="Last name"
                            v-model="updateOrderForm.billing.billing_last_name"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing email" rules="required|email|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="UpdateFormBillingEmail">Email<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="UpdateFormBillingEmail"
                            id="UpdateFormBillingEmail"
                            placeholder="Email"
                            v-model="updateOrderForm.billing.billing_email"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')" id="update-billing-contact">
                    <ValidationProvider name="billing contact no." :rules="billingCountryIsSelected ? 'required' : '' " v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="cBCNumber">Contact No.<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <vue-tel-input 
                            v-model="updateOrderForm.billing.billing_contact_no"
                            mode="international"
                            :onlyCountries="allowedCountries"
                            :inputOptions="ContactFieldOptions"
                            ref="billingContactNoField"
                            :dropdownOptions="ContactFieldDropDownOptions"
                            :validCharactersOnly=true 
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                            @input="billingContactInputHandle"
                            @validate="billingValidateContactNo"
                            @country-changed="billingCountryChangeHandle"
                            @focus="billingContactFieldFocusHandle"
                            @close="billingDropDownCloseHandle"
                          >
                          </vue-tel-input>
                          <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                          <span class="text-danger" v-else-if="!billingCountryIsSelected && billingContactFieldTouched">
                            Select a country from the dropdown menu or add the country code before the contact no.
                          </span>
                          <span class="text-danger" v-else-if="!billingContactIsValid && billingContactFieldTouched">
                            Enter a valid contact no.
                          </span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing country" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormBillingCountry">Country<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingCountry"
                            id="updateFormBillingCountry"
                            :options="availableCountries"
                            placeholder="Select country"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            @select="countrySelectHandle($event,'billing')"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          >
                            <span slot="noResult">No country found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing state" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormBillingState">State<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingState"
                            id="updateFormBillingState"
                            :options="selectedBillingCountryStates"
                            :placeholder="billingCountryStatesLoading ? 'Loading states....' : 'Select state'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :loading="billingCountryStatesLoading"
                            @select="stateSelectHandle($event,'billing')"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the states, please select a country.</span>
                            <span slot="noResult">No state found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing city" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormCity">City<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="selectedBillingCity"
                            id="updateFormCity"
                            :options="selectedBillingStateCities"
                            :placeholder="billingStateCitiesLoading ? 'Loading cities....' : 'Select city'"
                            label="name"
                            track-by="name"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :loading="billingStateCitiesLoading"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          >
                            <span slot="noOptions">To load the cities, please select a state.</span>
                            <span slot="noResult">No city found matching the searched keyword</span>
                          </Multiselect>
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" @click.prevent="productSelectionMessage('update-order')">
                    <ValidationProvider name="billing postal code" rules="required|max:250" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="UpdateFormBillingPostalCode">Billing Postal Code<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="UpdateFormBillingPostalCode"
                            id="UpdateFormBillingPostalCode"
                            placeholder="Billing postal code"
                            v-model="updateOrderForm.billing.billing_postal_code"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12" @click.prevent="productSelectionMessage('update-order')" id="updateOrderActivities">
                    <ValidationProvider name="billing address" rules="required|max:500" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updateFormBillingAddress">Billing Address<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="text"
                            name="updateFormBillingAddress"
                            id="updateFormBillingAddress"
                            placeholder="Shipping address"
                            v-model="billing_address"
                            :disabled="isSameBilling || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded') || (updateOrderForm.items.length == 0)"
                          />
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- billing details block ends here -->

              </form>

            </div>
            <!-- customer block ends here -->

            <!-- order activity block starts here-->
            <div class="productTableInfoWhiteBox" id="updateOrderActivitySection">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Order Activity</h5>
              </div>
              <div class="activityList">
                <div class="orderActivityList" v-if="updateOrderForm.activities.length == 0">
                  <ul>
                    <li>
                      <h6>No Activity Logs Available</h6>
                    </li>
                  </ul>
                </div>
                <div class="orderActivityList" v-else>
                  <ul>
                    <li v-for="(activity, index) in updateOrderForm.activities" :key="index">
                      <h6>{{ activity.activity }}</h6>
                      <p>
                        on
                        {{
                          activity.created_at ? new Date(activity.created_at).toLocaleString("en-UK",
                              {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              }
                            )
                          : "Not Available"
                        }}
                        at
                        {{ activity.created_at ? new Date(activity.created_at).toLocaleTimeString( "en-US"): "Not Available" }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="customerOrderNoteBox" id="update-customer-order-note">
                  <h5>
                    Customer Order Note
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.75 13.75">
                      <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" opacity="0.63" />
                    </svg>
                  </h5>
                  <p v-if="updateOrderForm.order_notes">
                    {{ updateOrderForm.order_notes }}
                  </p>
                  <p class="noOrderNotes" v-else>No order note available</p>
                </div>
                <div class="addOrderNoteBox" id="update-admin-order-notes">
                  <h5>Admin Order Notes</h5>
                  <ul v-if="updateOrderForm.notes.length == 0 && tempOrderNotes.length == 0">
                    <li>No admin notes available</li>
                  </ul>
                  <ul v-else>
                    <li>
                      <div class="row" v-for="(note, index) in updateOrderForm.notes" :key="note.id + index">
                        <div class="col-md-10">
                          <p class="noteListTxt">
                            {{ note.note }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <div class="actionIconWrap">
                            <button 
                              @click.prevent="orderNoteModalPreview('update-order',note, index)"
                              :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                              tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.547 15.417">
                                <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(0.65 0.65)">
                                  <path id="Path_52165" data-name="Path 52165" d="M9.314,6H4.4A1.4,1.4,0,0,0,3,7.4v9.822a1.4,1.4,0,0,0,1.4,1.4h9.822a1.4,1.4,0,0,0,1.4-1.4V12.314" transform="translate(-3 -4.512)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                  <path id="Path_52166" data-name="Path 52166" d="M19.367,3.254a1.488,1.488,0,1,1,2.1,2.1l-6.665,6.665-2.806.7.7-2.806Z" transform="translate(-7.791 -2.818)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                </g>
                              </svg>
                            </button>
                            <button 
                              @click.prevent="removeOrderNote('update-order',note, index)" 
                              :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                              tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'"
                            >
                              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="#fd4e5d">
                                <title/>
                                <g>
                                  <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z"/>
                                  <path d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z"/>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="tempOrderNotes.length > 0">
                      <h5 class="newNoteTxt">New Notes</h5>
                      <div class="row" v-for="(note, index) in tempOrderNotes" :key="index">
                        <div class="col-md-10">
                          <p class="noteListTxt">
                            {{ note.note }}
                          </p>
                        </div>
                        <div class="col-md-2">
                          <div class="actionIconWrap">
                            <button 
                              @click.prevent="orderNoteModalPreview('update-order',note, index)"
                              :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                              tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.547 15.417">
                                <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(0.65 0.65)">
                                  <path id="Path_52165" data-name="Path 52165" d="M9.314,6H4.4A1.4,1.4,0,0,0,3,7.4v9.822a1.4,1.4,0,0,0,1.4,1.4h9.822a1.4,1.4,0,0,0,1.4-1.4V12.314" transform="translate(-3 -4.512)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                  <path id="Path_52166" data-name="Path 52166" d="M19.367,3.254a1.488,1.488,0,1,1,2.1,2.1l-6.665,6.665-2.806.7.7-2.806Z" transform="translate(-7.791 -2.818)" fill="none" stroke="#fd4d5d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                                </g>
                              </svg>
                            </button>
                            <button 
                              @click.prevent="removeOrderNote('update-order',note, index)"
                              :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                              tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'"
                            >
                              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" fill="#fd4e5d">
                                <title/>
                                <g>
                                  <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z"/>
                                  <path d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z"/>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ValidationObserver ref="updateFormAdminOrderNoteObserver">
                    <form>
                      <ValidationProvider name="order note" :rules="{regex:/^\S.*\S$/,max:1000}" v-slot="{ errors }">
                        <div class="addOrderNoteFieldBox" id="updateOrderDetailsSection">
                          <input
                            type="text"
                            name="updateOrderNote"
                            id="updateOrderNote"
                            placeholder="Write an order note"
                            v-model="orderNote"
                            :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <button 
                            @click.prevent="addOrderNote('update-order')"
                            :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          >
                            Add
                          </button>
                        </div>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
            <!-- order activity block ends here-->

            <!-- shipping block starts here -->
            <div class="productTableInfoWhiteBox" id="update-shipping-options-section">
              
              <div class="productTableInfoWhiteBoxTitle">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <h5>Current Shipping</h5>
                  </div>
                </div>
              </div>

              <div class="storeShippingCheckBox" v-if="tempOrder && tempOrder.payment && tempOrder.payment.custom_shipping_amount">

                <div class="storeShippingCheckField">

                  <label>
                    <h3>Custom Shipping</h3>
                    <div class="row">
                      <div class="col-md-9">
                        <p>A custom shipping fee applies.</p>
                      </div>
                      <div class="col-md-3 text-right">
                        <p>
                          {{ settings.currency_unit ? settings.currency_symbol + " " +
                              parseFloat(tempOrder.payment.custom_shipping_amount ).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) 
                              : tempOrder.payment.custom_shipping_amount
                          }}
                        </p>
                      </div>
                    </div>
                  </label>

                </div>

              </div>
              <div class="storeShippingCheckBox" v-else-if="tempOrder && tempOrder.payment && tempOrder.payment.is_free_shipping">

                <div class="storeShippingCheckField">

                  <label>
                    <h3>Free Shipping</h3>
                    <div class="row">
                      <div class="col-md-12">
                        <p>The customer won't be charged for shipping.</p>
                      </div>
                    </div>
                  </label>

                </div>

              </div>

              <!-- flat rate block starts here-->
              <div class="storeShippingCheckBox" v-else-if="tempOrder && tempOrder.payment && tempOrder.payment.shipping_rule && tempOrder.payment.shipping_rule.shipping.type == 'Flat Rate'">
                <p>{{ tempOrder.payment.shipping_rule.shipping.type }}</p>
                <div class="storeShippingCheckFlexBox">

                  <div class="storeShippingCheckField">

                    <label>
                      <h3>{{ tempOrder.payment.shipping_rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ tempOrder.payment.shipping_rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>
                            {{ settings.currency_unit ? settings.currency_symbol + " " +
                                parseFloat(tempOrder.payment.shipping_amount ).toLocaleString("ur-PK", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) 
                                : tempOrder.payment.shipping_amount
                            }}
                          </p>
                        </div>
                      </div>
                    </label>

                  </div>

                </div>
              </div>
              <!-- flat rate block ends here-->

              <!-- store pickup only block starts here-->
              <div class="storeShippingCheckBox" v-else-if="tempOrder && tempOrder.payment && tempOrder.payment.shipping_rule && tempOrder.payment.shipping_rule.shipping.type == 'Store Pickup Only'">
                <p>{{ tempOrder.payment.shipping_rule.shipping.type }}</p>
                <div class="storeShippingCheckFlexBox">
                  
                  <div class="storeShippingCheckField">

                    <label>
                      <h3>{{ tempOrder.payment.shipping_rule.store_name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ tempOrder.payment.shipping_rule.store_contact_no }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>{{ tempOrder.payment.shipping_rule.city.name }}</p>
                        </div>
                      </div>
                      <p class="mt-2">{{ tempOrder.payment.shipping_rule.address }}</p>
                    </label>

                  </div>

                </div>
              </div>
              <!-- store pickup only block ends here-->

              <!-- rate by price block starts here -->
              <div class="storeShippingCheckBox" v-else-if="tempOrder && tempOrder.payment && tempOrder.payment.shipping_rule && tempOrder.payment.shipping_rule.shipping.type == 'Rate By Price'">
                <p>{{ tempOrder.payment.shipping_rule.shipping.type }}</p>
                <div class="storeShippingCheckFlexBox">
                  
                  <div class="storeShippingCheckField">

                    <label>
                      <h3>{{ tempOrder.payment.shipping_rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ tempOrder.payment.shipping_rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>
                            {{ settings.currency_unit ? settings.currency_symbol + " " +
                                parseFloat(tempOrder.payment.shipping_amount ).toLocaleString("ur-PK", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) 
                                : tempOrder.payment.shipping_amount
                            }}
                          </p>
                        </div>
                      </div>
                    </label>
                    
                  </div>

                </div>
              </div>
              <!-- rate by price block ends here -->

              <!-- rate by weight block starts here -->
              <div class="storeShippingCheckBox" v-else-if="tempOrder && tempOrder.payment && tempOrder.payment.shipping_rule && tempOrder.payment.shipping_rule.shipping.type == 'Rate By Weight'">
                <p>{{ tempOrder.payment.shipping_rule.shipping.type }}</p>
                <div class="storeShippingCheckFlexBox">
                  
                  <div class="storeShippingCheckField">

                    <label>
                      <h3>{{ tempOrder.payment.shipping_rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ tempOrder.payment.shipping_rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>
                            {{ settings.currency_unit ? settings.currency_symbol + " " +
                                parseFloat(tempOrder.payment.shipping_amount ).toLocaleString("ur-PK", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) 
                                : tempOrder.payment.shipping_amount
                            }}
                          </p>
                        </div>
                      </div>
                    </label>
                    
                  </div>

                </div>
              </div>
              <!-- rate by weight block ends here -->

              <!-- no shipping option block starts here -->
              <div class="storeShippingCheckBox" style="text-align:center;" v-else>
                No Shipping Option Was Selected
              </div>
              <!-- no shipping option block ends here -->

            </div>

            <div class="productTableInfoWhiteBox" id="update-shipping-options-section">
              
              <div class="productTableInfoWhiteBoxTitle">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <h5>Available Shipping Options</h5>
                  </div>
                  <div class="col-md-6">
                    <div class="ctmShippingLink" v-if="tempOrder.status != 'Delivered' &&
                      tempOrder.status != 'Cancelled' && tempOrder.status != 'Deleted' && tempOrder.status != 'Refunded'"
                    >
                      <a href="#update-form-custom-shipping-block">Apply Custom Shipping Charges</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="storeShippingCheckBox" v-if="regionsLoader">
                <div class="storeShippingCheckFlexBox">
                  <div class="storeShippingCheckField">
                    <vue-skeleton-loader
                      type="rect"
                      :width="360"
                      :height="70"
                      animation="fade"
                    />
                  </div>
                  <div class="storeShippingCheckField">
                    <vue-skeleton-loader
                      type="rect"
                      :width="360"
                      :height="70"
                      animation="fade"
                    />
                  </div>
                </div>
              </div>

              <div class="storeShippingCheckBox" style="text-align:center;" v-else-if="!updateOrderShippingRules">
                No shipping Options Available
              </div>

              <div class="storeShippingCheckBox" v-else>
                <p v-if="updateOrderShippingRules.type != 'Free Shipping'">{{ updateOrderShippingRules.type }}</p>
                
                <!-- free shipping block starts here -->
                <div class="storeShippingCheckFlexBox" v-if="updateOrderShippingRules.type == 'Free Shipping'">

                  <div class="storeShippingCheckField">

                    <label for="update-form-free-shipping">
                      <input 
                        type="radio" 
                        name="update-form-free-shipping" 
                        id="update-form-free-shipping" 
                        :value="updateOrderShippingRules" 
                        v-model="updateOrderSelectedShipping"
                        :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                        tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                      />
                      <h3>{{ updateOrderShippingRules.type }}</h3>
                      <div class="row">
                        <div class="col-md-12">
                          <p>The customer won't be charged for shipping.</p>
                        </div>
                      </div>
                    </label>

                  </div>

                </div>
                <!-- free shipping block ends here -->

                <div class="storeShippingCheckFlexBox" v-else>

                  <div class="storeShippingCheckField" v-for="(rule, index) in updateOrderShippingRules.rules" :key="index">
                    
                    <!-- flat rate block starts here-->
                    <label :for="`update-form-${rule.id + '-' + rule.name}`" v-if="updateOrderShippingRules.type == 'Flat Rate'">
                      <input 
                        type="radio" 
                        :name="`update-form-${rule.id + '-' + rule.name}`" 
                        :id="`update-form-${rule.id + '-' + rule.name}`" 
                        :value="rule" 
                        v-model="updateOrderSelectedShipping"
                        :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                        tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                      />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="updateOrderShippingRules.isNonCodDifferent == 1 && updateOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <p>
                            {{
                              settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].non_cod_price).toLocaleString( "ur-PK",
                                {
                                  
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}
                          </p>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <p>
                            {{
                              settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].price).toLocaleString( "ur-PK",
                                {
                                  
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </label>
                    <!-- flat rate block ends here-->

                    <!-- store pickup only block starts here-->
                    <label :for="`update-form-${rule.id + '-' + rule.store_name}`" v-if="updateOrderShippingRules.type == 'Store Pickup Only'">
                      <input
                        type="radio" 
                        :name="`update-form-${rule.id + '-' + rule.store_name}`" 
                        :id="`update-form-${rule.id + '-' + rule.store_name}`" 
                        :value="rule" 
                        v-model="updateOrderSelectedShipping"
                        :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                        tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                      />
                      <h3>{{ rule.store_name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.store_contact_no }}</p>
                        </div>
                        <div class="col-md-6 text-right">
                          <p>{{ rule.city.name }}</p>
                        </div>
                      </div>
                      <p class="mt-2">{{ rule.address }}</p>
                    </label>
                    <!-- store pickup only block ends here-->

                    <!-- rate by price block starts here -->
                    <label :for="`update-form-${rule.id + '-' + rule.name}`" v-if="updateOrderShippingRules.type == 'Rate By Price'">
                      <input 
                        type="radio" 
                        :name="`update-form-${rule.id + '-' + rule.name}`" 
                        :id="`update-form-${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: updateOrderShippingRules.type, rule: rule,}" 
                        v-model="updateOrderSelectedShipping"
                        :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                        tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                      />
                      <h3>{{ rule.name }}</h3>
                      <div class="row" v-if="updateOrderForm.payment.discounted_amount">
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="updateOrderShippingRules.isNonCodDifferent && updateOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && 
                            parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount)">
                              {{ (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount  + updateOrderForm.payment.tax_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount)">
                              {{ (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount + updateOrderForm.payment.tax_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="updateOrderShippingRules.isNonCodDifferent && updateOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount)">
                              {{ (updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.non_cod_price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(price, index) in rule.rule_prices" :key="index + 'p'">
                            <p v-if="(updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount)">
                              {{ (updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) >= parseFloat(price.from).toFixed(2) && parseFloat(price.to).toFixed(2) >= (updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) ? 
                                settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) : ""
                              }}
                            </p>
                            <p v-else-if="(updateOrderForm.payment.amount + updateOrderForm.payment.tax_amount) > parseFloat(price.from).toFixed(2) && (price.to == '' || price.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(price.price).toFixed(2) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                    <!-- rate by price block starts here -->

                    <!-- rate by weight block starts here -->
                    <label :for="`update-form-${rule.id + '-' + rule.name}`" v-if="updateOrderShippingRules.type == 'Rate By Weight'">
                      <input 
                        type="radio" 
                        :name="`update-form-${rule.id + '-' + rule.name}`" 
                        :id="`update-form-${rule.id + '-' + rule.name}`" 
                        :value="{ shipping_name: updateOrderShippingRules.type,rule: rule,}" 
                        v-model="updateOrderSelectedShipping"
                        :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                        tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                      <h3>{{ rule.name }}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <p>{{ rule.delivery_details }}</p>
                        </div>
                        <div class="col-md-6 text-right" v-if="updateOrderShippingRules.isNonCodDifferent && updateOrderForm.payment.payment_method.type != 'Cash on Delivery'">
                          <div v-for="(weight, index) in rule.rule_prices" :key="index + 'w'">
                            <p v-if="parseFloat(updateOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(updateOrderForm.total_weight)">
                              {{ parseFloat(updateOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(updateOrderForm.total_weight) ? 
                                settings.currency_symbol + " " + parseFloat(weight.non_cod_price).toFixed(2) : "" 
                              }}
                            </p>
                            <p v-else-if="parseFloat(updateOrderForm.total_weight) > parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(weight.non_cod_price).toFixed(2) }}
                            </p>
                            <p v-else-if="parseFloat(updateOrderForm.total_weight) < parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ index == 0 ? settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].non_cod_price).toFixed(2) : '' }}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6 text-right" v-else>
                          <div v-for="(weight, index) in rule.rule_prices" :key="index + 'w'">
                            <p v-if="parseFloat(updateOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(updateOrderForm.total_weight)">
                              {{ parseFloat(updateOrderForm.total_weight) >= parseFloat(weight.from).toFixed(2) && parseFloat(weight.to).toFixed(2) >= parseFloat(updateOrderForm.total_weight) ? 
                                settings.currency_symbol + " " + parseFloat(weight.price).toFixed(2) : "" 
                              }}
                            </p>
                            <p v-else-if="parseFloat(updateOrderForm.total_weight) > parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ settings.currency_symbol + " " + parseFloat(weight.price).toFixed(2) }}
                            </p>
                            <p v-else-if="parseFloat(updateOrderForm.total_weight) < parseFloat(weight.from).toFixed(2) && (weight.to == '' || weight.to == null)">
                              {{ index == 0 ? settings.currency_symbol + " " + parseFloat(rule.rule_prices[0].price).toFixed(2) : '' }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </label>
                    <!-- rate by weight block ends here -->

                  </div>

                </div>
              </div>
              
            </div>
            <!-- shipping block ends here -->

            <!-- order details block starts here -->
            <div class="productTableInfoWhiteBox" id="updateOrderDetail">
              <div class="productTableInfoWhiteBoxTitle">
                <h5>Order Detail</h5>
              </div>
              <form>
                <div class="row">
                  <div class="col-md-6" id="update-form-custom-shipping-block">
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="order date" rules="required" v-slot="{ errors }">
                        <label for="updateFormOrderDate">Order Date<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="date"
                            name="updateFormOrderDate"
                            id="updateFormOrderDate"
                            onkeydown="return false"
                            @input="checkDate('update-order')"
                            v-model="updateOrderForm.order_date"
                            :max="minDate"
                            :disabled="tempOrder.created_by == 'customer' || (tempOrder.status == 'Delivered' || 
                            tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox" id="updateCustomShippingBlock">
                      <ValidationProvider name="delivery date" rules="required" v-slot="{ errors }">
                        <label for="updateFormDeliveryDate">Delivery Date<span>*</span></label>
                        <div class="tableFromFieldItem">
                          <input
                            type="date"
                            name="updateFormDeliveryDate"
                            id="updateFormDeliveryDate"
                            onkeydown="return false"
                            @input="checkDate('update-order')"
                            :min="updateOrderForm.order_date"
                            v-model="updateOrderForm.delivery_date"
                            :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="payment method" rules="required" v-slot="{ errors }">
                      <div class="tableFromFieldBox">
                        <label for="updatePaymentDropDown">Payment Method<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="updateOrderForm.payment.payment_method"
                            id="updatePaymentDropDown"
                            :options="paymentMethodsList"
                            placeholder="Select Payment Method"
                            label="type"
                            track-by="id"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tempOrder.created_by == 'customer' || (tempOrder.status == 'Delivered' || 
                            tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <div class="tableFromFieldBox">
                      <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                        <label for="updateFormStatusDropDown">Status<span>*</span></label>
                        <div class="vueSelectBlock">
                          <Multiselect
                            v-model="updateOrderForm.status"
                            id="updateFormStatusDropDown"
                            :options="updateOrderStatusList"
                            placeholder="Select Status"
                            :object="true"
                            :allow-empty="false"
                            :hide-selected="false"
                            :disabled="tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded'"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="tableFromFieldBox">
                      <label for="updateFormCustomShippingCharges">Custom Shipping Charges</label>
                      <div class="addOrderNoteFieldBox">
                        <input
                          type="number"
                          name="updateFormCustomShippingCharges"
                          id="updateFormCustomShippingCharges"
                          placeholder="Write a custom shipping amount"
                          v-model="customShippingCharges"
                          :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                          tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                        <button 
                          @click.prevent="applyCustomShipping('update-order')"
                          :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                          tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        >
                          Add
                        </button>
                      </div>
                      <p class="fieldSeoMessage">
                        <span style="display: inline-flex;margin-right: 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                          </svg> 
                        </span>
                        If custom shipping charges will be added, the selected
                        shipping option will not be considered
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <form>
                      <div class="tableFromFieldBox">
                        <label for="updateFormCouponCode">Coupon</label>
                        <div class="addOrderNoteFieldBox coupon">
                          <input
                            type="text"
                            name="updateFormCouponCode"
                            id="updateFormCouponCode"
                            placeholder="Write a coupon code"
                            style="padding-right: 190px !important"
                            v-model="updateOrderForm.coupon.coupon_code"
                            :readonly="updateOrderForm.isUpdate"
                            :disabled="(tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                            tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                          />
                          <loaderBtn v-if="couponLoader"/>
                          <button 
                            @click.prevent="verifyCoupon('update-order')" 
                            :disabled="tourIsActive || updateOrderForm.isUpdate || (tempOrder.status == 'Delivered' || 
                            tempOrder.status == 'Cancelled' || tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                            v-else
                          >
                            <p>{{ updateOrderForm.isUpdate ? "Verified" : "Verify Coupon" }}</p>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </form>
            </div>
            <!-- order details block ends here -->

            <!-- order total block starts here -->
            <div class="productTableInfoWhiteBox" id="update-order-total-section">
              <div class="priceDetailList">
                <ul>
                  <li>
                    <h4>Total Cart Items</h4>
                    <h2>{{ updateOrderFormCalculate }}</h2>
                  </li>
                  <li>
                    <h4 class="greenColorTxt">Amount</h4>
                    <h2 class="greenColorTxt" style="font-weight: 500">
                      {{
                        updateOrderForm.payment.amount
                          ? settings.currency_unit ? settings.currency_symbol + " " + updateOrderForm.payment.amount.toLocaleString(
                              "ur-PK",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + updateOrderForm.payment.amount.toLocaleString(
                              "ur-PK",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                      }}
                    </h2>
                  </li>
                  <li v-if="updateOrderIsCustomTax">
                    <h4 style="font-weight: 300">Tax Percentage</h4>
                    <div class="taxDetailTxt">
                      <div class="quantityTaxDetail">
                        <button class="miniusIconBtn" 
                          @click="taxChangeHandle('update-order','decr')"
                          :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                          tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        >
                          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <g id="_-" data-name=" -" class="cls-1">
                                <g class="cls-1">
                                  <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <input 
                          type="number" 
                          name="stock" 
                          id="stock" 
                          placeholder="0" 
                          v-model="updateOrderForm.payment.tax" 
                          @blur="handleTaxInput('update-order')"
                          :disabled="tourIsActive || (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled' || 
                          tempOrder.status == 'Deleted' || tempOrder.status == 'Refunded')"
                        />
                        <button 
                          class="plusIconBtn" 
                          @click="taxChangeHandle('update-order','incr')"
                          :disabled="tourIsActive || (tempOrder.id != -1 && (tempOrder.status == 'Delivered' || tempOrder.status == 'Cancelled'))"
                        >
                          <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <g id="_" data-name=" " class="cls-1">
                                <g class="cls-1">
                                  <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>           
                      </div>
                      <a href="#." @click.prevent="updateOrderIsCustomTax = !updateOrderIsCustomTax">Save</a>
                    </div>
                  </li>
                  <li v-else>
                    <h4 style="font-weight: 300">Tax Percentage</h4>
                    <div class="taxDetailTxt">
                      <div class="quantityTaxDetail">
                        
                      </div>
                      <p>{{ updateOrderForm.payment.tax }}%</p>
                      <a 
                        href="#." 
                        @click.prevent="updateOrderIsCustomTax = !updateOrderIsCustomTax" 
                        id="custom-tax-btn" 
                        v-if="tempOrder.status != 'Deleted' && tempOrder.status != 'Cancelled' && 
                        tempOrder.status != 'Delivered' && tempOrder.status != 'Refunded' "
                      >
                        Add Custom Tax
                      </a>
                    </div>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Tax Amount</h4>
                    <h2 style="font-weight: 500">
                      {{ settings.currency_unit ? settings.currency_symbol + " " +
                        parseFloat(
                          (updateOrderForm.payment.amount - updateOrderForm.payment.discounted_amount) *
                            (updateOrderForm.payment.tax / 100)
                        ).toLocaleString("ur-PK", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) 
                        : ''
                      }}
                    </h2>
                  </li>
                  <li v-if="updateOrderForm.payment.is_custom_shipping">
                    <h4 style="font-weight: 300">Shipping Fee</h4>
                    <h2 style="font-weight: 500">
                      {{
                        updateOrderForm.payment.custom_shipping_amount
                          ? settings.currency_unit ? settings.currency_symbol + " " +parseFloat(
                              updateOrderForm.payment.custom_shipping_amount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(updateOrderForm.payment.custom_shipping_amount).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li v-else>
                    <h4 style="font-weight: 300">Shipping Fee</h4>
                    <h2 style="font-weight: 500">
                      {{
                        updateOrderForm.payment.shipping_amount
                          ? settings.currency_unit ? settings.currency_symbol + " " +parseFloat(
                              updateOrderForm.payment.shipping_amount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(updateOrderForm.payment.shipping_amount).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " +(0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li>
                    <h4 style="font-weight: 300">Discounted Amount</h4>
                    <h2 style="font-weight: 500">
                      {{
                        updateOrderForm.payment.discounted_amount
                          ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(
                              updateOrderForm.payment.discounted_amount
                            ).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }}
                    </h2>
                  </li>
                  <li>
                    <div class="netTotalBlock">
                      <p>Net Total</p>
                      <h5>
                        {{
                          updateOrderForm.payment.net_amount
                            ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(
                                updateOrderForm.payment.net_amount
                              ).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : settings.currency_symbol + " " + (0).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : settings.currency_symbol + " " + (updateOrderForm.payment.net_amount).toLocaleString("ur-PK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }}
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- order total block ends here -->

          </div>

        </div>
      </ValidationObserver>
      <!-- update order form ends here -->

    </div>


    <!-- bottom action bar start -->
    <div class="row product-bottom-bar" v-show="checkedItems.length > 1">
      <div class="col-md-6 model-footer-main1">
        <div class="bottom-bar">
          <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != orders.length">
            <i class="fa fa-check-square-o" aria-hidden="true"></i>
            <p>SELECT ALL</p>
          </a>
          <a href="" class="model-footer-1" @click.prevent="checkedItems = []">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
            <p>CLEAR SELECTION</p>
          </a>
        </div>
        <div class="model-footer-2">{{ checkedItems.length }}</div>
      </div>
      <div class="col-md-6 model-footer-main1 product-row-reverse">
        <div class="bottom-bar">
          <div class="select-box">
            <select name="globalTag" id="selectGlobalTag" v-model="selectedStatus">
              <option value="" disabled selected>-- Assign Status --</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Dispatched">Dispatched</option>
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Refunded">Refunded</option>
            </select>
            <button @click="applyAction('statusChange')">Apply</button>
          </div>
          <a href="" class="model-footer-1 ml-4" @click.prevent="applyAction('bulkDelete')">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
            <p>DELETE</p>
          </a>
        </div>
      </div>
    </div>
    <!-- bottom action bar end -->

    <!-- cart items list modal starts here -->
    <div class="modalBg addProductModel" :class="cartItemsModal.isOpen ? 'modal-active' : '' ">
      <div class="modalLayers" @click.prevent="loadAvailableCartItems"></div>
      <div class="modalBody">
        <div class="addProductTitle">
          <h3>ADD {{cartItemsModal.itemsType == 'products' ? 'PRODUCTS' : 'BUNDLES'}}</h3>
        </div>
        <div class="searchProductForm">
          <input
            type="search"
            name="searchProduct"
            id="searchProduct"
            :placeholder="cartItemsModal.itemsType == 'products' ? 'Search product' : 'Search bundle'"
            v-model="searchedCartItem"
            @input="searchCartItems(cartItemsModal.itemsType,'search',1)"
          />
          <div class="filtersRow">
            <div class="filtersList">
              <ul>
                <li :class="cartItemsModal.itemsType == 'products' ? 'active' : '' ">
                  <button @click.prevent="carItemTypeChangeHandle('products')">Products</button>
                </li>
                <li :class="cartItemsModal.itemsType == 'bundles' ? 'active' : '' " v-if="isDev()">
                  <button @click.prevent="carItemTypeChangeHandle('bundles')">Bundles</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="productMainSelectBox">

          <!-- cart items loader starts here -->
          <div class="showProductBox" v-if="cartItemsLoading">
            <div class="productGrayBox" v-for="i in 3" :key="i">
              <vue-skeleton-loader 
                type="rect" 
                :width="620" 
                :height="125" 
                animation="fade"
              />
            </div>
          </div>
          <!-- cart items loader ends here -->

          <!-- products list block starts here -->
          <div class="showProductBox" v-if="cartItemsModal.itemsType == 'products' && !cartItemsLoading">
            <div class="productGrayBox" v-if="cartItemsList.length == 0 && searchedCartItem.length != 0" style="justify-content: center !important">
              <p style="color: #000">
                No Active Products Found Against "{{ searchedCartItem }}"
              </p>
            </div>
            <div class="productGrayBox" v-if="cartItemsList.length == 0 && searchedCartItem == 0" style="justify-content: center !important">
              <p style="color: #000">
                No Active Products Available
              </p>
            </div>
            <div class="productGrayBox" v-for="product in cartItemsList" :key="product.id">
              <div class="productGrayBoxImg">
                <img 
                  :src="product.image ? base_image_url+ settings.store_id + '/product/' + product.image : '/images/no_image.png'" 
                  alt=""
                  @error="handleImageError"
                />
              </div>
              <div class="productGrayBoxdetail">
                <div class="productNameDetail">
                  <div class="ProductNameTxt">
                    <p>{{ product.name }}</p>
                  </div>
                </div>
                <div class="productSkuBlock">
                  <div 
                    class="productSkuWhiteBox" 
                    :class="variant.qty <= 0 || !variant.isActive ? 'stockOutProduct': ''" 
                    v-for="variant in product.variants" 
                    :key="variant.id" 
                    :data-toggle="variant.qty <= 0 ? 'tooltip' : ''" 
                    :data-placement="variant.qty <= 0 ? 'right' : ''" 
                    :title="variant.qty <= 0 ? 'Out of Stock' : !variant.isActive ? 'Variant Inactive': '' "
                  >
                    <h5>{{ variant.name }}</h5>
                    <p>{{ variant.sku }}</p>
                    <p class="productPrice" v-if="variant.sale_price">
                      {{ variant.sale_price ? settings.currency_symbol + " " + parseFloat(variant.sale_price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                      }}
                    </p>
                    <p class="productPrice" v-else>
                      {{ variant.price ? settings.currency_symbol + " " + parseFloat(variant.price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                      }}
                    </p>
                    <button
                      @click="
                        addVariant(cartItemsModal.form,{
                          product_id: product.id,
                          name: product.name,
                          type: product.product_type,
                          image: product.image,
                          product_deleted: product.deleted_at ? true : false,
                          variant: {
                            id: variant.id,
                            name: variant.name,
                            qty: 1,
                            stock: variant.qty,
                            price: variant.sale_price,
                            weight: variant.weight,
                            sku: variant.sku,
                            variant_deleted: variant.deleted_at ? true : false,
                            is_sale_price: true,
                          },
                        })
                      "
                      :disabled="variant.qty <= 0 || !variant.isActive"
                      v-if="variant.sale_price"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                        <g id="plus" transform="translate(0)">
                          <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                          <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                        </g>
                      </svg>
                    </button>
                    <button
                      @click="
                        addVariant(cartItemsModal.form,{
                          product_id: product.id,
                          name: product.name,
                          type: product.product_type,
                          image: product.image,
                          product_deleted: product.deleted_at ? true : false,
                          variant: {
                            id: variant.id,
                            name: variant.name,
                            qty: 1,
                            stock: variant.qty,
                            price: variant.price,
                            weight: variant.weight,
                            sku: variant.sku,
                            variant_deleted: variant.deleted_at ? true : false,
                            is_sale_price: false,
                          },
                        })
                      "
                      :disabled="variant.qty <= 0 || !variant.isActive"
                      v-else
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                        <g id="plus" transform="translate(0)">
                          <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                          <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- products list block ends here -->

          <!-- bundles list block starts here -->
          <div class="showProductBox" v-if="cartItemsModal.itemsType == 'bundles' && !cartItemsLoading">
            <div class="productGrayBox" v-if="cartItemsList.length == 0 && searchedCartItem.length != 0" style="justify-content: center !important">
              <p style="color: #000">
                No Active Bundles Found Against "{{ searchedCartItem }}"
              </p>
            </div>
            <div class="productGrayBox" v-if="cartItemsList.length == 0 && searchedCartItem == 0" style="justify-content: center !important">
              <p style="color: #000">
                No Active Bundles Available
              </p>
            </div>
            <div class="productGrayBox bundle" v-for="bundle in cartItemsList" :key="bundle.id">
              
              <div class="productGrayBoxImg">
                <img 
                  :src="bundle.image ? base_image_url + settings.store_id + '/product-bundle/' + bundle.image : '/images/no_image.png'" 
                  alt=""
                  @error="handleImageError"
                />
              </div>
              
              <div class="productGrayBoxdetail">
                <div class="productNameDetail bundle" v-if="bundle.discount_type != 'none'">
                  <div class="ProductNameTxt">
                    <p>{{ bundle.name }}</p>
                    <p>
                      {{ bundle.discounted_total ? settings.currency_symbol + " " + parseFloat(bundle.discounted_total).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0  
                      }}
                    </p>
                    <del>
                      {{ bundle.bundle_total ? settings.currency_symbol + " " + parseFloat(bundle.bundle_total).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0  
                      }}
                    </del>
                  </div>
                  <button @click.prevent="addBundle(cartItemsModal.form,bundle)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                      <g id="plus" transform="translate(0)">
                        <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                        <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                      </g>
                    </svg>
                  </button>
                </div>
                <div class="productNameDetail bundle" v-else>
                  <div class="ProductNameTxt">
                    <p>{{ bundle.name }}</p>
                    <p>
                      {{ bundle.bundle_total ? settings.currency_symbol + " " + parseFloat(bundle.bundle_total).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0  
                      }}
                    </p>
                  </div>
                  <button @click.prevent="addBundle(cartItemsModal.form,bundle)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                      <g id="plus" transform="translate(0)">
                        <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                        <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                      </g>
                    </svg>
                  </button>
                </div>
                <div class="productSkuBlock">
                  <div class="productSkuWhiteBox" v-for="(bundleItem, index) in bundle.bundle_items" :key="bundleItem.id" >
                    <h5>{{ index + 1 }}. {{ bundleItem.item_details.name }}</h5>
                    <p>{{ bundleItem.item_details.variant.name }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- bundles list block ends here -->


          <!-- added cart items block starts here -->
          <div class="addProductShowBox">
            <div class="addProductTitle">
              <h3>ADDED CART ITEMS</h3>
            </div>
            <div class="productDelList" v-if="cartItemsModal.form == 'add-order'">
              <ul>
                <li v-for="(item, index) in addOrderForm.items" :key="index">
                  <div class="productListDetail">
                    <div>
                      <h5>{{ item.name }}</h5>
                      <p>{{ item.variant.name }}</p>
                      <p>{{ item.variant.sku }}</p>
                    </div>
                    <p class="productListDetailPrice">
                      {{ item.variant.price ? settings.currency_symbol + " " + parseFloat(item.variant.price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                      }}
                    </p>
                  </div>
                  <div class="delIconBox">
                    <button @click="removeOrderItem(item, 'product-modal', index)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25.667" viewBox="0 0 22 25.667">
                        <g id="Group_175" data-name="Group 175" transform="translate(-1781 -573.417)">
                          <path id="Path_68" data-name="Path 68" d="M13.5,13.5h1.833v11H13.5Z" transform="translate(1774.833 569.083)" fill="#fd4d5d"/>
                          <path id="Path_69" data-name="Path 69" d="M20.25,13.5h1.833v11H20.25Z" transform="translate(1773.583 569.083)" fill="#fd4d5d"/>
                          <path id="Path_70" data-name="Path 70" d="M4.5,6.75V8.583H6.333V26.917A1.833,1.833,0,0,0,8.167,28.75H22.833a1.833,1.833,0,0,0,1.833-1.833V8.583H26.5V6.75ZM8.167,26.917V8.583H22.833V26.917Z" transform="translate(1776.5 570.333)" fill="#fd4d5d"/>
                          <path id="Path_71" data-name="Path 71" d="M13.5,2.25h7.333V4.083H13.5Z" transform="translate(1774.833 571.167)" fill="#fd4d5d"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="productDelList" v-else-if="cartItemsModal.form == 'update-order'">
              <ul>
                <li v-for="(item, index) in updateOrderForm.items" :key="index">
                  <div class="productListDetail">
                    <div>
                      <h5>{{ item.name }}</h5>
                      <p>{{ item.variant.name }}</p>
                      <p>{{ item.variant.sku }}</p>
                    </div>
                    <p class="productListDetailPrice">
                      {{ item.variant.price ? settings.currency_symbol + " " + parseFloat(item.variant.price).toLocaleString(
                            "ur-PK",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0 
                      }}
                    </p>
                  </div>
                  <div class="delIconBox">
                    <button @click="removeOrderItem(item, 'product-modal', index)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25.667" viewBox="0 0 22 25.667">
                        <g id="Group_175" data-name="Group 175" transform="translate(-1781 -573.417)">
                          <path id="Path_68" data-name="Path 68" d="M13.5,13.5h1.833v11H13.5Z" transform="translate(1774.833 569.083)" fill="#fd4d5d"/>
                          <path id="Path_69" data-name="Path 69" d="M20.25,13.5h1.833v11H20.25Z" transform="translate(1773.583 569.083)" fill="#fd4d5d"/>
                          <path id="Path_70" data-name="Path 70" d="M4.5,6.75V8.583H6.333V26.917A1.833,1.833,0,0,0,8.167,28.75H22.833a1.833,1.833,0,0,0,1.833-1.833V8.583H26.5V6.75ZM8.167,26.917V8.583H22.833V26.917Z" transform="translate(1776.5 570.333)" fill="#fd4d5d"/>
                          <path id="Path_71" data-name="Path 71" d="M13.5,2.25h7.333V4.083H13.5Z" transform="translate(1774.833 571.167)" fill="#fd4d5d"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- added cart items block ends here -->

        </div>

        <hr v-if="cartItemsPagination.total > 10">

        <el-pagination
          background
          layout="prev, pager, next"
          :page-count="cartItemsPagination.last_page"
          @current-change="cartItemPaginationChangeHandle"
          class="mt-4"
          v-if="cartItemsPagination.total > 10"
        />

        <hr>

        <div class="modalDoneButton">
          <button 
            @click.prevent="loadAvailableCartItems" 
            :disabled="(addOrderForm.isOpen && addOrderForm.items.length == 0) ||
            (updateOrderForm.isPreview && updateOrderForm.items.length == 0)"
          >
            Done
          </button>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="loadAvailableCartItems">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
              <path id="close" d="M31,10.925,29.075,9,20,18.075,10.925,9,9,10.925,18.075,20,9,29.075,10.925,31,20,21.925,29.075,31,31,29.075,21.925,20Z" transform="translate(-9 -9)" fill="#22678d"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- cart items list modal ends here -->

    <!-- update Order Note modal starts here -->
    <div class="modalBg passwordResetPopup" :class="orderNoteForm.isOpen ? 'modal-active' : ''">
      <div class="modalLayers" @click.prevent="orderNoteForm.isOpen = false"></div>
      <div class="modalBody">
        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%;overflow: hidden !important;">
          <ValidationObserver>
            <form @submit.prevent="addOrderForm.isOpen ? updateOrderNote('add-order') : updateOrderNote('update-order')">
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider name="order note" :rules="{required:true,regex:/^\S.*\S$/}" v-slot="{ errors }">
                    <div class="tableFromFieldBox">
                      <label class="formLabelCustomer">Order Note <span>*</span></label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Order note"
                        v-model="orderNoteForm.updatedOrderNote"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <div class="formCustomerBtn">
                    <loaderBtn v-if="orderNoteForm.pending"/>
                    <button type="submit" v-else>Update</button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="modalCloseIcon">
          <button @click.prevent="orderNoteForm.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- update Order Note modal ends here -->

    <!-- add customer modal starts here -->
    <ValidationObserver ref="orderCustomerFormObserver">
      <div class="modalBg" :class="customer.isOpen ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="resetValidator"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 0px; right: 0; width: 100%">
            <div id="London" class="tabcontent">
              <div class="contaient-div horizontal-scroll-fix">
                <div class="titleActiveBox">
                  <div class="customer-detail">
                    <h2 style="margin-bottom: 0px !important;">CUSTOMER DETAIL</h2>
                  </div>
                  <div class="shipping-table">
                    <table class="table">
                      <tr>
                        <td class="p-status-flex">
                          <p class="p2" style="margin-right: 0px !important;">{{customer.isActive ? 'ACTIVE' : 'INACTIVE'}}</p>
                          <div class="actionSwitch" style="display: inline-flex;align-items: center;">
                            <label class="switch">
                              <input
                                type="checkbox"
                                id="addcustomer"
                                v-model="customer.isActive"
                              />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="uploadNewUserImg">
                  <div class="userDetailsImg">
                    <img :src="customer.image_src ? customer.image_src : '/images/customer_noImage.png'" alt=""/>
                  </div>
                  <div class="uploadImgFile">
                    <input 
                      type="file" 
                      accept="image/png,image/jpg,image/jpeg,image/webp"
                      @change.prevent="changeAddImage" 
                    />
                    <button>
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <form class="customerDetailFormBox" @submit.prevent="validate('addCustomer')">
                  <div class="row">
                    <div class="col-md-6">
                      <ValidationProvider name="first name" :rules="{required:true,max:50}" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="addCustomerFirstName">First Name <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="text"
                              class="form-control"
                              id="addCustomerFirstName"
                              name="addCustomerFirstName"
                              v-model="customer.first_name"
                              @input="handleFormat('f_name')"
                              placeholder="First name"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="last name" :rules="{required:true,max:50}" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="addCustomerLastName">Last Name <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="text"
                              class="form-control"
                              id="addCustomerLastName"
                              name="addCustomerLastName"
                              v-model="customer.last_name"
                              @input="handleFormat('l_name')"
                              placeholder="Last name"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="email" rules="required|email|max:250" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="addCustomerEmail">Email <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="email"
                              class="form-control"
                              id="addCustomerEmail"
                              name="addCustomerEmail"
                              v-model="customer.email"
                              placeholder="Email"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="password" :rules="{required:true,min:8,max:250}" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="addCustomerPassword">Password <span>*</span></label>
                          <div class="tableFromFieldItem passwordBtnIconPd">
                            <a href="#." class="genPasswordBtn" @click.prevent="generatePassword('sliderForm',16)">Generate Password</a>
                            <input
                              :type="customer.passwordShow ? 'text' : 'password'"
                              class="form-control"
                              v-model="customer.password"
                              placeholder="Password"
                              autocomplete="true"
                              ref="passwordInput"
                              id="addCustomerPassword"
                              name="addCustomerPassword"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                            <button 
                              type="button" 
                              class="passwordEyeBtn" 
                              @click.prevent="customer.passwordShow = !customer.passwordShow"
                            >
                              <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" v-if="customer.passwordShow">
                                <path d="M325.1 351.5L225.8 273.6c8.303 44.56 47.26 78.37 94.22 78.37C321.8 352 323.4 351.6 325.1 351.5zM320 400c-79.5 0-144-64.52-144-143.1c0-6.789 1.09-13.28 1.1-19.82L81.28 160.4c-17.77 23.75-33.27 50.04-45.81 78.59C33.56 243.4 31.1 251 31.1 256c0 4.977 1.563 12.6 3.469 17.03c54.25 123.4 161.6 206.1 284.5 206.1c45.46 0 88.77-11.49 128.1-32.14l-74.5-58.4C356.1 396.1 338.1 400 320 400zM630.8 469.1l-103.5-81.11c31.37-31.96 57.77-70.75 77.21-114.1c1.906-4.43 3.469-12.07 3.469-17.03c0-4.976-1.562-12.6-3.469-17.03c-54.25-123.4-161.6-206.1-284.5-206.1c-62.69 0-121.2 21.94-170.8 59.62L38.81 5.116C34.41 1.679 29.19 0 24.03 0C16.91 0 9.839 3.158 5.121 9.189c-8.187 10.44-6.37 25.53 4.068 33.7l591.1 463.1c10.5 8.203 25.57 6.333 33.69-4.073C643.1 492.4 641.2 477.3 630.8 469.1zM463.1 256c0 24.85-6.705 47.98-17.95 68.27l-38.55-30.23c5.24-11.68 8.495-24.42 8.495-38.08c0-52.1-43-96-95.1-96c-2.301 .0293-5.575 .4436-8.461 .7658C316.8 170 319.1 180.6 319.1 192c0 10.17-2.561 19.67-6.821 28.16L223.6 149.9c25.46-23.38 59.12-37.93 96.42-37.93C399.5 112 463.1 176.6 463.1 256z"/>
                              </svg>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" v-else>
                                <g id="eyeOpen" transform="translate(-355 -428)">
                                  <g id="eye-svgrepo-com" transform="translate(355 333.275)">
                                  <path id="Path_31" data-name="Path 31" d="M11,98.725c-4.2,0-8.015,2.3-10.828,6.035a.871.871,0,0,0,0,1.04c2.813,3.74,6.625,6.039,10.828,6.039s8.015-2.3,10.828-6.035a.871.871,0,0,0,0-1.04C19.015,101.025,15.2,98.725,11,98.725Zm.3,11.174a4.627,4.627,0,1,1,4.316-4.316A4.63,4.63,0,0,1,11.3,109.9Zm-.14-2.133a2.492,2.492,0,1,1,2.327-2.327A2.488,2.488,0,0,1,11.162,107.766Z"></path></g><rect id="Rectangle_12990" data-name="Rectangle 12990" width="22" height="22" transform="translate(355 428)" fill="none">
                                  </rect>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4" id="mobile-no-field">
                      <ValidationProvider name="contact no." :rules="countryIsSelected ? 'required' : ''" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Contact No. <span>*</span></label>
                          <div class="formContentBox">
                            <vue-tel-input 
                              v-model="customer.mobile_no"
                              mode="international"
                              :onlyCountries="allowedCountries"
                              :inputOptions="ContactFieldOptions"
                              ref="contactNoField"
                              :dropdownOptions="ContactFieldDropDownOptions"
                              :validCharactersOnly=true 
                              @input="contactInputHandle"
                              @validate="validateContactNo"
                              @country-changed="countryChangeHandle"
                              @focus="contactFieldFocusHandle"
                              @close="dropDownCloseHandle"
                            >
                            </vue-tel-input>
                            <span class="text-danger" v-if="errors.length > 0">{{ errors[0] }}</span>
                            <span class="text-danger" v-else-if="!countryIsSelected && contactFieldTouched">
                              Select a country from the dropdown menu or add the country code before the contact no.
                            </span>
                            <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">Enter a valid contact no.</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">Country <span>*</span></label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              v-model="selectedCustomerCountry"
                              :clearOnSelect="true"
                              :options="availableCountries"
                              label="name"
                              :multiple="false"
                              :object="true"
                              track-by="name"
                              :hideSelected="false"
                              :showNoResults="true"
                              placeholder="Select country"
                              :showOptions="true"
                              :searchable="true"
                              openDirection="bottom"
                              @select="countrySelectHandle($event,'add-customer')"
                            >
                              <span slot="noResult">No country found matching the searched keyword.</span>
                            </Multiselect>
                            <span class="text-danger" >{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">State <span>*</span></label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              v-model="selectedCustomerState"
                              :clearOnSelect="true"
                              :options="selectedCustomerCountryStates"
                              label="name"
                              track-by="name"
                              :hideSelected="false"
                              :showNoResults="true"
                              :multiple="false"
                              :object="false"
                              :showOptions="false"
                              :searchable="true"
                              openDirection="bottom"
                              :placeholder="selectedCustomerCountryStatesLoading ? 'Loading states....' : 'Select state'"
                              :loading="selectedCustomerCountryStatesLoading"
                              @select="stateSelectHandle($event,'add-customer')"
                            >
                              <span slot="noOptions">To load the states, please select a country.</span>
                              <span slot="noResult">No state found matching the searched keyword.</span>
                            </Multiselect>
                            <span class="text-danger" >{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4">
                      <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="">City <span>*</span></label>
                          <div class="vueSelectBlock">
                            <Multiselect
                              v-model="selectedCustomerCity"
                              :clearOnSelect="true"
                              :options="selectedCustomerStateCities"
                              label="name"
                              track-by="name"
                              :hideSelected="false"
                              :showNoResults="true"
                              :multiple="false"
                              :object="true"
                              :showOptions="true"
                              :searchable="true"
                              openDirection="bottom"
                              :placeholder="selectedCustomerStateCitiesLoading ? 'Loading cities....' : 'Select city'"
                              :loading="selectedCustomerStateCitiesLoading"
                            >
                              <span slot="noOptions">To load the cities, please select a state.</span>
                              <span slot="noResult">No city found matching the searched keyword.</span>
                            </Multiselect>
                            <span class="text-danger" >{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="address line 1" rules="required|max:250" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="addCustomerAddressLine1">Address Line 1 <span>*</span></label>
                          <div class="formContentBox">
                            <input
                              type="text"
                              class="form-control"
                              id="addCustomerAddressLine1"
                              name="addCustomerAddressLine1"
                              v-model="customer.address_line_1"
                              placeholder="Address line 1"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider name="address line 2" rules="max:250" v-slot="{ errors }">
                        <div class="tableFromFieldBox">
                          <label for="addCustomerAddressLine2">Address Line 2</label>
                          <div class="formContentBox">
                            <input
                              type="text"
                              class="form-control"
                              id="addCustomerAddressLine2"
                              name="addCustomerAddressLine2"
                              v-model="customer.address_line_2"
                              placeholder="Address line 2"
                            />
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <div class="formCustomerBtn">
                        <loaderBtn v-if="customer.isLoading"/>
                        <button v-else type="submit">Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="resetValidator">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <!-- add customer modal ends here -->

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import _ from "lodash";
import { Message, MessageBox, Loading } from "element-ui";
import Swal from 'sweetalert2';
import loaderBtn from "./loaderBtn.vue";

export default {
  components: {
    Multiselect,
    loaderBtn
  },
  name: "OrderTable",
  customerProfile: null,
  data: () => ({
    base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
    addOrderStatusList:[
      "Paid",
      "Pending",
      "Confirmed",
      "Dispatched",
      "Delivered",
    ],
    updateOrderStatusList: [
      "Paid",
      "Pending",
      "Confirmed",
      "Dispatched",
      "Delivered",
      "Cancelled",
      "Refunded",
    ],

    isUpdate: false,
    value: {
      first_name: "",
      id: "",
      email: "",
      mobile_no: "",
    },
    list_customer: [],
    tempCustomersList: [],
    orders: [],

    orderLoader: false,
    fieldRequired: false,
    customerFieldRequired: false,
    regions: [],
    regionsLoader: false,
    selectedShipping: "",
    tempOrder: [],
    orderUpdate: false,
    tempOrderNote: "",

    customer: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      mobile_no: "",
      city: null,
      address_line_1: "",
      address_line_2: "",
      image: "",
      image_src: "",
      isLoading: false,
      isActive: true,
      isOpen: false,
      passwordShow: false,
    },

    checkedItems: [],
    deletingItems: [],
    deleteLoader: false,
    selectedStatus: "",
    isCreateNew: false,
    isMasterSearched: false,

    ordersLoading: false,
    paymentMethodsList: [],

    //product popup starts here
    searchedProduct: "",
    productSearchLoader: false,
    productsPagination:{
      current_page: 0,
      total: 0,
      previous_page: 0,
      last_page: 0,
    },
    //product popup ends here

    //add order starts here
    addOrderForm:{
      order_date: "",
      order_no: "",
      delivery_date: "",
      status: "",
      order_notes: "",
      total_products: "",
      total_weight: "",
      shipping: {
        shipping_first_name: "",
        shipping_last_name: "",
        shipping_email: "",
        shipping_contact_no: "",
        shipping_postal_code: "",
      },
      billing:{
        billing_first_name: "",
        billing_last_name: "",
        billing_email: "",
        billing_contact_no: "",
        billing_postal_code: "",
      },
      customer: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        mobile_no: "",
      },
      payment:{
        payment_method: {
          id: "",
          type: "",
        },
        amount: 0,
        net_amount: 0,
        coupon_discounted_amount: 0,
        shipping_amount:null,
        tax: 0,
        tax_amount: 0,
        status: "",
        shipping_rule_id: '',
        shipping_rule_price_id: "",
        is_custom_shipping: false,
      },
      coupon:{
        coupon_code: "",
        coupon_data: {},
        discounted_amount: 0,
      },
      items: [],
      activities: [],
      notes: [],
      created_by: "Admin",
      isOpen: false,
      isTouched: false,
    },
    //add order ends here

    //update order starts here
    updateOrderForm:{
      id:"",
      index:"",
      order_date: "",
      order_no: "",
      delivery_date: "",
      status: "",
      order_notes: "",
      created_by: "",
      previewable: "",
      total_products: "",
      total_weight: "",
      shipping: {
        shipping_first_name: "",
        shipping_last_name: "",
        shipping_email: "",
        shipping_contact_no: "",
        shipping_postal_code: "",
      },
      billing:{
        billing_first_name: "",
        billing_last_name: "",
        billing_email: "",
        billing_contact_no: "",
        billing_postal_code: "",
      },
      customer: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        mobile_no: "",
      },
      payment:{
        payment_method: {
          id: "",
          type: "",
        },
        amount: 0,
        net_amount: 0,
        discounted_amount:0,
        shipping_amount:null,
        tax: 0,
        tax_amount: 0,
        status: "",
        shipping_rule_id: '',
        shipping_rule_price_id:"",
        shipping:null,
        shipping_rule:null,
        shipping_rule_price:null,
        is_custom_shipping: false,
        custom_shipping_amount:0,
      },
      coupon:{
        coupon_code: "",
        coupon_data:{},
        discounted_amount:0,
      },
      coupon_usage:null,
      items: [],
      activities: [],
      notes: [],
      isPreview:false,
    },
    //update order ends here

    //products start here
    product_list: [],
    //products end here

    //cart items modal start
    cartItemsList: [],
    searchedCartItem: "",
    cartItemsModal:{
      form: '',
      itemsType: 'products',
      isOpen: false,
    },
    cartItemsPagination:{
      current_page: 0,
      total: 0,
      previous_page: 0,
      last_page: 0,
    },
    cartItemsLoading: false,
    //cart items modal end

    //order notes start here
    orderNote: "",
    tempOrderNotes: [],
    orderNoteForm: {
      isOpen: false,
      updatedOrderNote: "",
      index: "",
      pending: false,
    },
    selectedOrderNote: "",
    //order notes ends here

    customShippingCharges: null,

    addOrderSelectedShipping: "",
    addOrderShippingRules: null,

    addOrderIsCustomTax: false,

    updateOrderSelectedShipping: "",
    updateOrderShippingRules: null,

    updateOrderIsCustomTax: false,

    coupon: [],
    couponLoader: false,

    availableCountries: [],

    shipping_address: "",
    billing_address: "",

    //shipping
    selectedShippingCountry: null,
    selectedShippingCountryStates: [],
    shippingCountryStatesLoading: false,
    selectedShippingState: null,
    selectedShippingStateCities: [],
    shippingStateCitiesLoading: false,
    selectedShippingCity: null,

    //billing
    isSameBilling:true,
    selectedBillingCountry: null,
    selectedBillingCountryStates: [],
    billingCountryStatesLoading: false,
    selectedBillingState: null,
    selectedBillingStateCities: [],
    billingStateCitiesLoading: false,
    selectedBillingCity: null,

    //customer modal country, state & city handle
    selectedCustomerCountry: null,
    selectedCustomerCountryStates: [],
    selectedCustomerCountryStatesLoading: false,
    selectedCustomerState: null,
    selectedCustomerStateCities: [],
    selectedCustomerStateCitiesLoading: false,
    selectedCustomerCity: null,

    tourIsActive: false,
    imageValidations:{
      imgIsRequired: false,
      imgInvalidFormat: false,
      isNotImg: false,
    },
    //add customer contact field handle
    allowedCountries:[],
    ContactFieldOptions:{
      placeholder: "Contact no.",
      showDialCode: true,
      type: 'tel',
      maxlength: 25,
    },
    ContactFieldDropDownOptions:{
      showSearchBox: true,
      showDialCodeInSelection: false,
      showDialCodeInList: true,
      showFlags: true,
      placeholder: "Select a Country"
    },
    contactIsValid: true,
    countryIsSelected: false,
    contactFieldTouched: false,
    selectedContactObj: {},
    selectedContactCountry: [],
    //shipping contact handle
    shippingContactIsValid: true,
    shippingCountryIsSelected: false,
    shippingContactFieldTouched: false,
    shippingSelectedContactObj: {},
    shippingSelectedContactCountry: [],
    //billing contact handle
    billingContactIsValid: true,
    billingCountryIsSelected: false,
    billingContactFieldTouched: false,
    billingSelectedContactObj: {},
    billingSelectedContactCountry: [],

  }),
  methods: {

    //infinite scroll start
    handleScrollEvent(event){

      let element = this.$refs.newOrdertable;

      let maxScrollTop = element.scrollHeight - element.clientHeight;

      if(element.scrollTop >= maxScrollTop - 100){

        if(this.isPending == false  && this.storeOrdersCurrentPage != this.storeOrdersLastPage){

          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: this.storeOrdersCurrentPage + 1, loadData: 'more' });

        }

      }

    },
    //infinite scroll end

    copyOrderNo(order){

      const storage = document.createElement("textarea");

      storage.value = order.order_no;

      this.$refs.newOrdertable.appendChild(storage);

      storage.select();

      storage.setSelectionRange(0, 99999);

      document.execCommand("copy");

      this.$refs.newOrdertable.removeChild(storage);

      this.$notify({
        type: "success",
        title: "Success",
        message: "Order no. has been successfully copied to clipboard.",
      });

    },

    orderSelection(order){

      this.tempOrder = _.cloneDeep(order);

    },
    async handleOrderStatusChange(order, index){

      if(!order.delivery_date && order.status == 'Delivered'){

        this.$notify({
          type: "info",
          title: "Delivery Date Missing",
          message: "Order delivery date must be provided.",
        });

        this.orders[index].status = this.tempOrder.status;

        return

      }

      MessageBox.confirm(
        "Confirm changes?", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "The order is undergoing status update. Please wait!",
          fullscreen: true,
        });

        let formData = new FormData();

        formData.append("id", order.id);
        formData.append("status", order.status);

        try{
          let res = await this.$axios.post("/order/status-change", formData);
          if(res.data.status_code == "10256"){

            let order = res.data.order;

            let jsonItems = [];

            order.items.forEach((item) =>{

              let obj = JSON.parse(item.product);

              if(item.actual_product && 
              item.actual_product.is_deleted){

                obj.product_deleted = true;

              }else{

                obj.product_deleted = false;

              }

              jsonItems.push(obj);

            });

            order.items = jsonItems;

            this.$notify({
              title: "Success",
              type: "success",
              message: res.data.message,
            });

            let updatedOrder = {
              i: index,
              updatedOrder: order,
            };

            this.$store.commit("orders_module/update_orders_list",updatedOrder);

            this.$store.dispatch('main_stats/fetchStatsRequest');

            this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);
            
            this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

            this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'});

            this.tempOrder = [];

            this.searchedCartItem = "";

            this.searchCartItems('products', "simple", 1);

          }
        }catch(error){

          this.orders[index].status = this.tempOrder.status;

          if(error.response){

            if(error.response.data.error.includes("order status can be") ||
            error.response.data.error.includes("not possible")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

        }

      }).catch(() => {

        this.orders[index].status = this.tempOrder.status;

        this.$notify({
          type: "info",
          title: "Changes Discarded",
          message: "Changes discarded successfully",
        });

        this.tempOrder = [];

      });

    },

    searchOrder(searchedKeyword, searchedStatus){

      if(this.addOrderForm.isOpen){

        this.newOrderOperations('close','without-confirmation');

      }else if(this.updateOrderForm.isPreview){

        this.updateOrderOperations('close','without-confirmation');

      }

      this.searchLoader = true;
      this.ordersLoading = true;

      clearTimeout(this.timer);

      this.timer = setTimeout(() => {

        if(searchedKeyword || searchedStatus){

          let payload = {
            keyword: searchedKeyword,
            status: searchedStatus,
          };

          this.$store.commit("orders_module/update_searched_keyword", payload);
          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

        }else{

          this.$store.commit("orders_module/remove_searched_keyword");
          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

        }

      }, 500);

    },

    async validate(action){

      if(action == "add"){

        this.$refs.addOrderFormObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.addOrderFormObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.addOrderFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(this.addOrderForm.customer.id == ""){

              this.customerFieldRequired = true;

            }

            if(this.addOrderForm.payment.payment_method.id == ""){

              this.fieldRequired = true;

            }

            if(typeof this.shippingSelectedContactObj.valid == 'undefined'){

              this.shippingContactIsValid = false;
              this.shippingContactFieldTouched = true;

              document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(typeof this.billingSelectedContactObj.valid == 'undefined'){

              this.billingContactIsValid = false;
              this.billingContactFieldTouched = true;

              document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }else{

            if(typeof this.shippingSelectedContactObj.valid == 'undefined'){

              this.shippingContactIsValid = false;
              this.shippingContactFieldTouched = true;

              document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(typeof this.billingSelectedContactObj.valid == 'undefined'){

              this.billingContactIsValid = false;
              this.billingContactFieldTouched = true;

              document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(this.shippingContactIsValid && this.shippingCountryIsSelected &&
            this.billingContactIsValid && this.billingCountryIsSelected){

              this.generateOrder();

            }else{
              
              if(!this.shippingContactIsValid || !this.shippingCountryIsSelected){

                document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }else if (!this.billingContactIsValid || !this.billingCountryIsSelected){

                document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }

            }

          }

        });

      }

      if(action == "update"){

        this.$refs.updateOrderFormObserver.validate().then((success) => {

          if(!success){

            const errors = Object.entries(this.$refs.updateOrderFormObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.updateOrderFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(typeof this.shippingSelectedContactObj.valid == 'undefined'){

              this.shippingContactIsValid = false;
              this.shippingContactFieldTouched = true;

              document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

            if(typeof this.billingSelectedContactObj.valid == 'undefined'){

              this.billingContactIsValid = false;
              this.billingContactFieldTouched = true;

              document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }else{

            if(this.shippingContactIsValid && this.shippingCountryIsSelected &&
            this.billingContactIsValid && this.billingCountryIsSelected){

              this.updateGeneratedOrder();

            }else{
              
              if(!this.shippingContactIsValid || !this.shippingCountryIsSelected){

                document.querySelector("#shipping-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }else if(!this.billingContactIsValid || !this.billingCountryIsSelected){

                document.querySelector("#billing-contact").scrollIntoView({ behavior: 'smooth',block: "center" });

              }

            }

          }

        });

      }

      if(action == "addCustomer"){

        this.$refs.orderCustomerFormObserver.validate().then((success) => {

          if(!success){
            
            const errors = Object.entries(this.$refs.orderCustomerFormObserver.errors)
            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

            this.$refs.orderCustomerFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

            if(typeof this.selectedContactObj.valid == 'undefined'){

              this.contactIsValid = false;
              this.contactFieldTouched = true;

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

            }else if(!this.selectedContactObj.valid){

              this.contactIsValid = false;
              this.contactFieldTouched = true;

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

            }

          }else{

            if(typeof this.selectedContactObj.valid == 'undefined'){

              this.contactIsValid = false;
              this.contactFieldTouched = true;

            }else if(!this.selectedContactObj.valid){

              this.contactIsValid = false;
              this.contactFieldTouched = true;

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" })

            }

            if(this.contactIsValid && this.countryIsSelected){

              this.handleAddCustomer();

            }else{

              document.querySelector("#mobile-no-field").scrollIntoView({ behavior: 'smooth',block: "center" });

            }

          }

        });

      }

    },
    async generateOrder(){

      if(this.addOrderForm.payment.payment_method.id == ""){

        this.$notify({
          type: "info",
          title: "Alert",
          message: "Payment method must be selected.",
        });

        return;

      }

      if(this.addOrderForm.customer.id == ''){

        this.$notify({
          type: "info",
          title: "Alert",
          message: "Customer must be selected.",
        });

        return

      }

      if(this.addOrderForm.items.length == 0){

        this.$notify({
          type: "info",
          title: "Alert",
          message: "At least one product must be selected.",
        });

        return;

      }
      
      let formData = new FormData();
      
      formData.append('order_date',this.addOrderForm.order_date);
      formData.append('delivery_date',this.addOrderForm.delivery_date);
      formData.append('status',this.addOrderForm.status);

      formData.append('items',JSON.stringify(this.addOrderForm.items));

      formData.append('customer[id]',this.addOrderForm.customer.id);


      formData.append('payment[amount]',this.addOrderForm.payment.amount);
      formData.append('payment[net_amount]',this.addOrderForm.payment.net_amount);

      formData.append('payment[tax]',this.addOrderForm.payment.tax);

      formData.append('payment[payment_method][id]',this.addOrderForm.payment.payment_method.id);

      formData.append('payment[is_custom_shipping]',this.addOrderForm.payment.is_custom_shipping ? 1 : 0);

      if(this.addOrderForm.payment.is_custom_shipping){

        formData.append('payment[custom_shipping_amount]',this.addOrderForm.payment.shipping_amount);

      }else{

        if(this.addOrderForm.payment.shipping_rule_id){

          formData.append('payment[shipping_rule_id]',this.addOrderForm.payment.shipping_rule_id);

        }

        if(this.addOrderForm.payment.shipping_rule_price_id){

          formData.append('payment[shipping_rule_price_id]',this.addOrderForm.payment.shipping_rule_price_id);

        }

      }

      if(this.addOrderShippingRules.is_free_shipping){

        formData.append('payment[is_free_shipping]',1);

        formData.append('payment[free_shipping_id]',this.addOrderShippingRules.shipping_id);

      }else{

        formData.append('payment[is_free_shipping]',0);
        
      }

      formData.append('payment[status]',this.addOrderForm.payment.status);

      formData.append('shipping[shipping_first_name]',this.addOrderForm.shipping.shipping_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_last_name]',this.addOrderForm.shipping.shipping_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_email]',this.addOrderForm.shipping.shipping_email);
      formData.append('shipping[shipping_contact_no]',this.addOrderForm.shipping.shipping_contact_no);
      formData.append('shipping[shipping_city]',this.selectedShippingCity.id);
      formData.append('shipping[shipping_address]',this.shipping_address.replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_postal_code]',this.addOrderForm.shipping.shipping_postal_code.replace(/\s+/g,' ').trim());
      
      formData.append('shipping[is_same_billing]',this.isSameBilling ? 1 : 0);

      //billing details
      if(!this.isSameBilling){

        formData.append('billing[billing_first_name]',this.addOrderForm.billing.billing_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_last_name]',this.addOrderForm.billing.billing_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_email]',this.addOrderForm.billing.billing_email);
        formData.append('billing[billing_contact_no]',this.addOrderForm.billing.billing_contact_no);
        formData.append('billing[billing_city]',this.selectedBillingCity.id)
        formData.append('billing[billing_address]',this.billing_address.replace(/\s+/g,' ').trim());
        formData.append('billing[billing_postal_code]',this.addOrderForm.billing.billing_postal_code.replace(/\s+/g,' ').trim());

      }

      if(this.addOrderForm.notes.length > 0){

        formData.append('notes',JSON.stringify(this.addOrderForm.notes));

      }

      if(this.coupon.length > 0){

        formData.append('coupon_code',this.addOrderForm.coupon.coupon_code);
        formData.append('payment[coupon_discounted_amount]',this.addOrderForm.payment.coupon_discounted_amount);

      }

      this.orderLoader = true;
      try{
        let res = await this.$axios.post('/dashboard/order/generate',formData);
        if(res.data.status_code == "1023"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.$store.commit('orders_module/add_orders_list',res.data.order);

          this.$store.dispatch( "dashboard_stats/fetchStatsRequest", this.duration);

          this.$store.dispatch('main_stats/fetchStatsRequest');

          this.$store.dispatch("dashboard_order_stats/fetchStatsRequest");

          this.$store.commit('products_module/CLEAR_MODULE');

          this.$store.commit('customers_module/CLEAR_MODULE');

          this.searchedCartItem = "";

          this.searchCartItems('products', "simple", 1);

          this.newOrderOperations('close','without-confirmation');

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.shipping_amount){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.shipping_amount[0]
            });

          }else if(error.response.data.error.order_date){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.order_date[0],
            });

          }else if(error.response.data.error.status){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.status[0],
            });

          }else if(error.response.data.error.total_products){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.total_products[0],
            });

          }else if(error.response.data.error.payment){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.payment[0],
            });

          }else if(error.response.data.error.customer){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error.customer[0],
            });

          }else if(error.response.data.error.includes('quantity specified') ||
          error.response.data.error.includes("doesn't support") ||
          error.response.data.error.includes("temporarily unavailable") ||
          error.response.data.error.includes("no longer available")){

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.orderLoader = false;

      }

    },
    async updateGeneratedOrder(){

      if(this.tempOrder.status == "Cancelled"){

        this.$notify({
          type: "error",
          title: "Alert",
          message: "Cancelled order cannot be updated.",
        });

        return;

      }

      if(this.updateOrderForm.payment.payment_method.id == ""){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "Payment method must be selected.",
        });

        return;

      }

      if(this.updateOrderForm.customer.id == ""){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "Customer must be selected.",
        });

        return;

      }

      if(this.updateOrderForm.items.length == 0){

        this.$notify({
          type: "warning",
          title: "Alert",
          message: "At least one product must be selected.",
        });

        return;

      } 

      let formData = new FormData();

      formData.append('id',this.updateOrderForm.id);
      formData.append('order_no',this.updateOrderForm.order_no);
      formData.append('order_date',this.updateOrderForm.order_date);
      formData.append('delivery_date',this.updateOrderForm.delivery_date);
      formData.append('status',this.updateOrderForm.status);

      formData.append('items',JSON.stringify(this.updateOrderForm.items));

      formData.append('customer[id]',this.updateOrderForm.customer.id);

      formData.append('payment[amount]',this.updateOrderForm.payment.amount);
      formData.append('payment[net_amount]',this.updateOrderForm.payment.net_amount);

      formData.append('payment[tax]',this.updateOrderForm.payment.tax);

      formData.append('payment[payment_method][id]',this.updateOrderForm.payment.payment_method.id);

      formData.append('payment[is_custom_shipping]',this.updateOrderForm.payment.is_custom_shipping == 1 || this.updateOrderForm.payment.is_custom_shipping ? 1 : 0);

      if(this.updateOrderForm.payment.is_custom_shipping == 1 || this.updateOrderForm.payment.is_custom_shipping){

        formData.append('payment[custom_shipping_amount]',this.updateOrderForm.payment.shipping_amount ? this.updateOrderForm.payment.shipping_amount : 
        this.updateOrderForm.payment.custom_shipping_amount);

      }else{

        if(this.updateOrderForm.payment.shipping_rule_id){

          formData.append('payment[shipping_rule_id]',this.updateOrderForm.payment.shipping_rule_id);

        }

        if(this.updateOrderForm.payment.shipping_rule_price_id){

          formData.append('payment[shipping_rule_price_id]',this.updateOrderForm.payment.shipping_rule_price_id);

        }

      }

      if(this.updateOrderShippingRules.is_free_shipping){

        formData.append('payment[is_free_shipping]',1);

        formData.append('payment[free_shipping_id]',this.updateOrderShippingRules.shipping_id);

      }else if(this.updateOrderForm.payment.is_free_shipping == 1){

        formData.append('payment[is_free_shipping]',1);

        formData.append('payment[free_shipping_id]',this.updateOrderForm.payment.shipping_id);

      }else{

        formData.append('payment[is_free_shipping]',0);

      }

      formData.append('payment[status]',this.updateOrderForm.status);

      formData.append('shipping[shipping_first_name]',this.updateOrderForm.shipping.shipping_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_last_name]',this.updateOrderForm.shipping.shipping_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_email]',this.updateOrderForm.shipping.shipping_email);
      formData.append('shipping[shipping_contact_no]',this.updateOrderForm.shipping.shipping_contact_no);
      formData.append('shipping[shipping_city]',this.selectedShippingCity.id);
      formData.append('shipping[shipping_address]',this.shipping_address.replace(/\s+/g,' ').trim());
      formData.append('shipping[shipping_postal_code]',this.updateOrderForm.shipping.shipping_postal_code.replace(/\s+/g,' ').trim());
      
      formData.append('shipping[is_same_billing]',this.isSameBilling ? 1 : 0);

      //billing details
      if(!this.isSameBilling){

        formData.append('billing[billing_first_name]',this.updateOrderForm.billing.billing_first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_last_name]',this.updateOrderForm.billing.billing_last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
        formData.append('billing[billing_email]',this.updateOrderForm.billing.billing_email);
        formData.append('billing[billing_contact_no]',this.updateOrderForm.billing.billing_contact_no);
        formData.append('billing[billing_city]',this.selectedBillingCity.id)
        formData.append('billing[billing_address]',this.billing_address.replace(/\s+/g,' ').trim());
        formData.append('billing[billing_postal_code]',this.updateOrderForm.billing.billing_postal_code.replace(/\s+/g,' ').trim());

      }

      if(this.tempOrderNotes.length > 0){

        formData.append('notes',JSON.stringify(this.tempOrderNotes));

      }

      if(this.coupon.length > 0){

        formData.append('coupon[id]',this.coupon[0].id);

        formData.append('coupon_code',this.updateOrderForm.coupon.coupon_code);
        formData.append('payment[coupon_discounted_amount]',this.updateOrderForm.payment.discounted_amount);

      }
      

      this.orderLoader = true;
      try{
        let res = await this.$axios.post('/dashboard/order/update',formData);
        if(res.data.status_code == "1024"){

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          let order = res.data.order;

          let jsonItems = [];

          order.items.forEach((item) =>{

            let obj = JSON.parse(item.product);

            if(item.actual_product && 
            item.actual_product.is_deleted){

              obj.product_deleted = true;

            }else{

              obj.product_deleted = false;

            }

            jsonItems.push(obj);

          });
          
          order.items = jsonItems;

          let orderIndex = this.orders.findIndex((listedOrder) => listedOrder.id == order.id);

          this.orders[orderIndex] = order;

          let updatedOrder = {
            i: orderIndex,
            updatedOrder: order,
          };

          this.$store.commit("orders_module/update_orders_list", updatedOrder);

          this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

          this.$store.dispatch('main_stats/fetchStatsRequest');

          this.$store.dispatch("dashboard_order_stats/fetchStatsRequest");

          this.$store.commit('products_module/CLEAR_MODULE');

          this.$store.commit('customers_module/CLEAR_MODULE');

          this.searchedCartItem = "";

          this.searchCartItems('products', "simple", 1);

          if(this.updateOrderForm.isPreview && (this.updateOrderForm.id == order.id)){

            this.orderUpdate = true;

            this.updateOrderOperations('close','without-confirmation');

          }

        }
      }catch(error){
        
        if(error.response){

          if(error.response.data.error.includes('not possible') ||
          error.response.data.error.includes("deleted or isn't available.") ||
          error.response.data.error.includes("no longer exist") ||
          error.response.data.error.includes("unavailable")){

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: "error",
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.orderLoader = false;

      }

    },
    async deleteOrder(order, index){

      if(order.isOpen){

        this.orders.splice(index, 1);

        return;

      }

      MessageBox.confirm(
        "Are you sure you want to delete the order?", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {

        let loader = Loading.service({
          text: "The order is being marked as 'Deleted'. Please wait!",
          fullscreen: true,
        });

        this.deletingItems.push(order.id);
        this.deleteLoader = true

        try{ 
          let res = await this.$axios.post("/deleteOrder", { id: order.id });
          if(res.data.status_code == "1025"){

            this.$notify({
              title: "Success",
              type: "success",
              message: res.data.message,
            });

            this.$store.commit("orders_module/delete_orders_list", index);

            this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

            this.$store.dispatch('main_stats/fetchStatsRequest');

            this.$store.commit('products_module/CLEAR_MODULE');

            this.$store.commit('customers_module/CLEAR_MODULE');

            this.deletingItems = [];
            this.deleteLoader = false;

            this.searchedCartItem = "";

            this.searchCartItems('products', "simple", 1);

            //removing deleted item from checked items list
            this.checkedItems = this.checkedItems.filter((item) => {

              if(item != order.id){
                  
                return item;
                  
              }

            });

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes('already') || 
            error.response.data.error.includes('not possible') ||
            error.response.data.error.includes("deleted or isn't available.")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: "error",
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          loader.close();

          this.deletingItems = [];
          this.deleteLoader = false;

        }

      }).catch(()=>{});

    },
    checkedItemHandle(order){

      if(!this.tourIsActive && !order.isOpen != -1 && !this.addOrderForm.isOpen && !this.updateOrderForm.isPreview){

        let index = this.checkedItems.findIndex(item => item == order.id);

        if(index != -1){

          this.checkedItems.splice(index,1);

        }else{

          this.checkedItems.push(order.id);
          
        }

      }

    },
    toggleIsCheckedAll(){

      if(!this.tourIsActive){

        if(this.checkedItems.length == this.orders.length){

          this.checkedItems = [];

        }else if(this.checkedItems.length < this.orders.length){

          this.checkedItems = [];

          this.orders.forEach((order) => {

            this.checkedItems.push(order.id);

          });

        }

      }

    },
    async applyAction(action){

      MessageBox.confirm(
        'Do you really want to make the changes?',
        'Confirm',
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }
      ).then(async ()=>{

        if(action == "statusChange"){

          let loader = Loading.service({
            text: "The selected orders are undergoing status update. Please wait!",
            fullscreen: true,
          });

          let isDeliveryDateMissing = false;

          for(let i = 0; i <= this.checkedItems.length; i++){

            if((this.orders.some(order => order.id == this.checkedItems[i] && order.delivery_date == null)) && this.selectedStatus != 'Cancelled'){

              isDeliveryDateMissing = true;
              break;

            }

          }

          if(isDeliveryDateMissing){

            this.$message({
              type: "error",
              showClose: true,
              message: 'The delivery date is missing for one or more of the selected orders.',
            });

            return

          }

          let formData = new FormData();

          formData.append("orders", JSON.stringify(this.checkedItems));
          formData.append("status", this.selectedStatus);
          formData.append("action", "statusChange");

          try{
            let res = await this.$axios.post("/bulkOrderAction", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: "success",
                title: "Success",
                message: "Orders status has been successfully changed.",
              });

              let payload = {
                action: 'statusChange',
                status: this.selectedStatus,
                listIds: this.checkedItems
              }

              this.$store.commit('orders_module/orders_bulk_action',payload)

              this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

              this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

              this.$store.dispatch('main_stats/fetchStatsRequest');

              this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'})

              this.checkedItems = [];
              this.selectedStatus = "";

              this.searchedCartItem = "";

              this.searchCartItems('products', "simple", 1);
              
            }
          }catch(error){

            if(error.response){

              if(error.response.data.error.includes('delivery date is missing') || 
              error.response.data.error.includes("deleted or isn't available.")){
                
                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                }); 

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.message,
              }); 

            }

          }finally{

            loader.close();

          }

        }else if(action == "bulkDelete"){

          let formData = new FormData();

          formData.append("orders", JSON.stringify(this.checkedItems));
          formData.append("status", this.selectedStatus);
          formData.append("action", "bulkDelete");  

          this.deletingItems = _.cloneDeep(this.checkedItems);
          this.deleteLoader = true;

          let loader = Loading.service({
            text: "The selected orders are being marked as 'Deleted'. Please wait!",
            fullscreen: true,
          });

          try{
            let res = await this.$axios.post("/bulkOrderAction", formData);
            if(res.data.status_code == "2031"){

              this.$notify({
                type: "success",
                title: "Success",
                message: "Orders have been successfully marked as deleted.",
              });

              let payload = {
                action: 'delete',
                listIds: this.checkedItems
              }

              this.$store.commit('orders_module/orders_bulk_action',payload);

              this.$store.dispatch("dashboard_stats/fetchStatsRequest", this.duration);

              this.$store.dispatch("products_module/fetchStoreProductsRequest",{ size: 15, page: 1, loadData: 'new'});

              this.$store.dispatch('main_stats/fetchStatsRequest');

              this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'})

              this.checkedItems = [];
              this.selectedStatus = "";
              this.deletingItems = [];
              this.deleteLoader = false;

              this.searchedCartItem = "";

              this.searchCartItems('products', "simple", 1);

            }
          }catch(error){

            this.selectedStatus = "";
            this.deletingItems = [];
            this.deleteLoader = false;

            if(error.response){

              if(error.response.data.error.includes("deleted or isn't available.")){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                }); 

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            loader.close();

          }

        }

      }).catch(()=>{});

    },
    isDeletingItem(id){

      let find = this.deletingItems.find(x=>x == id);

      if(find){

        return true;

      }else{

        return false;

      }

    },
    calculateProductTotal(item,form){

      if(item.type != 'bundle'){
        
        if(form == 'add-order'){

          return item.variant.price * item.variant.qty;

        }else if(form == 'update-order'){

          return item.variant.price * item.variant.qty;

        }

      }else{

        return item.variant.price;

      }


    },
    
    //add order related methods start here
    newOrderOperations(action,sub_action){

      if(action == 'open'){

        if(this.ordersLoading){
          
          this.$notify({
            type: "info",
            title: "Please Wait",
            message: "Orders are being retrieved.",
          });

          return

        }

        if(this.orderLoader){

          this.$notify({
            type: "info",
            title: "Please Wait",
            message: "An order is currently being processed.",
          });

          return

        }

        let index = this.orders.findIndex((order) => order.isOpen);

        if(index != -1){

          this.orders.splice(index,1); 

        }

        if(!this.orderUpdate){

          this.orders[this.tempOrder.index] = this.tempOrder;

        }

        this.updateOrderOperations('close','without-confirmation');

        let tempAddOrderForm = {
          order_date: "",
          order_no: "",
          delivery_date: "",
          status: "",
          order_notes: "",
          total_products: "",
          total_weight: "",
          shipping: {
            shipping_first_name: "",
            shipping_last_name: "",
            shipping_email: "",
            shipping_contact_no: "",
            shipping_postal_code: "",
          },
          billing:{
            billing_first_name: "",
            billing_last_name: "",
            billing_email: "",
            billing_contact_no: "",
            billing_postal_code: "",
          },
          customer: {
            id: "",
            first_name: "",
            last_name: "",
            email: "",
            city: "",
            address_line_1: "",
            address_line_2: "",
            mobile_no: "",
          },
          payment:{
            payment_method: {
              id: "",
              type: "",
            },
            amount: 0,
            net_amount: 0,
            shipping_amount: null,
            coupon_discounted_amount: 0,
            tax: 0,
            tax_amount: 0,
            status: "Pending",
            shipping_rule_id: '',
            shipping_rule_price_id:"",
            is_custom_shipping: false,
          },
          coupon:{
            coupon_code: "",
            coupon_data: {},
            discounted_amount: 0,
          },
          items: [],
          activities: [],
          notes: [],
          created_by: "Admin",
          isOpen: true,
          isTouched: false,
        }

        if(this.orders.length == 0){

          tempAddOrderForm.order_no = `YC${1}`;

        }else{

          let orderNoSubstr = this.orders[0].order_no.substr(2)

          tempAddOrderForm.order_no = `YC${parseInt(orderNoSubstr) + 1}`;

        }

        if(this.settings){

          tempAddOrderForm.payment.tax = this.settings.tax;

        }

        this.addOrderForm = _.cloneDeep(tempAddOrderForm);

        this.orders.unshift(this.addOrderForm);

        this.selectedCustomer = null;
        this.orderNote = "";

        this.addOrderSelectedShipping = ""
        this.addOrderShippingRules = null;

        this.addOrderIsCustomTax = false;

        this.customShippingCharges = null;
        this.coupon = [];

        //shipping
        this.selectedShippingCountry = null;
        this.selectedShippingCountryStates = [];
        this.shippingCountryStatesLoading = false;
        this.selectedShippingState = null;
        this.selectedShippingStateCitie = [];
        this.shippingStateCitiesLoading = false;
        this.selectedShippingCity = null;

        //billing
        this.isSameBilling = false;
        this.selectedBillingCountry = null;
        this.selectedBillingCountryStates = [];
        this.billingCountryStatesLoading = false;
        this.selectedBillingState = null;
        this.selectedBillingStateCities = [];
        this.billingStateCitiesLoading = false;
        this.selectedBillingCity = null;

        this.shippingContactFieldTouched = false;
        this.billingContactFieldTouched = false;

        this.shipping_address = "";
        this.billing_address = "";

        this.selectedOrderNote = "";
        this.orderNote = "";

        this.paymentMethodsList.forEach(method => {

          if(method.type == "Cash on Delivery"){

            this.addOrderForm.payment.payment_method = method;

          }

        });

        this.customerFieldRequired = false;

        this.isCreateNew = false;

        this.tempOrder = _.cloneDeep(this.addOrderForm);

        if(this.$refs.addOrderFormObserver){

          this.$refs.addOrderFormObserver.reset();

        }

      }else if(action == 'close'){

        if(sub_action == 'with-confirmation'){

          if(this.addOrderForm.isTouched){

            MessageBox.confirm(
              "Do you want to close? Entered information will be discarded.",
              "Warning",
              {
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            ).then(()=>{

              let index = this.orders.findIndex((order) => order.isOpen);

              if(index != -1){

                this.orders.splice(index,1); 

              }

              this.addOrderForm = {
                order_date: "",
                order_no: "",
                delivery_date: "",
                status: "",
                order_notes: "",
                total_products: "",
                total_weight: "",
                shipping: {
                  shipping_first_name: "",
                  shipping_last_name: "",
                  shipping_email: "",
                  shipping_contact_no: "",
                  shipping_postal_code: "",
                },
                billing:{
                  billing_first_name: "",
                  billing_last_name: "",
                  billing_email: "",
                  billing_contact_no: "",
                  billing_postal_code: "",
                },
                customer: {
                  id: "",
                  first_name: "",
                  last_name: "",
                  email: "",
                  city: "",
                  address_line_1: "",
                  address_line_2: "",
                  mobile_no: "",
                },
                payment:{
                  payment_method: {
                    id: "",
                    type: "",
                  },
                  amount: 0,
                  net_amount: 0,
                  coupon_discounted_amount: 0,
                  tax: 0,
                  status: "",
                  shipping_rule_id: '',
                  shipping_rule_price_id:"",
                },
                coupon:{
                  coupon_code: "",
                  coupon_data: {},
                  discounted_amount: 0,
                },
                items: [],
                activities: [],
                notes: [],
                isOpen: false,
                isTouched: false,
              }

              this.tempOrder = [];

            }).catch(() => {

            });

          }else{


          }

        }else if(sub_action == 'without-confirmation'){

          let index = this.orders.findIndex((order) => order.isOpen);

          if(index != -1){

            this.orders.splice(index,1); 

          }

          this.addOrderForm = {
            order_date: "",
            order_no: "",
            delivery_date: "",
            status: "",
            order_notes: "",
            total_products: "",
            total_weight: "",
            shipping: {
              shipping_first_name: "",
              shipping_last_name: "",
              shipping_email: "",
              shipping_contact_no: "",
              shipping_postal_code: "",
            },
            billing:{
              billing_first_name: "",
              billing_last_name: "",
              billing_email: "",
              billing_contact_no: "",
              billing_postal_code: "",
            },
            customer: {
              id: "",
              first_name: "",
              last_name: "",
              email: "",
              city: "",
              address_line_1: "",
              address_line_2: "",
              mobile_no: "",
            },
            payment:{
              payment_method: {
                id: "",
                type: "",
              },
              amount: 0,
              net_amount: 0,
              coupon_discounted_amount: 0,
              tax: 0,
              status: "",
              shipping_rule_id: '',
              shipping_rule_price_id:"",
            },
            coupon:{
              coupon_code: "",
              coupon_data: {},
              discounted_amount: 0,
            },
            items: [],
            activities: [],
            notes: [],
            isOpen: false,
            isTouched: false,
          }

          this.tempOrder = [];

        }

      }

    },
    //add order related methods end here

    //update order related methods start here
    updateOrderOperations(action,sub_action){

      if(action == 'close'){

        if(sub_action == 'with-confirmation'){

        }else if(sub_action == 'without-confirmation'){

          if(!this.orderUpdate){

            let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

            this.orders[tempIndex] = this.tempOrder;

          }

          this.updateOrderForm = {
            id:"",
            index:"",
            order_date: "",
            order_no: "",
            delivery_date: "",
            status: "",
            order_notes: "",
            total_products: "",
            total_weight: "",
            shipping: {
              shipping_first_name: "",
              shipping_last_name: "",
              shipping_email: "",
              shipping_contact_no: "",
              shipping_postal_code: "",
            },
            billing:{
              billing_first_name: "",
              billing_last_name: "",
              billing_email: "",
              billing_contact_no: "",
              billing_postal_code: "",
            },
            customer: {
              id: "",
              first_name: "",
              last_name: "",
              email: "",
              city: "",
              address_line_1: "",
              address_line_2: "",
              mobile_no: "",
            },
            payment:{
              payment_method: {
                id: "",
                type: "",
              },
              amount: 0,
              net_amount: 0,
              coupon_discounted_amount:0,
              shipping_amount:null,
              tax: 0,
              tax_amount: 0,
              status: "",
              shipping_rule_id: '',
              shipping_rule_price_id:"",
              is_custom_shipping: false,
              custom_shipping_amount:0,
            },
            coupon:{
              coupon_code: "",
              coupon_data:{},
              discounted_amount:0,
            },
            items: [],
            activities: [],
            notes: [],
            isPreview:false,
          }

          this.coupon = [];

          //shipping
          this.selectedShippingCountry = null;
          this.selectedShippingCountryStates = [];
          this.shippingCountryStatesLoading = false;
          this.selectedShippingState = null;
          this.selectedShippingStateCitie = [];
          this.shippingStateCitiesLoading = false;
          this.selectedShippingCity = null;

          //billing
          this.isSameBilling = false;
          this.selectedBillingCountry = null;
          this.selectedBillingCountryStates = [];
          this.billingCountryStatesLoading = false;
          this.selectedBillingState = null;
          this.selectedBillingStateCities = [];
          this.billingStateCitiesLoading = false;
          this.selectedBillingCity = null;

          this.updateOrderShippingRules = null;
          this.updateOrderSelectedShipping = "";

          this.isMasterSearched = false;

          this.orderUpdate = false;

          this.updateOrderIsCustomTax = false;

          this.tempOrder = [];

          this.list_customer = _.cloneDeep(this.tempCustomersList);

        }

      }

    },
    orderPreview(listedOrder, i, field, e){

      if(field == 'input'){
      
        if((e.target.tagName == 'TD' || e.target.dataset.open == 'true') && !this.tourIsActive){
          
          if(!listedOrder.isOpen){

            if(this.addOrderForm.isOpen){

              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                  type: "warning",
                }
              ).then(()=>{

                if(listedOrder.previewable == 0){

                  let subscriptionPath = window.location.origin + "/subscription";

                  Swal.fire({
                    title: "Max Orders/Month Quota Exhausted",
                    html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                    <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                    icon: "warning",
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Got it"
                  }).then((result) => {

                    if(result.isConfirmed){

                    }

                  });

                  return

                }

                let index = this.orders.findIndex((order) => order.isOpen);

                if(index != -1){

                  this.orders.splice(index,1); 

                  this.newOrderOperations('close','without-confirmation');

                }

                if(!this.orderUpdate){

                  let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                  this.orders[tempIndex] = this.tempOrder;

                }

                let tempPreviewOrder = {
                  id: listedOrder.id,
                  index: index != -1 ? i - 1 : i,
                  order_no: listedOrder.order_no,
                  order_date: listedOrder.order_date,
                  delivery_date: listedOrder.delivery_date,
                  status: listedOrder.status,
                  order_notes: listedOrder.order_notes,
                  total_products: 0,
                  total_weight: "",
                  created_by: listedOrder.created_by,
                  previewable: listedOrder.previewable,
                  shipping:{
                    shipping_first_name: listedOrder.order_details.shipping_first_name,
                    shipping_last_name: listedOrder.order_details.shipping_last_name,
                    shipping_email: listedOrder.order_details.shipping_email,
                    shipping_contact_no: listedOrder.order_details.shipping_contact_no,
                    shipping_postal_code: listedOrder.order_details.shipping_postal_code,
                  },
                  billing:{
                    billing_first_name: listedOrder.order_details.billing_first_name,
                    billing_last_name: listedOrder.order_details.billing_last_name,
                    billing_email: listedOrder.order_details.billing_email,
                    billing_contact_no: listedOrder.order_details.billing_contact_no,
                    billing_postal_code: listedOrder.order_details.billing_postal_code,
                  },
                  payment: listedOrder.payment,
                  customer: listedOrder.customer,
                  items: listedOrder.items,
                  activities: listedOrder.activities,
                  notes: listedOrder.notes,
                  coupon:{
                    coupon_code: "",
                    coupon_data:{},
                    discounted_amount:0,
                  },
                  coupon_usage: null,
                  isPreview:true,
                  isUpdate:false,
                }

                tempPreviewOrder.payment.tax_amount = 0;

                if(listedOrder.coupon_usage){

                  tempPreviewOrder.coupon.coupon_code = listedOrder.coupon_usage.coupon.coupon_code;
                  this.coupon[0] = listedOrder.coupon_usage.coupon;
                  tempPreviewOrder.payment.discounted_amount = listedOrder.coupon_usage.discounted_amount;
                  tempPreviewOrder.isUpdate = true;

                }else{

                  this.coupon = [];

                }

                // this.updateOrderForm = _.cloneDeep(tempPreviewOrder);
                this.updateOrderForm = tempPreviewOrder;

                if(listedOrder.order_details){

                  this.shipping_address = listedOrder.order_details.shipping_address;
              
                  this.selectedShippingCountry = listedOrder.order_details.shipping_city.country;
                  this.selectedShippingState = listedOrder.order_details.shipping_city.state

                  this.selectedShippingCity = listedOrder.order_details.shipping_city;

                  if(listedOrder.order_details.isSameBilling == 1){

                    this.isSameBilling = true

                    this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                    this.selectedBillingState = listedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = listedOrder.order_details.billing_city;

                    this.billing_address = listedOrder.order_details.billing_address;

                    this.loadRegions(this.selectedShippingCity.id);

                    this.loadCountryStates('shipping');

                    this.loadStateCities('shipping');

                  }else{

                    this.isSameBilling = false
                    this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                    this.selectedBillingState = listedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = listedOrder.order_details.billing_city;

                    this.billing_address = listedOrder.order_details.billing_address;


                    this.loadRegions(this.selectedShippingCity.id);

                    this.loadCountryStates('shipping');

                    this.loadStateCities('shipping');

                    this.loadCountryStates('billing');

                    this.loadStateCities('billing');

                  }

                }

                this.checkedItems = [];
                
                this.shippingContactFieldTouched = false;
                this.billingContactFieldTouched = false;

                this.customerFieldRequired = false;

                this.customShippingCharges = null;

                this.tempOrderNotes = [];
                this.selectedOrderNote = "";
                this.orderNote = "";

                this.orderUpdate = false;

                this.updateOrderIsCustomTax = false;

                this.orderLoader = false;

                this.tempOrder = _.cloneDeep(listedOrder);

              }).catch(() => {})

            }else{

              if(listedOrder.previewable == 0){

                let subscriptionPath = window.location.origin + "/subscription";

                Swal.fire({
                  title: "Max Orders/Month Quota Exhausted",
                  html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                  <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                  icon: "warning",
                  showCancelButton: false,
                  showCloseButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Got it"
                }).then((result) => {

                  if(result.isConfirmed){

                  }

                });

                return

              }

              let index = this.orders.findIndex((order) => order.isOpen);

              if(index != -1){

                this.orders.splice(index,1); 

                this.newOrderOperations('close','without-confirmation');

              }

              if(!this.orderUpdate){

                let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                this.orders[tempIndex] = this.tempOrder;

              }

              let tempPreviewOrder = {
                id: listedOrder.id,
                index: index != -1 ? i - 1 : i,
                order_no: listedOrder.order_no,
                order_date: listedOrder.order_date,
                delivery_date: listedOrder.delivery_date,
                status: listedOrder.status,
                order_notes: listedOrder.order_notes,
                total_products: 0,
                total_weight: "",
                created_by: listedOrder.created_by,
                previewable: listedOrder.previewable,
                shipping:{
                  shipping_first_name: listedOrder.order_details.shipping_first_name,
                  shipping_last_name: listedOrder.order_details.shipping_last_name,
                  shipping_email: listedOrder.order_details.shipping_email,
                  shipping_contact_no: listedOrder.order_details.shipping_contact_no,
                  shipping_postal_code: listedOrder.order_details.shipping_postal_code,
                },
                billing:{
                  billing_first_name: listedOrder.order_details.billing_first_name,
                  billing_last_name: listedOrder.order_details.billing_last_name,
                  billing_email: listedOrder.order_details.billing_email,
                  billing_contact_no: listedOrder.order_details.billing_contact_no,
                  billing_postal_code: listedOrder.order_details.billing_postal_code,
                },
                payment: listedOrder.payment,
                customer: listedOrder.customer,
                items: listedOrder.items,
                activities: listedOrder.activities,
                notes: listedOrder.notes,
                coupon:{
                  coupon_code: "",
                  coupon_data:{},
                  discounted_amount:0,
                },
                coupon_usage: null,
                isPreview:true,
                isUpdate:false,
              }

              tempPreviewOrder.payment.tax_amount = 0;

              if(listedOrder.coupon_usage){

                tempPreviewOrder.coupon.coupon_code = listedOrder.coupon_usage.coupon.coupon_code;
                this.coupon[0] = listedOrder.coupon_usage.coupon;
                tempPreviewOrder.payment.discounted_amount = listedOrder.coupon_usage.discounted_amount;
                tempPreviewOrder.isUpdate = true;

              }else{

                this.coupon = [];

              }

              // this.updateOrderForm = _.cloneDeep(tempPreviewOrder);
              this.updateOrderForm = tempPreviewOrder;

              if(listedOrder.order_details){

                this.shipping_address = listedOrder.order_details.shipping_address;

                this.selectedShippingCountry = listedOrder.order_details.shipping_city.country;
                this.selectedShippingState = listedOrder.order_details.shipping_city.state

                this.selectedShippingCity = listedOrder.order_details.shipping_city;

                if(listedOrder.order_details.isSameBilling == 1){

                  this.isSameBilling = true

                  this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                  this.selectedBillingState = listedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = listedOrder.order_details.billing_city;

                  this.billing_address = listedOrder.order_details.billing_address;

                  this.loadRegions(this.selectedShippingCity.id);

                  this.loadCountryStates('shipping');

                  this.loadStateCities('shipping');

                }else{

                  this.isSameBilling = false
                  this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                  this.selectedBillingState = listedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = listedOrder.order_details.billing_city;

                  this.billing_address = listedOrder.order_details.billing_address;


                  this.loadRegions(this.selectedShippingCity.id);

                  this.loadCountryStates('shipping');

                  this.loadStateCities('shipping');

                  this.loadCountryStates('billing');

                  this.loadStateCities('billing');

                }

              }

              this.checkedItems = [];

              this.shippingContactFieldTouched = false;
              this.billingContactFieldTouched = false;

              this.customerFieldRequired = false;

              this.customShippingCharges = null;

              this.tempOrderNotes = [];
              this.selectedOrderNote = "";
              this.orderNote = "";

              this.orderUpdate = false;

              this.updateOrderIsCustomTax = false;

              this.orderLoader = false;

              this.tempOrder = _.cloneDeep(listedOrder);

            }

          }
        
        }
      
      }else{

        if(!this.tourIsActive){
          
          if(!listedOrder.isOpen){

            if(this.addOrderForm.isOpen){

              MessageBox.confirm(
                "Are you sure? unsaved changes will be discarded", 
                "Warning", 
                {
                  confirmButtonText: "Yes",
                  cancelButtonText: "Cancel",
                  type: "warning",
                }
              ).then(()=>{

                if(listedOrder.previewable == 0){

                  let subscriptionPath = window.location.origin + "/subscription";

                  Swal.fire({
                    title: "Max Orders/Month Quota Exhausted",
                    html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                    <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                    icon: "warning",
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Got it"
                  }).then((result) => {

                    if(result.isConfirmed){

                    }

                  });

                  return

                }

                let index = this.orders.findIndex((order) => order.isOpen);

                if(index != -1){

                  this.orders.splice(index,1); 

                  this.newOrderOperations('close','without-confirmation');

                }

                if(!this.orderUpdate){

                  let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                  this.orders[tempIndex] = this.tempOrder;

                }

                let tempPreviewOrder = {
                  id: listedOrder.id,
                  index: index != -1 ? i - 1 : i,
                  order_no: listedOrder.order_no,
                  order_date: listedOrder.order_date,
                  delivery_date: listedOrder.delivery_date,
                  status: listedOrder.status,
                  order_notes: listedOrder.order_notes,
                  total_products: 0,
                  total_weight: "",
                  created_by: listedOrder.created_by,
                  previewable: listedOrder.previewable,
                  shipping:{
                    shipping_first_name: listedOrder.order_details.shipping_first_name,
                    shipping_last_name: listedOrder.order_details.shipping_last_name,
                    shipping_email: listedOrder.order_details.shipping_email,
                    shipping_contact_no: listedOrder.order_details.shipping_contact_no,
                    shipping_postal_code: listedOrder.order_details.shipping_postal_code,
                  },
                  billing:{
                    billing_first_name: listedOrder.order_details.billing_first_name,
                    billing_last_name: listedOrder.order_details.billing_last_name,
                    billing_email: listedOrder.order_details.billing_email,
                    billing_contact_no: listedOrder.order_details.billing_contact_no,
                    billing_postal_code: listedOrder.order_details.billing_postal_code,
                  },
                  payment: listedOrder.payment,
                  customer: listedOrder.customer,
                  items: listedOrder.items,
                  activities: listedOrder.activities,
                  notes: listedOrder.notes,
                  coupon:{
                    coupon_code: "",
                    coupon_data:{},
                    discounted_amount:0,
                  },
                  coupon_usage: null,
                  isPreview:true,
                  isUpdate:false,
                }

                tempPreviewOrder.payment.tax_amount = 0;

                if(listedOrder.coupon_usage){

                  tempPreviewOrder.coupon.coupon_code = listedOrder.coupon_usage.coupon.coupon_code;
                  this.coupon[0] = listedOrder.coupon_usage.coupon;
                  tempPreviewOrder.payment.discounted_amount = listedOrder.coupon_usage.discounted_amount;
                  tempPreviewOrder.isUpdate = true;

                }else{

                  this.coupon = [];

                }

                // this.updateOrderForm = _.cloneDeep(tempPreviewOrder);
                this.updateOrderForm = tempPreviewOrder;

                if(listedOrder.order_details){

                  this.shipping_address = listedOrder.order_details.shipping_address;

                  this.selectedShippingCountry = listedOrder.order_details.shipping_city.country;
                  this.selectedShippingState = listedOrder.order_details.shipping_city.state

                  this.selectedShippingCity = listedOrder.order_details.shipping_city;

                  if(listedOrder.order_details.isSameBilling == 1){

                    this.isSameBilling = true

                    this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                    this.selectedBillingState = listedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = listedOrder.order_details.billing_city;

                    this.billing_address = listedOrder.order_details.billing_address;

                    this.loadRegions(this.selectedShippingCity.id);

                    this.loadCountryStates('shipping');

                    this.loadStateCities('shipping');

                  }else{

                    this.isSameBilling = false
                    this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                    this.selectedBillingState = listedOrder.order_details.billing_city.state;

                    this.selectedBillingCity = listedOrder.order_details.billing_city;

                    this.billing_address = listedOrder.order_details.billing_address;


                    this.loadRegions(this.selectedShippingCity.id);

                    this.loadCountryStates('shipping');

                    this.loadStateCities('shipping');

                    this.loadCountryStates('billing');

                    this.loadStateCities('billing');

                  }

                }

                this.checkedItems = [];
                
                this.shippingContactFieldTouched = false;
                this.billingContactFieldTouched = false;

                this.customerFieldRequired = false;

                this.customShippingCharges = null;

                this.tempOrderNotes = [];
                this.selectedOrderNote = "";
                this.orderNote = "";

                this.orderUpdate = false;

                this.updateOrderIsCustomTax = false;

                this.orderLoader = false;

                this.tempOrder = _.cloneDeep(listedOrder);

              }).catch(() => {})

            }else{

              if(listedOrder.previewable == 0){

                let subscriptionPath = window.location.origin + "/subscription";

                Swal.fire({
                  title: "Max Orders/Month Quota Exhausted",
                  html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
                  <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
                  icon: "warning",
                  showCancelButton: false,
                  showCloseButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Got it"
                }).then((result) => {

                  if(result.isConfirmed){

                  }

                });

                return

              }

              let index = this.orders.findIndex((order) => order.isOpen);

              if(index != -1){

                this.orders.splice(index,1); 

                this.newOrderOperations('close','without-confirmation');

              }

              if(!this.orderUpdate){

                let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

                this.orders[tempIndex] = this.tempOrder;

              }

              let tempPreviewOrder = {
                id: listedOrder.id,
                index: index != -1 ? i - 1 : i,
                order_no: listedOrder.order_no,
                order_date: listedOrder.order_date,
                delivery_date: listedOrder.delivery_date,
                status: listedOrder.status,
                order_notes: listedOrder.order_notes,
                total_products: 0,
                total_weight: "",
                created_by: listedOrder.created_by,
                previewable: listedOrder.previewable,
                shipping:{
                  shipping_first_name: listedOrder.order_details.shipping_first_name,
                  shipping_last_name: listedOrder.order_details.shipping_last_name,
                  shipping_email: listedOrder.order_details.shipping_email,
                  shipping_contact_no: listedOrder.order_details.shipping_contact_no,
                  shipping_postal_code: listedOrder.order_details.shipping_postal_code,
                },
                billing:{
                  billing_first_name: listedOrder.order_details.billing_first_name,
                  billing_last_name: listedOrder.order_details.billing_last_name,
                  billing_email: listedOrder.order_details.billing_email,
                  billing_contact_no: listedOrder.order_details.billing_contact_no,
                  billing_postal_code: listedOrder.order_details.billing_postal_code,
                },
                payment: listedOrder.payment,
                customer: listedOrder.customer,
                items: listedOrder.items,
                activities: listedOrder.activities,
                notes: listedOrder.notes,
                coupon:{
                  coupon_code: "",
                  coupon_data:{},
                  discounted_amount:0,
                },
                coupon_usage: null,
                isPreview:true,
                isUpdate:false,
              }

              tempPreviewOrder.payment.tax_amount = 0;

              if(listedOrder.coupon_usage){

                tempPreviewOrder.coupon.coupon_code = listedOrder.coupon_usage.coupon.coupon_code;
                this.coupon[0] = listedOrder.coupon_usage.coupon;
                tempPreviewOrder.payment.discounted_amount = listedOrder.coupon_usage.discounted_amount;
                tempPreviewOrder.isUpdate = true;

              }else{

                this.coupon = [];

              }

              // this.updateOrderForm = _.cloneDeep(tempPreviewOrder);
              this.updateOrderForm = tempPreviewOrder;

              if(listedOrder.order_details){

                this.shipping_address = listedOrder.order_details.shipping_address;

                this.selectedShippingCountry = listedOrder.order_details.shipping_city.country;
                this.selectedShippingState = listedOrder.order_details.shipping_city.state

                this.selectedShippingCity = listedOrder.order_details.shipping_city;

                if(listedOrder.order_details.isSameBilling == 1){

                  this.isSameBilling = true

                  this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                  this.selectedBillingState = listedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = listedOrder.order_details.billing_city;

                  this.billing_address = listedOrder.order_details.billing_address;

                  this.loadRegions(this.selectedShippingCity.id);

                  this.loadCountryStates('shipping');

                  this.loadStateCities('shipping');

                }else{

                  this.isSameBilling = false
                  this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
                  this.selectedBillingState = listedOrder.order_details.billing_city.state;

                  this.selectedBillingCity = listedOrder.order_details.billing_city;

                  this.billing_address = listedOrder.order_details.billing_address;


                  this.loadRegions(this.selectedShippingCity.id);

                  this.loadCountryStates('shipping');

                  this.loadStateCities('shipping');

                  this.loadCountryStates('billing');

                  this.loadStateCities('billing');

                }

              }

              this.checkedItems = [];

              this.shippingContactFieldTouched = false;
              this.billingContactFieldTouched = false;

              this.customerFieldRequired = false;

              this.customShippingCharges = null;

              this.tempOrderNotes = [];
              this.selectedOrderNote = "";
              this.orderNote = "";

              this.orderUpdate = false;

              this.updateOrderIsCustomTax = false;

              this.orderLoader = false;

              this.tempOrder = _.cloneDeep(listedOrder);

            }

          }
        
        }

      }

    },
    searchedOrderPreview(listedOrder, i){

      if(listedOrder.previewable == 0){

        let subscriptionPath = window.location.origin + "/subscription";

        Swal.fire({
          title: "Max Orders/Month Quota Exhausted",
          html: `The order cannot be previewed as the maximum number of orders allowed per month has been reached. 
          <a href='${subscriptionPath}'' target='_blank'>Click here</a> to Upgrade your plan.`,
          icon: "warning",
          showCancelButton: false,
          showCloseButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Got it"
        }).then((result) => {

          if(result.isConfirmed){

          }

        });

        return

      }

      let index = this.orders.findIndex((order) => order.isOpen);

      if(index != -1){

        this.orders.splice(index,1); 

        this.newOrderOperations('close','without-confirmation');

      }

      if(!this.orderUpdate){

        let tempIndex = this.orders.findIndex(x=>x.id == this.tempOrder.id);

        this.orders[tempIndex] = this.tempOrder;

      }

      let tempPreviewOrder = {
        id: listedOrder.id,
        index: index != -1 ? i - 1 : i,
        order_no: listedOrder.order_no,
        order_date: listedOrder.order_date,
        delivery_date: listedOrder.delivery_date,
        status: listedOrder.status,
        order_notes: listedOrder.order_notes,
        total_products: 0,
        total_weight: "",
        created_by: listedOrder.created_by,
        previewable: listedOrder.previewable,
        shipping:{
          shipping_first_name: listedOrder.order_details.shipping_first_name,
          shipping_last_name: listedOrder.order_details.shipping_last_name,
          shipping_email: listedOrder.order_details.shipping_email,
          shipping_contact_no: listedOrder.order_details.shipping_contact_no,
          shipping_postal_code: listedOrder.order_details.shipping_postal_code,
        },
        billing:{
          billing_first_name: listedOrder.order_details.billing_first_name,
          billing_last_name: listedOrder.order_details.billing_last_name,
          billing_email: listedOrder.order_details.billing_email,
          billing_contact_no: listedOrder.order_details.billing_contact_no,
          billing_postal_code: listedOrder.order_details.billing_postal_code,
        },
        payment: listedOrder.payment,
        customer: listedOrder.customer,
        items: listedOrder.items,
        activities: listedOrder.activities,
        notes: listedOrder.notes,
        coupon:{
          coupon_code: "",
          coupon_data:{},
          discounted_amount:0,
        },
        coupon_usage: null,
        isPreview:true,
        isUpdate:false,
      }

      tempPreviewOrder.payment.tax_amount = 0;

      if(listedOrder.coupon_usage){

        tempPreviewOrder.coupon.coupon_code = listedOrder.coupon_usage.coupon.coupon_code;
        this.coupon[0] = listedOrder.coupon_usage.coupon;
        tempPreviewOrder.payment.discounted_amount = listedOrder.coupon_usage.discounted_amount;
        tempPreviewOrder.isUpdate = true;

      }else{

        this.coupon = [];

      }

      this.updateOrderForm = _.cloneDeep(tempPreviewOrder);

      if(listedOrder.order_details){

        this.shipping_address = listedOrder.order_details.shipping_address;

        this.selectedShippingCountry = listedOrder.order_details.shipping_city.country;
        this.selectedShippingState = listedOrder.order_details.shipping_city.state

        this.selectedShippingCity = listedOrder.order_details.shipping_city;

        if(listedOrder.order_details.isSameBilling == 1){

          this.isSameBilling = true

          this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
          this.selectedBillingState = listedOrder.order_details.billing_city.state;

          this.selectedBillingCity = listedOrder.order_details.billing_city;

          this.billing_address = listedOrder.order_details.billing_address;

          this.loadRegions(this.selectedShippingCity.id);

          this.loadCountryStates('shipping');

          this.loadStateCities('shipping');

        }else{

          this.isSameBilling = false
          this.selectedBillingCountry = listedOrder.order_details.billing_city.country;
          this.selectedBillingState = listedOrder.order_details.billing_city.state;

          this.selectedBillingCity = listedOrder.order_details.billing_city;

          this.billing_address = listedOrder.order_details.billing_address;


          this.loadRegions(this.selectedShippingCity.id);

          this.loadCountryStates('shipping');

          this.loadStateCities('shipping');

          this.loadCountryStates('billing');

          this.loadStateCities('billing');

        }

      }

      this.checkedItems = [];

      this.shippingContactFieldTouched = false;
      this.billingContactFieldTouched = false;

      this.customerFieldRequired = false;

      this.customShippingCharges = null;

      this.tempOrderNotes = [];
      this.selectedOrderNote = "";
      this.orderNote = "";

      this.orderUpdate = false;

      this.updateOrderIsCustomTax = false;

      this.tempOrder = _.cloneDeep(listedOrder);

    },
    //update order related methods end here

    //product block related methods start here
    productQuantityChangeHandle(form,action,index){

      if(form == 'add-order'){

        if(action == 'incr'){

          if(this.addOrderForm.items[index].variant.qty >= this.addOrderForm.items[index].variant.stock){

            this.addOrderForm.items[index].variant.qty = this.addOrderForm.items[index].variant.stock;

          }else{

            this.addOrderForm.items[index].variant.qty++

          }

          if(this.addOrderSelectedShipping != ""){

            this.$notify({
              type: "info",
              title: "Shipping Option",
              message: "Selected shipping option has been removed.",
            });

            this.addOrderForm.payment.shipping_rule_price_id = ""
            this.addOrderForm.payment.shipping_rule_id = ''
            this.addOrderSelectedShipping = ""

          }

          if(this.customShippingCharges){

            this.$notify({
              type: "info",
              title: "Custom Shipping Charges",
              message: "Custom shipping charges have been removed.",
            });

            this.addOrderForm.payment.is_custom_shipping = false;

            this.customShippingCharges = null;

          }

          this.addOrderForm.payment.shipping_amount = 0

        }else if(action == 'decr'){

          if(this.addOrderForm.items[index].variant.qty > 1){

            this.addOrderForm.items[index].variant.qty--

          }else{

            this.addOrderForm.items[index].variant.qty = this.addOrderForm.items[index].variant.qty;

          }

          if(this.addOrderSelectedShipping != ""){

            this.$notify({
              type: "info",
              title: "Shipping Option",
              message: "Selected shipping option has been removed.",
            });

            this.addOrderForm.payment.shipping_rule_price_id = ""
            this.addOrderForm.payment.shipping_rule_id = ''
            this.addOrderSelectedShipping = ""

          }

          if(this.customShippingCharges){

            this.$notify({
              type: "info",
              title: "Custom Shipping Charges",
              message: "Custom shipping charges have been removed.",
            });

            this.addOrderForm.payment.is_custom_shipping = false;

            this.customShippingCharges = null;

          }

          this.addOrderForm.payment.shipping_amount = 0

        }

      }else if(form == 'update-order'){

        if(action == 'incr'){

          if(this.updateOrderForm.items[index].product_deleted){

            this.$message({
              type: "info",
              showClose: true,
              message: "The product you're interacting with has been deleted.",
            });

          }else if(this.updateOrderForm.items[index].variant.variant_deleted){

            this.$message({
              type: "info",
              showClose: true,
              message: "This product variant may no longer exist or has been modified. Add it as a new item to the order to update the quantity.",
            });

          }else if(this.updateOrderForm.items[index].variant.is_sale_price){

            if(this.updateOrderForm.items[index].variant.hasOwnProperty('current_sale_price')){

              if(this.updateOrderForm.items[index].variant.current_sale_price != this.updateOrderForm.items[index].variant.price){
              
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
                });

              }else{

                if(this.updateOrderForm.items[index].variant.qty >= this.updateOrderForm.items[index].variant.stock){

                  this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

                }else{

                  this.updateOrderForm.items[index].variant.qty++

                }

              }

            }else{

              if(this.updateOrderForm.items[index].variant.qty >= this.updateOrderForm.items[index].variant.stock){

                this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

              }else{

                this.updateOrderForm.items[index].variant.qty++

              }

            }

          }else if(!this.updateOrderForm.items[index].variant.is_sale_price){

            if(this.updateOrderForm.items[index].variant.hasOwnProperty('current_price')){

              if(this.updateOrderForm.items[index].variant.current_price != this.updateOrderForm.items[index].variant.price){
              
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
                });
                
              }else{

                if(this.updateOrderForm.items[index].variant.qty >= this.updateOrderForm.items[index].variant.stock){

                  this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

                }else{

                  this.updateOrderForm.items[index].variant.qty++

                }

              }

            }else{

              if(this.updateOrderForm.items[index].variant.qty >= this.updateOrderForm.items[index].variant.stock){

                this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

              }else{

                this.updateOrderForm.items[index].variant.qty++

              }

            }

          }else{

            if(this.updateOrderForm.items[index].variant.qty >= this.updateOrderForm.items[index].variant.stock){

              this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

            }else{

              this.updateOrderForm.items[index].variant.qty++

            }

          }

        }else if(action == 'decr'){

          if(this.updateOrderForm.items[index].product_deleted){

            this.$message({
              type: "info",
              showClose: true,
              message: "The product you're interacting with has been deleted.",
            });

          }else if(this.updateOrderForm.items[index].variant.variant_deleted){

            this.$message({
              type: "info",
              showClose: true,
              message: "This product variant may no longer exist or has been modified. Add it as a new item to the order to update the quantity.",
            });

          }else if(this.updateOrderForm.items[index].variant.is_sale_price){

            if(this.updateOrderForm.items[index].variant.hasOwnProperty('current_sale_price')){

              if(this.updateOrderForm.items[index].variant.current_sale_price != this.updateOrderForm.items[index].variant.price){
              
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
                });

              }else{

                if(this.updateOrderForm.items[index].variant.qty > 1){

                  this.updateOrderForm.items[index].variant.qty--

                }else{

                  this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.qty;

                }

              }

            }else{

              if(this.updateOrderForm.items[index].variant.qty > 1){

                this.updateOrderForm.items[index].variant.qty--

              }else{

                this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.qty;

              }
              
            }

          }else if(!this.updateOrderForm.items[index].variant.is_sale_price){

            if(this.updateOrderForm.items[index].variant.hasOwnProperty('current_price')){

              if(this.updateOrderForm.items[index].variant.current_price != this.updateOrderForm.items[index].variant.price){
              
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
                });

              }else{

                if(this.updateOrderForm.items[index].variant.qty > 1){

                  this.updateOrderForm.items[index].variant.qty--

                }else{

                  this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.qty;

                }

              }

            }else{

              if(this.updateOrderForm.items[index].variant.qty > 1){

                this.updateOrderForm.items[index].variant.qty--

              }else{

                this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.qty;

              }

            }

          }else{

            if(this.updateOrderForm.items[index].variant.qty > 1){

              this.updateOrderForm.items[index].variant.qty--

            }else{

              this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.qty;

            }

          }

        }

      }

    },
    productQuantityChangeCheck(form,index){

      if(form == 'add-order'){

        if(this.addOrderForm.items[index].variant.stock < parseInt(this.addOrderForm.items[index].variant.qty)){
        
          this.$notify({
            type: "info",
            title: "Stock Limit Reached",
            message: "Cannot add more quantity than available stock.",
          });

          if(this.tempOrder.items.length > 0){

            this.addOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

          }else{

            this.addOrderForm.items[index].variant.qty = this.addOrderForm.items[index].variant.stock;

          }

          return

        }else if(parseInt(this.addOrderForm.items[index].variant.qty) <= 0){

          this.$notify({
            type: "info",
            title: "Invalid Quantity",
            message: "The quantity entered is not acceptable.",
          });

          if(this.tempOrder.items.length > 0){

            this.addOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

          }else{

            this.addOrderForm.items[index].variant.qty = this.addOrderForm.items[index].variant.stock;

          }

          return

        }else if(!Number.isInteger(Number(this.addOrderForm.items[index].variant.qty))){
          
          this.$notify({
            type: "info",
            title: "Invalid Quantity",
            message: "Decimal values are not allowed for quantity.",
          });

          this.addOrderForm.items[index].variant.qty = parseInt(this.addOrderForm.items[index].variant.qty);

        }else{

          this.addOrderForm.items[index].variant.qty = parseInt(this.addOrderForm.items[index].variant.qty);

        }

      }else if(form == 'update-order'){

        if(this.updateOrderForm.items[index].product_deleted){

          this.$message({
            type: "info",
            showClose: true,
            message: "The product you're interacting with has been deleted.",
          });

          this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

        }else if(this.updateOrderForm.items[index].variant.variant_deleted){

          this.$message({
            type: "info",
            showClose: true,
            message: "This product variant may no longer exist or has been modified. Add it as a new item to the order to update the quantity.",
          });

          this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

        }else if(this.updateOrderForm.items[index].variant.is_sale_price){

          if(this.updateOrderForm.items[index].variant.current_sale_price){

            if(this.updateOrderForm.items[index].variant.current_sale_price != this.updateOrderForm.items[index].variant.price){

              this.$message({
                type: "info",
                showClose: true,
                message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
              });

              this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

            }else{

              if(this.updateOrderForm.items[index].variant.stock < parseInt(this.updateOrderForm.items[index].variant.qty)){
          
                this.$notify({
                  type: "info",
                  title: "Stock Limit Reached",
                  message: "Cannot add more quantity than available stock.",
                });

                if(this.tempOrder.items.length > 0){

                  this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

                }else{

                  this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

                }

                return

              }else if(parseInt(this.updateOrderForm.items[index].variant.qty) <= 0){

                this.$notify({
                  type: "info",
                  title: "Invalid Quantity",
                  message: "The quantity entered is not acceptable.",
                });

                if(this.tempOrder.items.length > 0){

                  this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

                }else{

                  this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

                }

                return

              }else if(!Number.isInteger(Number(this.updateOrderForm.items[index].variant.qty))){
                
                this.$notify({
                  type: "info",
                  title: "Invalid Quantity",
                  message: "Decimal values are not allowed for quantity.",
                });

                this.updateOrderForm.items[index].variant.qty = parseInt(this.updateOrderForm.items[index].variant.qty);

              }else{

                this.updateOrderForm.items[index].variant.qty = parseInt(this.updateOrderForm.items[index].variant.qty);

              }

            }

          }else{

            this.$message({
              type: "info",
              showClose: true,
              message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
            });

            this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

          }
          
        }else if(!this.updateOrderForm.items[index].variant.is_sale_price){

          if(this.updateOrderForm.items[index].variant.current_price != this.updateOrderForm.items[index].variant.price){

            this.$message({
              type: "info",
              showClose: true,
              message: "The product variant price has been changed. Kindly add it as a new order item to change the quantity.",
            });

            this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

          }else{

            if(this.updateOrderForm.items[index].variant.stock < parseInt(this.updateOrderForm.items[index].variant.qty)){
          
              this.$notify({
                type: "info",
                title: "Stock Limit Reached",
                message: "Cannot add more quantity than available stock.",
              });

              if(this.tempOrder.items.length > 0){

                this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

              }else{

                this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

              }

              return

            }else if(parseInt(this.updateOrderForm.items[index].variant.qty) <= 0){

              this.$notify({
                type: "info",
                title: "Invalid Quantity",
                message: "The quantity entered is not acceptable.",
              });

              if(this.tempOrder.items.length > 0){

                this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

              }else{

                this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

              }

              return

            }else if(!Number.isInteger(Number(this.updateOrderForm.items[index].variant.qty))){
              
              this.$notify({
                type: "info",
                title: "Invalid Quantity",
                message: "Decimal values are not allowed for quantity.",
              });

              this.updateOrderForm.items[index].variant.qty = parseInt(this.updateOrderForm.items[index].variant.qty);

            }else{

              this.updateOrderForm.items[index].variant.qty = parseInt(this.updateOrderForm.items[index].variant.qty);

            }

          }

        }else{

          if(this.updateOrderForm.items[index].variant.stock < parseInt(this.updateOrderForm.items[index].variant.qty)){
        
            this.$notify({
              type: "info",
              title: "Stock Limit Reached",
              message: "Cannot add more quantity than available stock.",
            });

            if(this.tempOrder.items.length > 0){

              this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

            }else{

              this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

            }

            return

          }else if(parseInt(this.updateOrderForm.items[index].variant.qty) <= 0){

            this.$notify({
              type: "info",
              title: "Invalid Quantity",
              message: "The quantity entered is not acceptable.",
            });

            if(this.tempOrder.items.length > 0){

              this.updateOrderForm.items[index].variant.qty = parseInt(this.tempOrder.items[index].variant.qty);

            }else{

              this.updateOrderForm.items[index].variant.qty = this.updateOrderForm.items[index].variant.stock;

            }

            return

          }else if(!Number.isInteger(Number(this.updateOrderForm.items[index].variant.qty))){
            
            this.$notify({
              type: "info",
              title: "Invalid Quantity",
              message: "Decimal values are not allowed for quantity.",
            });

            this.updateOrderForm.items[index].variant.qty = parseInt(this.updateOrderForm.items[index].variant.qty);

          }else{

            this.updateOrderForm.items[index].variant.qty = parseInt(this.updateOrderForm.items[index].variant.qty);

          }

        }

      }

    },
    addVariant(form,variantItem){

      if(form == 'add-order'){

        let isFoundInProducts = false;

        let isFoundInBundles = false;

        this.addOrderForm.items.forEach((item) => {

          if(item.type == 'bundle'){

            item.bundleItems.forEach((bundledItem) => {

              if(bundledItem.item_details.variant.id == variantItem.variant.id){

                isFoundInBundles = true;

              }

            });

          }else if(item.type == 'simple' || item.type == 'variable'){

            if(item.variant.id == variantItem.variant.id){

              isFoundInProducts = true;

            }

          }

        });

        if(isFoundInProducts){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Product has already been added in the cart.",
          });

        }else if(isFoundInBundles){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "The selected product is already present in a bundled cart item.",
          });

        }else{

          this.addOrderForm.items.push(variantItem);

        }

      }else if(form == 'update-order'){

        let isFoundInProducts = false;

        let isFoundInBundles = false;

        this.updateOrderForm.items.forEach((item) => {

          if(item.type == 'bundle'){

            item.bundleItems.forEach((bundledItem) => {

              if(bundledItem.item_details.variant.id == variantItem.variant.id){

                isFoundInBundles = true;

              }

            });

          }else if(item.type == 'simple' || item.type == 'variable'){

            if(item.variant.id == variantItem.variant.id){

              isFoundInProducts = true;

            }

          }

        });

        if(isFoundInProducts){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Product has already been added in the cart.",
          });

        }else if(isFoundInBundles){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "The selected product is already present in a bundled cart item.",
          });

        }else{

          this.updateOrderForm.items.push(variantItem);

        }

      }

    },
    removeOrderItem(item, form ,index){

      if(form == "add-order"){

        MessageBox.confirm(
          "Do you really want to remove the item?", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(()=>{

          this.addOrderForm.items.splice(index, 1);

        }).catch(()=>{})

      }else if(form == 'update-order'){

        MessageBox.confirm(
          "Do you really want to remove the item?", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(()=>{

          this.updateOrderForm.items.splice(index, 1);

        }).catch(()=>{})


      }else if(form == "product-modal"){

        if(this.cartItemsModal.form == 'add-order'){

          this.addOrderForm.items.splice(index, 1);

        }else if(this.cartItemsModal.form == 'update-order'){

          this.updateOrderForm.items.splice(index, 1);

        }


      }

    },
    productSelectionMessage(form){

      if(form == 'add-order'){

        if(this.addOrderForm.items.length == 0 && !this.$parent.tourIsActive){

          this.$notify({
            type: "info",
            title: "Product Not Selected",
            message: "At least one product must be selected first.",
          });

        }

      }else if(form == 'update-order'){

        if(this.updateOrderForm.items.length == 0){

          this.$notify({
            type: "info",
            title: "Product Not Selected",
            message: "At least one product must be selected first.",
          });

        }

      }

    },
    //product block related methods end here

    //cart items block related methods start here
    cartItemPaginationChangeHandle(currentPage, pageSize){

      this.searchCartItems(this.cartItemsModal.itemsType, 'simple', currentPage);

    },
    openCartItemsModal(form){

      this.cartItemsModal = {
        form: form,
        itemsType: 'products',
        isOpen: true,
      }

    },
    loadAvailableCartItems(){

      this.cartItemsModal.isOpen = false;

      if(this.cartItemsModal.itemsType != 'products' || this.searchedCartItem.length > 0 || this.cartItemsPagination.current_page != 1){
      
        this.cartItemsPagination = {
          current_page: 0,
          total: 0,
          previous_page: 0,
          last_page: 0,
        }
      
        this.searchedCartItem = ""

        this.cartItemsModal.itemsType = 'products';

        this.searchCartItems('products','simple',1);

      }

    },
    async searchCartItems(itemsType, operation, page){

      if(itemsType == 'products'){

        if(operation == 'simple'){

          this.cartItemsLoading = true;

          try{
            let productsRes = await this.$axios.get("/getAllProducts",{
              params:{
                search: this.searchedCartItem ? this.searchedCartItem : null,
                offset: 10,
                page: page ? page : null,
              }
            });
            if(productsRes.data.status_code == "1007"){

              this.cartItemsList = productsRes.data.products.data;

              this.cartItemsPagination = {
                current_page: page,
                total: productsRes.data.products.total,
                previous_page:0,
                last_page: productsRes.data.products.last_page
              }

            }
          }catch(error){

            this.cartItemsList = [];

            this.cartItemsPagination = {
              current_page: 0,
              total: 0,
              previous_page: 0,
              last_page: 0,
            }

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            this.cartItemsLoading = false;

          }

        }else if(operation == 'search'){

          this.cartItemsLoading = true;

          clearTimeout(this.timer);

          this.timer = setTimeout( async ()=>{
            try{
              let res = await this.$axios.get(`/getAllProducts`,{
                params:{
                  search: this.searchedCartItem ? this.searchedCartItem : null,
                  offset: 10,
                  page: page ? page : null,
                }
              });
              if(res.data.status_code == "1007"){

                this.cartItemsList = res.data.products.data

                this.cartItemsPagination = {
                  current_page: page,
                  total: res.data.products.last_page,
                  previous_page:0,
                  last_page: res.data.products.last_page
                }

                return true;

              }
            }catch(error){

              this.cartItemsList = [];

              this.cartItemsPagination = {
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
              }

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.cartItemsLoading = false;

            }

          },500);

        }

      }else if(itemsType == 'bundles'){

        if(operation == 'simple'){

          this.cartItemsLoading = true;

          try{
            let res = await this.$axios.get('/product-bundle/all',{
              params:{
                search: this.searchedCartItem ? this.searchedCartItem : null,
                offset: 10,
                page: page ? page : null,
                active: 1,
              }
            });
            if(res.data.status_code == "1761"){

              this.cartItemsList = res.data.product_bundles.data.map((listedBundle) => {

                listedBundle.bundle_items = listedBundle.bundle_items.map((listedItem) => {

                  listedItem.item_details = JSON.parse(listedItem.item_details);

                  return listedItem;

                });

                return listedBundle;

              });

              this.cartItemsPagination = {
                current_page: page,
                total: res.data.product_bundles.total,
                previous_page:0,
                last_page: res.data.product_bundles.last_page
              }

            }
          }catch(error){

            this.cartItemsList = [];

            this.cartItemsPagination = {
              current_page: 0,
              total: 0,
              previous_page: 0,
              last_page: 0,
            }

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response ? error.response.data.message : error.message,
            });

          }finally{

            this.cartItemsLoading = false;

          }

        }else if(operation == 'search'){

          this.cartItemsLoading = true;

          clearTimeout(this.timer);

          this.timer = setTimeout( async ()=>{
            try{
              let res = await this.$axios.get('/product-bundle/all',{
                params:{
                  search: this.searchedCartItem ? this.searchedCartItem : null,
                  offset: 10,
                  page: page ? page : null,
                  active: 1,
                }
              });
              if(res.data.status_code == "1761"){

                this.cartItemsList = res.data.product_bundles.data.map((listedBundle) => {

                  listedBundle.bundle_items = listedBundle.bundle_items.map((listedItem) => {

                    listedItem.item_details = JSON.parse(listedItem.item_details);

                    return listedItem;

                  });

                  return listedBundle;

                });

                this.cartItemsPagination = {
                  current_page: page,
                  total: res.data.product_bundles.total,
                  previous_page:0,
                  last_page: res.data.product_bundles.last_page
                }

              }
            }catch(error){

              this.cartItemsList = [];

              this.cartItemsPagination = {
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
              }

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
              });

            }finally{

              this.cartItemsLoading = false;

            }
          },500);

        }

      }

    },
    carItemTypeChangeHandle(itemType){

      if(this.cartItemsModal.itemsType != itemType){

        this.cartItemsModal.itemsType = itemType;

        this.searchedCartItem = "";

        this.cartItemsPagination = {
          current_page: 0,
          total: 0,
          previous_page: 0,
          last_page: 0,
        }

        this.searchCartItems(itemType, 'simple', 1);

      }

    },
    addBundle(form,listedBundle){

      if(form == 'add-order'){

        let isFoundInProducts = false;

        let isFoundInBundles = false;

        this.addOrderForm.items.forEach((item) => {

          if(item.type == 'bundle'){

            if(item.product_id == listedBundle.id){

              isFoundInBundles = true;

            }

          }else if(item.type == 'simple' || item.type == 'variable'){

            listedBundle.bundle_items.forEach((bundledItem) => {

              if(bundledItem.product_variant_id == item.variant.id){

                isFoundInProducts = true;

              }

            });

          }

        });

        if(isFoundInProducts){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "One of the items in the selected bundle is already added in the cart.",
          });

        }else if(isFoundInBundles){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Product bundle has already been added in the cart.",
          });

        }else{

          if(listedBundle.discount_type == 'none'){

            let tempBundleObj = {
              product_id: listedBundle.id,
              image: listedBundle.image ? listedBundle.image : null,
              name: listedBundle.name,
              type: 'bundle',
              discount_type: listedBundle.discount_type,
              bundle_total: listedBundle.bundle_total,
              discounted_total: listedBundle.discounted_total,
              product_deleted: listedBundle.deleted_at ? true : false,
              variant:{
                price: listedBundle.bundle_total,
                is_discounted_price: false,
              },
              bundleItems:[],
            }

            listedBundle.bundle_items.forEach((bundleItem) => {

              let tempBundleItemObj = {
                id: bundleItem.id,
                product_id: bundleItem.product_id,
                product_variant_id: bundleItem.product_variant_id,
                qty: bundleItem.min_quantity,
                unit_price: bundleItem.unit_price,
                item_details: bundleItem.item_details,
              }

              tempBundleObj.bundleItems.push(tempBundleItemObj);

            });

            this.addOrderForm.items.push(tempBundleObj);

          }else{

            let tempBundleObj = {
              product_id: listedBundle.id,
              image: listedBundle.image ? listedBundle.image : null,
              name: listedBundle.name,
              type: 'bundle',
              discount_type: listedBundle.discount_type,
              bundle_total: listedBundle.bundle_total,
              discounted_total: listedBundle.discounted_total,
              product_deleted: listedBundle.deleted_at ? true : false,
              variant:{
                price: listedBundle.discounted_total,
                is_discounted_price: true,
              },
              bundleItems:[],
            }

            listedBundle.bundle_items.forEach((bundleItem) => {

              let tempBundleItemObj = {
                id: bundleItem.id,
                product_id: bundleItem.product_id,
                product_variant_id: bundleItem.product_variant_id,
                qty: bundleItem.min_quantity,
                unit_price: bundleItem.unit_price,
                item_details: bundleItem.item_details,
              }

              tempBundleObj.bundleItems.push(tempBundleItemObj);

            });

            this.addOrderForm.items.push(tempBundleObj);

          }

        }

      }else if(form == 'update-order'){

        let isFoundInProducts = false;

        let isFoundInBundles = false;

        this.updateOrderForm.items.forEach((item) => {

          if(item.type == 'bundle'){

            if(item.product_id == listedBundle.id){

              isFoundInBundles = true;

            }

          }else if(item.type == 'simple' || item.type == 'variable'){

            listedBundle.bundle_items.forEach((bundledItem) => {

              if(bundledItem.product_variant_id == item.variant.id){

                isFoundInProducts = true;

              }

            });

          }

        });

        if(isFoundInProducts){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "One of the items in the selected bundle is already added in the cart.",
          });

        }else if(isFoundInBundles){

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Product bundle has already been added in the cart.",
          });

        }else{

          if(listedBundle.discount_type == 'none'){
          
            let tempBundleObj = {
              product_id: listedBundle.id,
              image: listedBundle.image ? listedBundle.image : null,
              name: listedBundle.name,
              type: 'bundle',
              discount_type: listedBundle.discount_type,
              bundle_total: listedBundle.bundle_total,
              discounted_total: listedBundle.discounted_total,
              product_deleted: listedBundle.deleted_at ? true : false,
              variant:{
                price: listedBundle.bundle_total,
                is_discounted_price: false,
              },
              bundleItems:[],
            }

            listedBundle.bundle_items.forEach((bundleItem) => {

              let tempBundleItemObj = {
                id: bundleItem.id,
                product_id: bundleItem.product_id,
                product_variant_id: bundleItem.product_variant_id,
                qty: bundleItem.min_quantity,
                unit_price: bundleItem.unit_price,
                item_details: bundleItem.item_details,
              }

              tempBundleObj.bundleItems.push(tempBundleItemObj);

            });

            this.updateOrderForm.items.push(tempBundleObj);
          
          }else{

            let tempBundleObj = {
              product_id: listedBundle.id,
              image: listedBundle.image ? listedBundle.image : null,
              name: listedBundle.name,
              type: 'bundle',
              discount_type: listedBundle.discount_type,
              bundle_total: listedBundle.bundle_total,
              discounted_total: listedBundle.discounted_total,
              product_deleted: listedBundle.deleted_at ? true : false,
              variant:{
                price: listedBundle.discounted_total,
                is_discounted_price: true,
              },
              bundleItems:[],
            }

            listedBundle.bundle_items.forEach((bundleItem) => {

              let tempBundleItemObj = {
                id: bundleItem.id,
                product_id: bundleItem.product_id,
                product_variant_id: bundleItem.product_variant_id,
                qty: bundleItem.min_quantity,
                unit_price: bundleItem.unit_price,
                item_details: bundleItem.item_details,
              }

              tempBundleObj.bundleItems.push(tempBundleItemObj);

            });

            this.updateOrderForm.items.push(tempBundleObj);

          }

        }

      }
      
    },
    //cart items block related methods end here

    //customer related methods start here
    async handleSelectedCustomer(e,form){

      if(!e.isActive){

        if(form == 'add-order'){
          
          this.$refs.addCustomerMultiSelect.deactivate();

        }else if(form == 'update-order'){
          
          this.$refs.updateCustomerMultiSelect.deactivate();

        }

        Swal.fire({
          title: 'Note',
          text: "The selected customer's status is currently inactive. It will be automatically changed to active upon saving the order.",
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#15223D',
          cancelButtonColor: '#15223D',
          confirmButtonText: 'OK'
        }).then((result) => {

          if(result.isConfirmed){
            
          }

        }).catch(() => {})

      }

      if(form == 'add-order'){

        if(e.address_line_2 != null){

          this.isSameBilling = true;

          //shipping 
          this.shipping_address = e.address_line_1 + e.address_line_2;
          this.addOrderForm.shipping.shipping_first_name = e.first_name;
          this.addOrderForm.shipping.shipping_last_name = e.last_name;
          this.addOrderForm.shipping.shipping_email = e.email;
          this.addOrderForm.shipping.shipping_contact_no = e.mobile_no;
          
          //billing
          this.billing_address = e.address_line_1 + e.address_line_2;
          this.addOrderForm.billing.billing_first_name = e.first_name;
          this.addOrderForm.billing.billing_last_name = e.last_name;
          this.addOrderForm.billing.billing_email = e.email;
          this.addOrderForm.billing.billing_contact_no = e.mobile_no;

        }else{

          this.isSameBilling = true;

          //shipping
          this.shipping_address = e.address_line_1;
          this.addOrderForm.shipping.shipping_first_name = e.first_name;
          this.addOrderForm.shipping.shipping_last_name = e.last_name;
          this.addOrderForm.shipping.shipping_email = e.email;
          this.addOrderForm.shipping.shipping_contact_no = e.mobile_no;
          
          //billing
          this.billing_address = e.address_line_1;
          this.addOrderForm.billing.billing_first_name = e.first_name;
          this.addOrderForm.billing.billing_last_name = e.last_name;
          this.addOrderForm.billing.billing_email = e.email;
          this.addOrderForm.billing.billing_contact_no = e.mobile_no;

        }

        this.selectedShippingCountry = _.cloneDeep(e.city.state.country);

        this.selectedShippingState = _.cloneDeep(e.city.state);

        this.loadRegions(e.city.id).then(()=>{

          this.selectedShippingCity = _.cloneDeep(e.city);

        });

        this.loadCountryStates('shipping');

        this.loadStateCities('shipping');

        this.selectedBillingCountry = _.cloneDeep(e.city.state.country);

        this.selectedBillingState = _.cloneDeep(e.city.state);

        this.selectedBillingCity = _.cloneDeep(e.city);

      }else if(form == 'update-order'){

        if(e.address_line_2 != null){

          this.isSameBilling = true;

          //shipping 
          this.shipping_address = e.address_line_1 + e.address_line_2;
          this.addOrderForm.shipping.shipping_first_name = e.first_name;
          this.addOrderForm.shipping.shipping_last_name = e.last_name;
          this.addOrderForm.shipping.shipping_email = e.email;
          this.addOrderForm.shipping.shipping_contact_no = e.mobile_no;

          //billing
          this.billing_address = e.address_line_1 + e.address_line_2;
          this.addOrderForm.billing.billing_first_name = e.first_name;
          this.addOrderForm.billing.billing_last_name = e.last_name;
          this.addOrderForm.billing.billing_email = e.email;
          this.addOrderForm.billing.billing_contact_no = e.mobile_no;

        }else{

          this.isSameBilling = true;

          //shipping
          this.shipping_address = e.address_line_1;
          this.addOrderForm.shipping.shipping_first_name = e.first_name;
          this.addOrderForm.shipping.shipping_last_name = e.last_name;
          this.addOrderForm.shipping.shipping_email = e.email;
          this.addOrderForm.shipping.shipping_contact_no = e.mobile_no;

          //billing
          this.billing_address = e.address_line_1;
          this.addOrderForm.billing.billing_first_name = e.first_name;
          this.addOrderForm.billing.billing_last_name = e.last_name;
          this.addOrderForm.billing.billing_email = e.email;
          this.addOrderForm.billing.billing_contact_no = e.mobile_no;

        }

        this.selectedShippingCountry = _.cloneDeep(e.city.state.country);

        this.selectedShippingState = _.cloneDeep(e.city.state);

        this.loadRegions(e.city.id).then(()=>{

        this.selectedShippingCity = _.cloneDeep(e.city);

        });

        this.loadCountryStates('shipping');

        this.loadStateCities('shipping');

        this.selectedBillingCountry = _.cloneDeep(e.city.state.country);

        this.selectedBillingState = _.cloneDeep(e.city.state);

        this.selectedBillingCity = _.cloneDeep(e.city);

      }

    },
    customerCustomLabel(customer){

      if(customer.id != ''){

        if(customer.isActive){

          return `${customer.first_name} ${customer.last_name} - active`

        }else{

          return `${customer.first_name} ${customer.last_name} - inactive`

        }

      }else{
        
        return 'Select Customer'

      }

    },
    onTouchCustomer(form){

      if(form == 'add-order'){

        if(this.addOrderForm.customer.id == ""){

          return (this.customerFieldRequired = true);

        }else{

          return (this.customerFieldRequired = false);

        }

      }else if(form == 'update-order'){

        if(this.updateOrderForm.customer.id == ""){

          return (this.customerFieldRequired = true);

        }else{

          return (this.customerFieldRequired = false);

        }

      }

    },
    onChangeInField(form,field){

      if(form == 'add-order'){

        if(this.isSameBilling){

          if(field == 'first_name'){

            this.addOrderForm.billing.billing_first_name = this.addOrderForm.shipping.shipping_first_name;

          }

          if(field == 'last_name'){

            this.addOrderForm.billing.billing_last_name = this.addOrderForm.shipping.shipping_last_name;

          }

          if(field == 'email'){

            this.addOrderForm.billing.billing_email = this.addOrderForm.shipping.shipping_email;

          }

          if(field == 'contact_no'){

            this.addOrderForm.billing.billing_contact_no = this.addOrderForm.shipping.shipping_contact_no;

          }

          if(field == 'address'){

            this.billing_address = this.shipping_address;

          }

          if(field == 'postal-code'){

            this.addOrderForm.billing.billing_postal_code = this.addOrderForm.shipping.shipping_postal_code;

          }
          
        }

      }else if(form == 'update-order'){

        if(this.isSameBilling){

          if(field == 'first_name'){

            this.updateOrderForm.billing.billing_first_name = this.updateOrderForm.shipping.shipping_first_name;

          }

          if(field == 'last_name'){

            this.updateOrderForm.billing.billing_last_name = this.updateOrderForm.shipping.shipping_last_name;

          }

          if(field == 'email'){

            this.updateOrderForm.billing.billing_email = this.updateOrderForm.shipping.shipping_email;

          }

          if(field == 'contact_no'){

            this.updateOrderForm.billing.billing_contact_no = this.updateOrderForm.shipping.shipping_contact_no;

          }

          if(field == 'address'){

            this.billing_address = this.shipping_address;

          }

          if(field == 'postal-code'){

            this.updateOrderForm.billing.billing_postal_code = this.updateOrderForm.shipping.shipping_postal_code;

          }

        }

      }

    },
    onChangeSameBilling(e,form){

      if(form == 'add-order'){

        if(this.isSameBilling){

          this.selectedBillingCountry = this.selectedShippingCountry;
          this.selectedBillingState = this.selectedShippingState;
          this.selectedBillingCity = this.selectedShippingCity;

          this.addOrderForm.billing.billing_first_name = this.addOrderForm.shipping.shipping_first_name;
          this.addOrderForm.billing.billing_last_name = this.addOrderForm.shipping.shipping_last_name;
          this.addOrderForm.billing.billing_email = this.addOrderForm.shipping.shipping_email;
          this.addOrderForm.billing.billing_contact_no = this.addOrderForm.shipping.shipping_contact_no;
          this.billing_address = this.shipping_address;

          this.selectedBillingCountryStates = _.cloneDeep(this.selectedShippingCountryStates);
          this.selectedBillingStateCities = _.cloneDeep(this.selectedShippingStateCities);

        }else{

          this.selectedBillingCountry = null
          this.selectedBillingState = null
          this.selectedBillingCity = null

          this.addOrderForm.billing.billing_first_name = ""
          this.addOrderForm.billing.billing_last_name = ""
          this.addOrderForm.billing.billing_email = ""
          this.addOrderForm.billing.billing_contact_no = ""

          this.billing_address = ""

          this.selectedBillingCountryStates = [];
          this.selectedBillingStateCities = [];

        }

      }else if(form == 'update-order'){

        if(this.isSameBilling){

          this.selectedBillingCountry = this.selectedShippingCountry;
          this.selectedBillingState = this.selectedShippingState;
          this.selectedBillingCity = this.selectedShippingCity;

          this.updateOrderForm.billing.billing_first_name = this.updateOrderForm.shipping.shipping_first_name;
          this.updateOrderForm.billing.billing_last_name = this.updateOrderForm.shipping.shipping_last_name;
          this.updateOrderForm.billing.billing_email = this.updateOrderForm.shipping.shipping_email;
          this.updateOrderForm.billing.billing_contact_no = this.updateOrderForm.shipping.shipping_contact_no;
          this.billing_address = this.shipping_address;

          this.selectedBillingCountryStates = _.cloneDeep(this.selectedShippingCountryStates);
          this.selectedBillingStateCities = _.cloneDeep(this.selectedShippingStateCities);

        }else{

          this.selectedBillingCountry = null
          this.selectedBillingState = null
          this.selectedBillingCity = null

          this.updateOrderForm.billing.billing_first_name = ""
          this.updateOrderForm.billing.billing_last_name = ""
          this.updateOrderForm.billing.billing_email = ""
          this.updateOrderForm.billing.billing_contact_no = ""

          this.billing_address = ""

          this.selectedBillingCountryStates = [];
          this.selectedBillingStateCities = [];

        }

      }

    },

    openCustomerForm(form){

      this.customer = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile_no: "",
        city: null,
        address_line_1: "",
        address_line_2: "",
        image: "",
        image_src: "",
        isLoading: false,
        isActive: true,
        isOpen: true,
        passwordShow:false,
      };

      this.selectedCustomerCountry = [];
      this.selectedCustomerCountryStatesLoading = false;
      this.selectedCustomerState = [];
      this.selectedCustomerStateCitiesLoading = false;
      this.selectedCustomerCity = [];

      // this.availableCountries.forEach((country)=>{

      //   if(this.settings.country.name == country.name){

      //     if(this.settings.country.phonecode.startsWith("+")){

      //       this.customer.mobile_no = this.settings.country.phonecode.replace(/-/g, '');

      //     }else{

      //       this.customer.mobile_no = "+" + (this.settings.country.phonecode).replace(/-/g, '');

      //     }

      //   }

      // });

      this.contactFieldTouched = false;

      this.$refs.orderCustomerFormObserver.reset();

      if(form == 'add-order'){
          
        this.$refs.addCustomerMultiSelect.toggle();

      }else if(form == 'update-order'){
        
        this.$refs.updateCustomerMultiSelect.toggle();

      }

    },
    resetCustomerForm(){

      this.customer = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile_no: "",
        city: null,
        address_line_1: "",
        address_line_2: "",
        image: "",
        image_src: "",
        isLoading: false,
        isActive: true,
        isOpen: false,
        passwordShow:false,
      };

      this.contactFieldTouched = false;

      // this.$refs.orderCustomerFormObserver.reset();

    },
    async handleAddCustomer(){

      let { customer } = this;

      let formData = new FormData();

      formData.append("first_name", customer.first_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append("last_name", customer.last_name.replace(/^\w/, (c) => c.toUpperCase()).replace(/\s+/g,' ').trim());
      formData.append("email", customer.email);
      formData.append("city", this.selectedCustomerCity.id);
      formData.append("address_line_1", customer.address_line_1.replace(/\s+/g,' ').trim());

      if(customer.address_line_2){

        formData.append('address_line_2', customer.address_line_2.replace(/\s+/g,' ').trim());

      }

      formData.append("password", customer.password);
      formData.append("mobile_no", customer.mobile_no.replace(/\s/g,''));

      formData.append("isActive", customer.isActive ? 1 : 0);

      if(customer.image != ""){

        formData.append("image", customer.image);

      }

      this.customer.isLoading = true;
      try{

        let res = await this.$axios.post("/addCustomer", formData);
        if(res.data.status_code == "1018"){

          let customer = res.data.customer;
          customer.isActive = customer.isActive ? true : false;

          this.list_customer.unshift(customer);

          if(this.addOrderForm.isOpen){

            this.addOrderForm.customer = customer;

          }else if(this.updateOrderForm.isPreview){

            this.updateOrderForm.customer = customer;

          }

          this.customerFieldRequired = false;

          this.loadCustomers();

          this.$store.dispatch('customers_module/fetchStoreCustomersRequest',{size: 15, page:1, loadData:'new'});

          this.$notify({
            type: "success",
            title: "Success",
            message: res.data.message,
          });

          this.resetCustomerForm();

          if(this.addOrderForm.isOpen){

            this.handleSelectedCustomer(customer,'add-order');

          }else if(this.updateOrderForm.isPreview){

            this.handleSelectedCustomer(customer,'update-order');

          }

        }

      }catch(error){

        this.resetCustomerForm();

        if(error.response){

          if(error.response.data.error.includes("aleardy exists")){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else if(error.response.data.error.includes('storage limit has been reached')){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });
          
        }

      }finally{

        this.customer.isLoading = false;

      }

    },
    generatePassword(form,length){

      if(form == 'sliderForm'){

        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+|}{?><:~``}";
        let retVal = "";

        for(var i = 0, n = charset.length; i < length; ++i){

          retVal += charset.charAt(Math.floor(Math.random() * n));
          
        }

        this.$refs.passwordInput.value = retVal;
        this.customer.password = retVal;

        this.$refs.passwordInput.focus();

      }

    },
    async changeAddImage(e){

      this.imageValidations = {
        imgIsRequired:false,
        imgInvalidFormat:false,
        isNotImg:false,
      }

      if(e.target.files.length > 0){

        const file = e.target.files[0];

        if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
          
          this.customer.image = file;
          this.customer.image_src = URL.createObjectURL(file);

          this.imageValidations.imgIsRequired = false;

        }else if(file.type.includes('image/')){

          let uploadedFileType = file.type.slice(6)
          
          this.$notify({
            type: "error",
            title: "Alert",
            message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
          });

        }else{

          this.$notify({
            type: "error",
            title: "Alert",
            message: "Uploaded file must be an image.",
          }); 

        }

      }

    },
    resetValidator(){

      this.customer.isOpen = false;

      // this.$refs.orderCustomerFormObserver.reset();

    },
    handleFormat(field){

      if(field == 'f_name'){

        let fname = this.customer.first_name;
        fname = fname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        fname = fname.replace(/\s+/g, ' ');
        fname = fname.replace(/^\w/, (c) => c.toUpperCase());
        
        this.customer.first_name = fname;

      }else if(field == 'l_name'){

        let lname = this.customer.last_name;
        lname = lname.replace('^[a-zA-Z0-9\s\-\.\,\_\&\!\#\@\$\%\^\*\(\)\+\=\{\}\[\]\|\\\/\?\:\;\"\'\<\>\~\`]*$', '');
        lname = lname.replace(/\s+/g, ' ');
        lname = lname.replace(/^\w/, (c) => c.toUpperCase());

        this.customer.last_name = lname;

      }

    },
    //customer related methods end here

    //order note related methods start here
    addOrderNote(form){

      if(form == 'add-order'){

        if(this.orderNote){

          let note = {
            note: this.orderNote,
          };

          this.addOrderForm.notes.push(note);

          this.$notify({
            type: "success",
            title: "Success",
            message: "Admin order note has been successfully added.",
          });

          this.orderNote = "";

        }else{

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Admin order note must be added in the available field.",
          });

        }

      }else if(form == 'update-order'){

        if(this.orderNote){

          let note = {
            note: this.orderNote,
          };

          this.tempOrderNotes.push(note);

          this.$notify({
            type: "success",
            title: "Success",
            message: "Admin order note has been successfully added.",
          });

          this.orderNote = "";

        }else{

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Admin order note must be added in the available field.",
          });

        }

      }

    },
    orderNoteModalPreview(form, note, index){

      if(form == 'add-order'){

        this.selectedOrderNote = note;
        this.orderNoteForm.updatedOrderNote = note.note;
        this.orderNoteForm.index = index;
        this.orderNoteForm.isOpen = true;

      }else if(form == 'update-order'){

        this.selectedOrderNote = note;
        this.orderNoteForm.updatedOrderNote = note.note;
        this.orderNoteForm.index = index;
        this.orderNoteForm.isOpen = true;

      }

    },
    async removeOrderNote(form, note, index){

      if(form == 'add-order'){

        this.$notify({
          type: "success",
          title: "Success",
          message: "Admin order note has been successfully removed.",
        });

        this.addOrderForm.notes.splice(index, 1);

      }else if(form == 'update-order'){

        MessageBox.confirm(
          "Are you sure you want to delete the order note?", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(async ()=>{

          if(note.created_at){

            let formData = new FormData();

            formData.append("order_note_id", note.id);
            formData.append("order_id", this.updateOrderForm.id);

            try{
              let res = await this.$axios.post("/removeOrderNote", formData);
              if(res.data.status_code == "1230"){

                this.$notify({
                  type: "success",
                  title: "Success",
                  message: res.data.message,
                });

                let payload = {
                  orderNote: note,
                  noteIndex: index,
                  orderIndex: this.updateOrderForm.index,
                };

                this.$store.commit("orders_module/delete_ordersNote", payload);

                this.tempOrder.notes.splice(index,1);

                this.updateOrderForm.notes.splice(index,1);

              }
            }catch(error){

              if(error.response){

                if(error.response.data.error.includes("deleted or isn't available.")){

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response.data.error,
                  });

                }else{

                  this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response.data.message,
                  });

                }

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.message,
                });

              }

            }

          }else{

            this.$notify({
              type: "success",
              title: "Success",
              message: "Order note has been successfully removed.",
            });

            this.tempOrderNotes.splice(index, 1);

          }

        }).catch(()=>{});


      }

    },
    async updateOrderNote(form){

      if(form == 'add-order'){

        if(this.orderNoteForm.updatedOrderNote){

          this.orderNoteForm.isOpen = false;

          this.$notify({
            type: "success",
            title: "Success",
            message: "Admin order note has been successfully added.",
          });

          this.addOrderForm.notes[this.orderNoteForm.index].note =
          this.orderNoteForm.updatedOrderNote

        }else{

          this.$notify({
            type: "info",
            title: "Alert",
            message: "Admin order note must be added in the provided field.",
          });

        }

      }else if(form == 'update-order'){

        if(this.selectedOrderNote.created_at){

          let formData = new FormData();

          formData.append("order_note_id", this.selectedOrderNote.id);
          formData.append("order_id", this.updateOrderForm.id);
          formData.append("new_note", this.orderNoteForm.updatedOrderNote);

          this.orderNoteForm.pending = true;
          try{
            let res = await this.$axios.post("updateOrderNote", formData);
            if(res.data.status_code == "1231"){

              this.$notify({
                type: "success",
                title: "success",
                message: res.data.message,
              });

              let payload = {
                orderIndex: this.updateOrderForm.index,
                updatedOrderNote: res.data.order_note,
                orderNoteIndex: this.orderNoteForm.index,
              };

              this.tempOrder.notes[this.orderNoteForm.index].note = res.data.order_note.note

              this.updateOrderForm.notes[this.orderNoteForm.index].note = res.data.order_note.note;

              this.$store.commit("orders_module/update_OrderNote", payload);

              this.orderNoteForm.isOpen = false;

            }

          }catch(error){

            if(error.response){

              if(error.response.data.error.includes("deleted or isn't available.")){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.error,
                });

              }else{

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response.data.message,
                });

              }

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.message,
              });

            }

          }finally{

            this.orderNoteForm.pending = true;

          }

        }else{

          this.orderNoteForm.isOpen = false;

          this.$notify({
            type: "success",
            title: "Success",
            message: "Order note has been successfully added.",
          });

          this.tempOrderNotes[this.orderNoteForm.index].note = this.orderNoteForm.updatedOrderNote;

        }

      }


    },
    //order note related methods end here

    //coupon verification method starts here
    async verifyCoupon(form){

      if(form == 'add-order'){

        if(!this.addOrderForm.coupon.coupon_code){

          this.$notify({
            type: "info",
            title: "Coupon Code Missing",
            message: "Enter coupon code to proceed with the verification process.",
          });

          return

        }
        if(this.addOrderForm.items.length == 0){

          this.$notify({
            type: "info",
            title: "Products Not Selected",
            message: "At least one product must be selected.",
          });

          return

        }
        if(this.coupon.length > 0 && this.coupon[0].coupon_code == this.addOrderForm.coupon.coupon_code){

          this.$notify({
            type: "info",
            title: "Coupon Already Verified",
            message: "Coupon has already been verified.",
          });

          return

        }

        if(this.addOrderForm.customer.email.length == 0){

          this.$notify({
            type: "info",
            title: "Customer Not Selected.",
            message: "Customer must be selected first.",
          });

          return

        }

        this.couponLoader = true;
        this.coupon = [];
        let formData = new FormData();

        this.addOrderForm.payment.coupon_discounted_amount = 0;

        this.coupon = [];

        formData.append("coupon", this.addOrderForm.coupon.coupon_code);
        formData.append("email", this.addOrderForm.customer.email);
        try{
          let res = await this.$axios.post("/validateCoupon", formData);
          if(res.data.status_code == "1074"){

            this.couponLoader = false;

            this.coupon.push(res.data.coupon);

            this.$notify({
              type: "success",
              title: "Success",
              message: "Entered coupon has been successfully verified.",
            });

            if(this.addOrderSelectedShipping != ""){
              
              this.$message({
                type: "info",
                showClose: true,
                message: "Selected shipping option has been removed.",
              });

              this.addOrderForm.payment.shipping_rule_price_id = ""
              this.addOrderForm.payment.shipping_rule_id = ''
              this.addOrderSelectedShipping = ""

            }

            if(this.customShippingCharges){

              this.$notify({
                type: "info",
                title: "Custom Shipping Charges",
                message: "Custom shipping charges have been removed.",
              });

              this.updateOrderForm.payment.is_custom_shipping = false;

              this.customShippingCharges = null;

              this.updateOrderForm.payment.custom_shipping_amount = null;

            }

            this.addOrderForm.payment.shipping_amount = 0

          }
        }catch(error){

          this.couponLoader = false;
          if(error.response){

            if(typeof(error.response.data.error) == 'string' &&  
            error.response.data.error.includes("expired")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });
              
            }else if(typeof(error.response.data.error) == 'string' && 
            error.response.data.error.includes("not available")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else if(typeof(error.response.data.error) == 'string' && 
            error.response.data.error.includes("reached")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else if(typeof(error.response.data.error) == 'object' && 
            error.response.data.error.coupon[0].includes("invalid")){

              this.$message({
                type: "error",
                showClose: true,
                message: "Entered coupon doesn't exist.",
              });

            }else if(error.response.data.error.includes("doesn't support")){

              let subscriptionPath = window.location.origin + "/subscription";

              this.$message({
                type: 'error',
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: error.response.data.error 
                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }

      }else if(form == 'update-order'){

        if(!this.updateOrderForm.coupon.coupon_code){

          this.$notify({
            type: "info",
            title: "Coupon Code Missing",
            message: "Enter coupon code to proceed with the verification process.",
          });

          return

        }
        if(this.updateOrderForm.items.length == 0){

          this.$notify({
            type: "info",
            title: "Products Not Selected",
            message: "At least one product must be selected.",
          });

          return

        }
        if(this.coupon.length > 0 && this.coupon[0].coupon_code == this.updateOrderForm.coupon.coupon_code){

          this.$notify({
            type: "info",
            title: "Coupon Already Verified",
            message: "Coupon has already been verified.",
          });

          return

        }

        if(this.updateOrderForm.customer.email.length == 0){

          this.$notify({
            type: "info",
            title: "Customer Not Selected.",
            message: "Customer must be selected first.",
          });

          return

        }

        this.couponLoader = true;
        this.coupon = [];
        let formData = new FormData();

        this.updateOrderForm.payment.discounted_amount = 0;

        this.coupon = [];

        formData.append("coupon", this.updateOrderForm.coupon.coupon_code);
        formData.append("email", this.updateOrderForm.customer.email);

        try{
          let res = await this.$axios.post("/validateCoupon", formData);
          if(res.data.status_code == "1074"){

            this.couponLoader = false;

            this.coupon.push(res.data.coupon);

            this.$notify({
              type: "success",
              title: "Success",
              message: "Entered coupon has been successfully verified.",
            });

            if(this.updateOrderSelectedShipping != "" || this.updateOrderForm.payment.shipping_amount){
              
              this.$message({
                type: "info",
                showClose: true,
                message: "Selected shipping option has been removed.",
              });

              this.updateOrderForm.payment.shipping_rule_price_id = ""
              this.updateOrderForm.payment.shipping_rule_id = ''
              this.updateOrderSelectedShipping = ""
              
            }

            if(this.customShippingCharges){

              this.$message({
                type: "info",
                showClose: true,
                message: "Custom shipping charges have been removed.",
              });

              this.updateOrderForm.payment.is_custom_shipping = false;

              this.customShippingCharges = null;

            }

            this.updateOrderForm.payment.shipping_amount = 0

          }
        }catch(error){

          this.couponLoader = false;
          if(error.response){

            if(typeof(error.response.data.error) == 'string' &&  
            error.response.data.error.includes("expired")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });
              
            }else if(typeof(error.response.data.error) == 'string' && 
            error.response.data.error.includes("not available")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else if(typeof(error.response.data.error) == 'string' && 
            error.response.data.error.includes("reached")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else if(typeof(error.response.data.error) == 'object' && 
            error.response.data.error.coupon[0].includes("invalid")){

              this.$message({
                type: "error",
                showClose: true,
                message: "Entered coupon doesn't exist.",
              });

            }else if(error.response.data.error.includes("doesn't support")){

              let subscriptionPath = window.location.origin + "/subscription";

              this.$message({
                type: 'error',
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: error.response.data.error 
                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }

      }

    },
    //coupon verification method ends here

    checkDate(form){

      if(form == 'add-order'){

        if(this.addOrderForm.order_date && this.addOrderForm.delivery_date){

          var order_date = new Date(this.addOrderForm.order_date);
          var deliver_date = new Date(this.addOrderForm.delivery_date);

          if(deliver_date < order_date){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Can't set delivery date before order date.",
            });

            this.addOrderForm.delivery_date = "";

          }

        }

      }else if(form == 'update-order'){

        if(this.updateOrderForm.order_date && this.updateOrderForm.delivery_date){

          var order_date = new Date(this.updateOrderForm.order_date);
          var deliver_date = new Date(this.updateOrderForm.delivery_date);

          if(deliver_date < order_date){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Can't set delivery date before order date.",
            });

            this.updateOrderForm.delivery_date = "";

          }

        }

      }

    },

    applyCustomShipping(form){

      if(form == 'add-order'){

        if(this.addOrderForm.items.length == 0){

          this.$notify({
            type: "info",
            title: "Products Not Selected",
            message: "At least one product must be selected.",
          });

          return

        }

        if(this.customShippingCharges){

          if(!this.addOrderSelectedShipping){

            this.addOrderForm.payment.shipping_amount = this.customShippingCharges;
            
            this.addOrderForm.payment.shipping_rule_id = ""

            this.addOrderForm.payment.shipping_rule_price_id = ""

            this.addOrderForm.payment.amount = 0;

            this.addOrderForm.payment.is_custom_shipping = true;

          }else if(this.addOrderSelectedShipping){

            this.addOrderSelectedShipping = "";

            this.addOrderForm.payment.shipping_amount = this.customShippingCharges;

            this.addOrderForm.shipping.shipping_rule_id = ""

            this.addOrderForm.payment.shipping_rule_price_id = ""

            this.addOrderForm.payment.amount = 0;

            this.addOrderForm.payment.is_custom_shipping = true;

          }

          this.$notify({
            type: "success",
            title: "Success",
            message: "Custom shipping charges have been successfully added.",
          });

        }else{

          if(this.addOrderForm.payment.is_custom_shipping){

            this.addOrderForm.payment.shipping_amount = 0;

            this.addOrderForm.payment.is_custom_shipping = false;

            if(this.addOrderSelectedShipping){

              this.addOrderSelectedShipping = "";

              this.addOrderForm.payment.shipping_amount = this.customShippingCharges;

              this.addOrderForm.shipping.shipping_rule_id = ""

              this.addOrderForm.payment.shipping_rule_price_id = ""

              this.addOrderForm.payment.amount = 0;

              this.addOrderForm.payment.is_custom_shipping = false;

            }

            this.$notify({
              type: "success",
              title: "Success",
              message: "Custom shipping charges have been successfully removed.",
            });

          }

        }

      }else if(form == 'update-order'){

        if(this.updateOrderForm.items.length == 0){

          this.$notify({
            type: "info",
            title: "Products Not Selected",
            message: "At least one product must be selected.",
          });

          return

        }

        if(this.customShippingCharges){

          if(!this.updateOrderSelectedShipping){

            this.updateOrderForm.payment.shipping_amount = this.customShippingCharges;
            
            this.updateOrderForm.payment.shipping_rule_id = "";

            this.updateOrderForm.payment.shipping_rule_price_id = "";

            this.updateOrderForm.payment.is_free_shipping = false;

            this.updateOrderForm.payment.amount = 0;

            this.updateOrderForm.payment.is_custom_shipping = true;
            
            this.updateOrderForm.payment.custom_shipping_amount = this.customShippingCharges;

          }else if(this.updateOrderSelectedShipping){

            this.updateOrderSelectedShipping = "";

            this.updateOrderForm.payment.shipping_amount = this.customShippingCharges;

            this.updateOrderForm.shipping.shipping_rule_id = "";

            this.updateOrderForm.payment.shipping_rule_price_id = "";

            this.updateOrderForm.payment.is_free_shipping = false;

            this.updateOrderForm.payment.amount = 0;

            this.updateOrderForm.payment.is_custom_shipping = true;

            this.updateOrderForm.payment.custom_shipping_amount = this.customShippingCharges;
            
          }

          this.$notify({
            type: "success",
            title: "Success",
            message: "Custom shipping charges have been successfully added.",
          });

        }else{

          if(this.updateOrderForm.payment.is_custom_shipping){

            this.updateOrderForm.payment.shipping_amount = 0;

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderForm.payment.custom_shipping_amount = null;

            this.$notify({
              type: "success",
              title: "Success",
              message: "Custom shipping charges have been successfully removed.",
            });

          }

        }

      }

    },

    handleTaxInput(form){

      if(form == 'add-order'){

        if(this.addOrderForm.payment.tax != this.tempOrder.payment.tax){

          if(this.addOrderForm.payment.tax.length == 0){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Tax percentage field is required.",
            });

            this.addOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          let regx = new RegExp(/^[0-9][0-9]*$/);

          if(!regx.test(this.addOrderForm.payment.tax)){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Tax percentage can only be numeric."
            });

            this.addOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          if(this.addOrderForm.payment.tax > 100){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Tax percentage cannot be more than 100.",
            });

            this.addOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          if(this.addOrderForm.payment.tax < 0){

            this.$notify({
              type: "warning",
              title: "Alert",
              message: "Tax percentage cannot be less than 0.",
            });

            this.addOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          if(this.addOrderSelectedShipping){

            this.addOrderSelectedShipping = "";

            this.addOrderForm.payment.shipping_amount = 0;

            this.addOrderForm.shipping.shipping_rule_id = ""

            this.addOrderForm.payment.shipping_rule_price_id = ""

            this.addOrderForm.payment.amount = 0;

            this.addOrderForm.payment.is_custom_shipping = false;

            this.$message({
              type: "info",
              showClose: true,
              message: "Selected shipping option has been removed.",
            });

          }

          this.tempOrder.payment.tax = this.addOrderForm.payment.tax

        }else{

          this.addOrderForm.payment.tax = 0;

          this.tempOrder.payment.tax = 0

        }

      }else if(form == 'update-order'){

        if(this.updateOrderForm.payment.tax != this.tempOrder.payment.tax){

          if(this.updateOrderForm.payment.tax.length == 0){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Tax percentage field is required.",
            });

            this.updateOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          let regx = new RegExp(/^[0-9][0-9]*$/);

          if(!regx.test(this.updateOrderForm.payment.tax)){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Tax percentage can only be numeric."
            });

            this.updateOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          if(this.updateOrderForm.payment.tax > 100){

            this.$notify({
              type: "info",
              title: "Alert",
              message: "Tax percentage cannot be more than 100.",
            });

            this.updateOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          if(this.updateOrderForm.payment.tax < 0){

            this.$notify({
              type: "warning",
              title: "Alert",
              message: "Tax percentage cannot be less than 0.",
            });

            this.updateOrderForm.payment.tax = this.tempOrder.payment.tax;

            return

          }

          if(this.updateOrderForm.payment.shipping_rule && !this.updateOrderSelectedShipping){

            if(this.updateOrderForm.payment.shipping_rule.shipping.type == 'Rate By Price'){

              let amount = 0;

              if(this.updateOrderForm.payment.discounted_amount){

                let taxPercent = parseFloat(this.updateOrderForm.payment.tax / 100).toFixed(2);
                let tempAmount = parseFloat(this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount);

                let percent_amount = (taxPercent * tempAmount).toFixed(2);

                let tempPercentAmount = parseFloat(percent_amount);

                amount = (this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount + tempPercentAmount).toFixed(2);

              }else{

                let taxPercent = parseFloat(this.updateOrderForm.payment.tax / 100).toFixed(2);
                let tempAmount = parseFloat(this.updateOrderForm.payment.amount);

                let percent_amount = (taxPercent * tempAmount).toFixed(2);

                let tempPercentAmount = parseFloat(percent_amount);

                amount = this.updateOrderForm.payment.amount + tempPercentAmount;

              }

              let foundPrice = 0;
              let val = [];

              this.updateOrderForm.payment.shipping_rule.rule_prices.forEach((price) => {

                if(amount >= parseFloat(price.from).toFixed(2) && amount <= parseFloat(price.to).toFixed(2)){

                  if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderForm.payment.shipping_rule.shipping.is_non_cod_different){

                    foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                    this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                  }else{

                    foundPrice = parseFloat(price.price).toFixed(2);

                    this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                  }

                  val = price;

                }else if(amount > parseFloat(price.from) && (price.to == "" || price.to == null)){

                  if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderForm.payment.shipping_rule.shipping.is_non_cod_different){

                    foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                    this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                  }else{

                    foundPrice = parseFloat(price.price).toFixed(2);

                    this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                  }

                  val = price;

                }

              });

              this.updateOrderForm.payment.shipping_amount = foundPrice;

            }

          }

          if(this.updateOrderSelectedShipping){

            this.updateOrderSelectedShipping = "";

            this.updateOrderForm.payment.shipping_amount = 0;

            this.updateOrderForm.shipping.shipping_rule_id = "";

            this.updateOrderForm.payment.shipping_rule_price_id = "";

            this.updateOrderForm.payment.is_free_shipping = false;

            this.updateOrderForm.payment.amount = 0;

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderForm.payment.custom_shipping_amount = 0;

            this.$message({
              type: "info",
              showClose: true,
              message: "Selected shipping option has been removed.",
            });

          }

          // this.tempOrder.payment.tax = this.updateOrderForm.payment.tax

        }else{

          this.updateOrderForm.payment.tax = 0;

          // this.tempOrder.payment.tax = 0;

        }

      }

    },
    taxChangeHandle(form,operation){

      if(form == 'add-order'){

        let isChanged = false;

        if(operation == 'incr'){

          if(this.addOrderForm.payment.tax < 100){

            this.addOrderForm.payment.tax++

            isChanged = true;

          }

        }else if(operation == 'decr'){

          if(this.addOrderForm.payment.tax > 0){

            this.addOrderForm.payment.tax--

            isChanged = true;

          }

        }

        if(this.addOrderSelectedShipping && isChanged){

          this.addOrderSelectedShipping = "";

          this.addOrderForm.payment.shipping_amount = 0;

          this.addOrderForm.shipping.shipping_rule_id = ""

          this.addOrderForm.payment.shipping_rule_price_id = ""

          this.addOrderForm.payment.amount = 0;

          this.addOrderForm.payment.is_custom_shipping = false;

          this.$message({
            type: "info",
            showClose: true,
            message: "Selected shipping option has been removed.",
          });

        }

      }else if(form == 'update-order'){

        let isChanged = false;

        if(operation == 'decr'){

          if(this.updateOrderForm.payment.tax > 0){

            this.updateOrderForm.payment.tax--

            isChanged = true;

          }

        }else if(operation == 'incr'){
          
          if(this.updateOrderForm.payment.tax < 100){

            this.updateOrderForm.payment.tax++

            isChanged = true;

          }

        }

        if(this.updateOrderForm.payment.shipping_rule && !this.updateOrderSelectedShipping){

          if(this.updateOrderForm.payment.shipping_rule.shipping.type == 'Rate By Price'){

            let amount = 0;

            if(this.updateOrderForm.payment.discounted_amount){

              let taxPercent = parseFloat(this.updateOrderForm.payment.tax / 100).toFixed(2);
              let tempAmount = parseFloat(this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount);

              let percent_amount = (taxPercent * tempAmount).toFixed(2);

              let tempPercentAmount = parseFloat(percent_amount);

              amount = (this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount + tempPercentAmount).toFixed(2);

            }else{

              let taxPercent = parseFloat(this.updateOrderForm.payment.tax / 100).toFixed(2);
              let tempAmount = parseFloat(this.updateOrderForm.payment.amount);

              let percent_amount = (taxPercent * tempAmount).toFixed(2);

              let tempPercentAmount = parseFloat(percent_amount);

              amount = this.updateOrderForm.payment.amount + tempPercentAmount;

            }

            let foundPrice = 0;
            let val = [];

            this.updateOrderForm.payment.shipping_rule.rule_prices.forEach((price) => {

              if(amount >= parseFloat(price.from).toFixed(2) && amount <= parseFloat(price.to).toFixed(2)){

                if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderForm.payment.shipping_rule.shipping.is_non_cod_different){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }

                val = price;

              }else if(amount > parseFloat(price.from) && (price.to == "" || price.to == null)){

                if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderForm.payment.shipping_rule.shipping.is_non_cod_different){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }

                val = price;

              }

            });

            this.updateOrderForm.payment.shipping_amount = foundPrice;

          }

        }
        
        if(this.updateOrderSelectedShipping){

          if(isChanged){

            this.updateOrderSelectedShipping = "";

            this.updateOrderForm.payment.shipping_amount = 0;

            this.updateOrderForm.shipping.shipping_rule_id = "";

            this.updateOrderForm.payment.shipping_rule_price_id = "";

            this.updateOrderForm.payment.is_free_shipping = false;

            this.updateOrderForm.payment.amount = 0;

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderForm.payment.custom_shipping_amount = 0;

            this.$message({
              type: "info",
              showClose: true,
              message: "Selected shipping option has been removed.",
            });

          }

        }

      }

    },

    expand(form){

      if(form == 'add-order'){

        this.$refs.viewAddInputForm.classList.toggle("fullWidthSlide");

      }else if(form == 'update-order'){

        this.$refs.viewInputForm.classList.toggle("fullWidthSlide");

      }

    },
    ordersKeyEvent(e){

      if(e.key == 'Escape'){

        if(this.addOrderForm.isOpen){

          if(this.$refs.viewAddInputForm.classList.contains('fullWidthSlide')){

            this.$refs.viewAddInputForm.classList.remove('fullWidthSlide');

          }else if(this.$refs.viewAddInputForm.classList.contains('activeRow')){

            this.newOrderOperations('close','with-confirmation');

          }

        }else if(this.updateOrderForm.isPreview){

          if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

            this.$refs.viewInputForm.classList.remove('fullWidthSlide');

          }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

            this.updateOrderOperations('close','without-confirmation');

          }

        }

      }

    },

    countrySelectHandle(e,form){

      if(form == 'shipping'){

        this.selectedShippingState = null;

        this.selectedShippingCity = null;

        this.selectedBillingState = null;

        this.selectedBillingCity = null;

        if(this.isSameBilling){

          this.selectedBillingCountry = _.cloneDeep(this.selectedShippingCountry); 

        }else{

          this.selectedBillingCountry = null;

        }

        this.loadCountryStates(form);

      }else if(form == 'billing'){

        this.selectedBillingState = null;

        this.selectedBillingCity = null;

        this.loadCountryStates(form);

      }else if(form == 'add-customer'){

        this.selectedCustomerState = [];

        this.selectedCustomerCity = [];

        this.loadCountryStates(form);

      }

    },
    stateSelectHandle(e,form){

      if(form == 'shipping'){

        this.selectedShippingCity = null;

        this.selectedBillingCity = null;

        if(this.addOrderForm.isOpen){
          
          this.addOrderForm.payment.shipping_rule_id = "";
          this.addOrderForm.payment.shipping_rule_price_id = "";
          this.addOrderForm.payment.shipping_amount = null;

          this.addOrderShippingRules = [];
          this.addOrderSelectedShipping = "";

        }else if(this.updateOrderForm.isPreview){

          this.updateOrderForm.payment.shipping_rule_id = "";
          this.updateOrderForm.payment.shipping_rule_price_id = "";
          this.updateOrderForm.payment.shipping_amount = null;

          this.updateOrderSelectedShipping = "";
          this.updateOrderShippingRules = null;

        }

        if(this.isSameBilling){

          this.selectedBillingState = _.cloneDeep(this.selectedShippingState); 

        }else{

          this.selectedBillingState = null;

        }

        this.loadStateCities(form);

      }else if(form == 'billing'){

        this.selectedBillingCity = null;

        this.loadStateCities(form);

      }else if(form == 'add-customer'){

        this.selectedCustomerCity = [];

        this.loadStateCities(form);

      }

    },
    citySelectHandle(e,form){

      if(form == 'shipping'){
        
        this.loadRegions(this.selectedShippingCity.id);

        if(this.isSameBilling){

          this.selectedBillingCity = _.cloneDeep(this.selectedShippingCity); 

        }

      }

    },

    async loadRegions(city_id){

      this.regions = [];

      this.regionsLoader = true;

      try{
        let res = await this.$axios.get('shipping/region/all',{params:{city:city_id,}})
        if(res.data.status_code == "1011"){

          if(res.data.regions.length > 0){

            this.regions = res.data.regions;

          }else{

            this.regions = [];

          }

        }
      }catch(error){

        if(error.response){

          this.$message({
            type: "error",
            showClose: true,
            message: error.response.data.message,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });
          
        }

      }finally{

        this.regionsLoader = false;

      }

    },
    async loadCountryStates(form){

      let countryId = null;

      if(form == 'shipping'){

        countryId = this.selectedShippingCountry.id;

        this.shippingCountryStatesLoading = true;

      }else if(form == 'billing'){

        countryId = this.selectedBillingCountry.id;

        this.billingCountryStatesLoading = true;

      }else if(form == 'add-customer'){

        countryId = this.selectedCustomerCountry.id;

        this.selectedCustomerCountryStatesLoading = true;

      }

      try{
        let res = await this.$axios.get('states/all',{params:{country_id:countryId}});
        if(res.data.status_code == "1010"){

          if(form == "shipping"){

            this.selectedShippingCountryStates = _.cloneDeep(res.data.states);

            if(this.isSameBilling){

              this.selectedBillingCountryStates = _.cloneDeep(res.data.states);

            }

          }else if(form == "billing"){

            this.selectedBillingCountryStates = _.cloneDeep(res.data.states);

          }else if(form == 'add-customer'){

            this.selectedCustomerCountryStates = _.cloneDeep(res.data.states);

          }

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.shippingCountryStatesLoading = false;

        this.billingCountryStatesLoading = false;

        this.selectedCustomerCountryStatesLoading = false;

      }

    },
    async loadStateCities(form){
      
      let stateId = null

      if(form == 'shipping'){

        stateId = this.selectedShippingState.id;

        this.shippingStateCitiesLoading = true;

      }else if(form == 'billing'){

        stateId = this.selectedBillingState.id;

        this.billingStateCitiesLoading = true;

      }else if(form == 'add-customer'){

        stateId = this.selectedCustomerState.id;

        this.selectedCustomerStateCitiesLoading = true;

      }

      try{
        let res = await this.$axios.get('cities/all',{params:{state_id:stateId}});
        if(res.data.status_code == "1010"){

          if(form == 'shipping'){

            this.selectedShippingStateCities = _.cloneDeep(res.data.cities);

            if(this.isSameBilling){

              this.selectedBillingStateCities = _.cloneDeep(res.data.cities);

            }

          }else if(form == 'billing'){

            this.selectedBillingStateCities = _.cloneDeep(res.data.cities);

          }else if(form == 'add-customer'){

            this.selectedCustomerStateCities = _.cloneDeep(res.data.cities);

          }

        }
      }catch(error){
        
        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.shippingStateCitiesLoading = false;

        this.billingStateCitiesLoading = false;

        this.selectedCustomerStateCitiesLoading = false;

      }

    },

    //add customer contact methods starts here
    validateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.contactIsValid = contactObj.valid;
        
      }

    },
    contactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.countryIsSelected = false;

      }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.countryIsSelected = false;

      }else if(typeof numberObj.country != 'undefined' && 
      typeof numberObj.valid == 'undefined' &&
      numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.countryIsSelected = false;

      }else{

        this.countryIsSelected = true;
        
      }

      this.selectedContactObj = numberObj;

    },
    dropDownCloseHandle(){

      if((this.customer.mobile_no).replace(/\s/g,'').length > this.selectedContactCountry.dialCode.length + 1){

        this.customer.mobile_no = "+" + this.selectedContactCountry.dialCode;

      }

      this.countryIsSelected = true;

    },
    countryChangeHandle(countryObj){

      this.selectedContactCountry = countryObj;

    },
    contactFieldFocusHandle(){

      this.contactFieldTouched = true;

    },
    //add customer contact methods ends here

    //shipping contact methods starts here
    shippingValidateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.shippingContactIsValid = contactObj.valid;

      }

    },
    shippingContactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.shippingCountryIsSelected = false;

      }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.shippingCountryIsSelected = false;

      }else if(typeof numberObj.country != 'undefined' && 
      typeof numberObj.valid == 'undefined' &&
      numberObj.formatted.length == numberObj.country.dialCode + 1){
      
        this.shippingCountryIsSelected = false;

      }else{

        this.shippingCountryIsSelected = true;
        
      }

      this.shippingSelectedContactObj = numberObj;

      this.onChangeInField('update-order','contact_no');

    },
    shippingDropDownCloseHandle(){

      if(this.addOrderForm.isOpen){

        if((this.addOrderForm.shipping.shipping_contact_no).replace(/\s/g,'').length > this.shippingSelectedContactCountry.dialCode.length + 1){

          this.addOrderForm.shipping.shipping_contact_no = "+" + this.shippingSelectedContactCountry.dialCode;

        }

        this.shippingCountryIsSelected = true;

      }else if(this.updateOrderForm.isPreview){

        if((this.updateOrderForm.shipping.shipping_contact_no).replace(/\s/g,'').length > this.shippingSelectedContactCountry.dialCode.length + 1){

          this.updateOrderForm.shipping.shipping_contact_no = "+" + this.shippingSelectedContactCountry.dialCode;

        }

        this.shippingCountryIsSelected = true;

      }

    },
    shippingCountryChangeHandle(countryObj){

      this.shippingSelectedContactCountry = countryObj;

    },
    shippingContactFieldFocusHandle(){

      this.shippingContactFieldTouched = true;

    },
    //shipping contact methods ends here

    //billing contact methods starts here
    billingValidateContactNo(contactObj){

      if(typeof contactObj.valid != 'undefined'){

        this.billingContactIsValid = contactObj.valid;

      }

    },
    billingContactInputHandle(number,numberObj){

      if(typeof numberObj.country == 'undefined'){
        
        this.billingCountryIsSelected = false;

      }else if(typeof numberObj.countryCode != 'undefined' && numberObj.countryCode == "--"){
        
        this.billingCountryIsSelected = false;

      }else if(typeof numberObj.country != 'undefined' && 
      typeof numberObj.valid == 'undefined' &&
      numberObj.formatted.length == numberObj.country.dialCode + 1){
        
        this.billingCountryIsSelected = false;

      }else{

        this.billingCountryIsSelected = true;
        
      }

      this.billingSelectedContactObj = numberObj;

    },
    billingDropDownCloseHandle(){

      if(this.addOrderForm.isOpen){

        if((this.addOrderForm.billing.billing_contact_no).replace(/\s/g,'').length > this.billingSelectedContactCountry.dialCode.length + 1){

          this.addOrderForm.billing.billing_contact_no = "+" + this.billingSelectedContactCountry.dialCode;

        }

        this.billingCountryIsSelected = true;

      }else if(this.updateOrderForm.isPreview){

        if((this.updateOrderForm.billing.billing_contact_no).replace(/\s/g,'').length > this.billingSelectedContactCountry.dialCode.length + 1){

          this.updateOrderForm.billing.billing_contact_no = "+" + this.billingSelectedContactCountry.dialCode;

        }

        this.billingCountryIsSelected = true;

      }

    },
    billingCountryChangeHandle(countryObj){

      this.billingSelectedContactCountry = countryObj;

    },
    billingContactFieldFocusHandle(){

      this.billingContactFieldTouched = true;

    },
    //billing contact methods ends here

    //load customers start here
    async loadCustomers(){

      try{
        let res = await this.$axios.get("/getAllCustomers");
        if(res.data.status_code == "1017"){

          this.list_customer = res.data.customers.map((val) => {

            return val;

          });

          this.tempCustomersList = _.cloneDeep(this.list_customer);

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }

    },

    //image loading error handling
    handleImageError(e){

      e.target.src = '/images/image_not_found.png'

    },

    //dev env check
    isDev(){

      if(process.env.VUE_APP_ENV == 'dev'){

        return true;

      }else{

        return false;

      }

    },

  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      duration: "dashboard_stats/duration",
      orderStats: "order_stats/stats",
      storeOrders: "orders_module/storeOrders",
      storeOrdersCurrentPage: "orders_module/currentPage",
      storeOrdersLastPage: "orders_module/lastPage",
      isPending: "orders_module/isPending",
      storeAllCountries: 'countries_module/allCountries',
      orderSearchKeyword:'orders_module/searchedKeyword',
    }),
    addOrderFormCalculate(){

      if(this.addOrderForm.isOpen){

        this.addOrderForm.total_products = this.addOrderForm.items.length;

        this.addOrderForm.payment.amount = 0.0;

        let weight = 0;

        this.addOrderForm.items.forEach((item) => {

          if(item.type != 'bundle'){

            this.addOrderForm.payment.amount += item.variant.qty * item.variant.price;

            if(item.variant.weight){

              weight += item.variant.weight * item.variant.qty;

            }

          }else{

            this.addOrderForm.payment.amount += item.variant.price;

          }

        });

        this.addOrderForm.total_weight = parseFloat(weight).toFixed(2);

        //discount coupon implementation starts here
        if(this.coupon.length > 0){

          let discount = this.coupon[0].max_discount_value;
          let discountType = this.coupon[0].discount_type;
          
          if(discountType == "Flat"){

            if(discount >= this.addOrderForm.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.addOrderForm.payment.coupon_discounted_amount = 0;

              this.addOrderForm.coupon.coupon_code = "";

            }else{

              this.addOrderForm.payment.net_amount = (this.addOrderForm.payment.net_amount - discount).toFixed(2);

              this.addOrderForm.payment.coupon_discounted_amount = (discount).toFixed(2);

            }

          }else{

            let amount = (this.addOrderForm.payment.amount * (discount / 100)).toFixed(2);

            if(amount > this.addOrderForm.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.addOrderForm.payment.coupon_discounted_amount = 0;

              this.addOrderForm.coupon.coupon_code = "";

            }else{

              this.addOrderForm.payment.net_amount = (this.addOrderForm.payment.net_amount - amount).toFixed(2);

              this.addOrderForm.payment.coupon_discounted_amount = amount;

            }

          }

        }
        //discount coupon implementation ends here

        if(this.addOrderForm.payment.coupon_discounted_amount){

          let taxPercent = parseFloat(this.addOrderForm.payment.tax / 100).toFixed(2);
          let tempAmount = parseFloat(this.addOrderForm.payment.amount - this.addOrderForm.payment.coupon_discounted_amount);

          let percent_amount = (taxPercent * tempAmount).toFixed(2);

          let tempPercentAmount = parseFloat(percent_amount);

          this.addOrderForm.payment.tax_amount = tempPercentAmount;

          this.addOrderForm.payment.net_amount = (tempPercentAmount + (this.addOrderForm.payment.amount - this.addOrderForm.payment.coupon_discounted_amount)).toFixed(2);

        }else{

          let taxPercent = parseFloat(this.addOrderForm.payment.tax / 100).toFixed(2);
          let tempAmount = parseFloat(this.addOrderForm.payment.amount);

          let percent_amount = (taxPercent * tempAmount).toFixed(2);

          let tempPercentAmount = parseFloat(percent_amount);

          this.addOrderForm.payment.tax_amount = tempPercentAmount;

          this.addOrderForm.payment.net_amount = (tempPercentAmount + this.addOrderForm.payment.amount).toFixed(2);

        }

        if(this.addOrderForm.payment.shipping_amount){

          let tempShipping = parseFloat(this.addOrderForm.payment.shipping_amount);
          let tempNetAmount = parseFloat(this.addOrderForm.payment.net_amount);

          let tempAmount = (tempNetAmount + tempShipping).toFixed(2);

          this.addOrderForm.payment.net_amount = tempAmount;

        }

        return this.addOrderForm.items.length;

      }

    },
    updateOrderFormCalculate(){

      if(this.updateOrderForm.isPreview){

        this.updateOrderForm.total_products = this.updateOrderForm.items.length;

        this.updateOrderForm.payment.amount = 0.0;

        let weight = 0;

        this.updateOrderForm.items.forEach((item) => {

          if(item.type != 'bundle'){

            this.updateOrderForm.payment.amount += item.variant.qty * item.variant.price;

            if(item.variant.weight){

              weight += item.variant.weight * item.variant.qty;

            }

          }else{

            this.updateOrderForm.payment.amount += item.variant.price;

          }

        });

        this.updateOrderForm.total_weight = parseFloat(weight).toFixed(2);

        //discount coupon implementation starts here
        if(this.coupon.length > 0){

          let discount = this.coupon[0].max_discount_value;
          let discountType = this.coupon[0].discount_type;

          if(discountType == "Flat"){

            if(discount >= this.updateOrderForm.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.updateOrderForm.payment.discounted_amount = 0;

              this.updateOrderForm.coupon.coupon_code = "";

            }else{

              this.updateOrderForm.payment.net_amount = (this.updateOrderForm.payment.net_amount - discount).toFixed(2);

              this.updateOrderForm.payment.discounted_amount = (discount).toFixed(2);

            }

          }else{

            let amount = (this.updateOrderForm.payment.amount * (discount / 100)).toFixed(2);

            if(amount > this.updateOrderForm.payment.amount){

              this.$notify({
                type: "error",
                title: "Alert",
                message: "The provied coupon cannot be applied.",
              });

              this.coupon = [];

              this.updateOrderForm.payment.discounted_amount = 0;

              this.updateOrderForm.coupon.coupon_code = "";

            }else{

              this.updateOrderForm.payment.net_amount = (this.updateOrderForm.payment.net_amount - amount).toFixed(2);

              this.updateOrderForm.payment.discounted_amount = amount;

            }

          }

        }
        //discount coupon implementation ends here

        if(this.updateOrderForm.payment.discounted_amount){

          let taxPercent = parseFloat(this.updateOrderForm.payment.tax / 100).toFixed(2);
          let tempAmount = parseFloat(this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount);

          let percent_amount = (taxPercent * tempAmount).toFixed(2);

          let tempPercentAmount = parseFloat(percent_amount);

          this.updateOrderForm.payment.tax_amount = tempPercentAmount;

          this.updateOrderForm.payment.net_amount = (tempPercentAmount + (this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount)).toFixed(2);

        }else{

          let taxPercent = parseFloat(this.updateOrderForm.payment.tax / 100).toFixed(2);
          let tempAmount = parseFloat(this.updateOrderForm.payment.amount);

          let percent_amount = (taxPercent * tempAmount).toFixed(2);

          let tempPercentAmount= parseFloat(percent_amount);

          this.updateOrderForm.payment.tax_amount = tempPercentAmount;

          this.updateOrderForm.payment.net_amount = (tempPercentAmount + this.updateOrderForm.payment.amount).toFixed(2);

        }

        if(this.updateOrderForm.payment.shipping_amount){

          let tempShipping = parseFloat(this.updateOrderForm.payment.shipping_amount);
          let tempNetAmount = parseFloat(this.updateOrderForm.payment.net_amount);

          let tempAmount = (tempNetAmount + tempShipping).toFixed(2);

          this.updateOrderForm.payment.net_amount = tempAmount;

        }else if(this.updateOrderForm.payment.custom_shipping_amount && this.updateOrderForm.payment.is_custom_shipping){

          let tempShipping = parseFloat(this.updateOrderForm.payment.custom_shipping_amount);
          let tempNetAmount = parseFloat(this.updateOrderForm.payment.net_amount);

          let tempAmount = (tempNetAmount + tempShipping).toFixed(2);

          this.updateOrderForm.payment.net_amount = tempAmount;

        }

        return this.updateOrderForm.items.length;

      }

    },
    minDate(){

      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();

      if(month < 10){

        month = '0' + month.toString();

      }

      if(day < 10){

        day = '0' + day.toString();
        
      }

      var maxDate = year + '-' + month + '-' + day;

      return maxDate;

    },
  },
  watch: {
    regions:{
      handler:function(value){
        
        if(value.length > 0){

          if(this.addOrderForm.isOpen){

            this.addOrderForm.payment.shipping_rule_id = "";
            this.addOrderForm.payment.shipping_rule_price_id = "";
            this.addOrderForm.payment.shipping_amount = null;

            this.addOrderShippingRules = [];
            this.addOrderSelectedShipping = "";

            let availableShippingRegion = value[0];

            if(availableShippingRegion){

              if(availableShippingRegion.shipping.type == "Flat Rate"){

                this.addOrderShippingRules.rules = _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
                this.addOrderShippingRules.type = availableShippingRegion.shipping.type;

                this.addOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
                this.addOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

              }else if(availableShippingRegion.shipping.type == "Store Pickup Only"){

                this.addOrderShippingRules.rules = _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
                this.addOrderShippingRules.type = availableShippingRegion.shipping.type;

                this.addOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;
              
              }else if(availableShippingRegion.shipping.type == "Rate By Price"){

                this.addOrderShippingRules.rules = _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
                this.addOrderShippingRules.type = availableShippingRegion.shipping.type;
                this.addOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
                this.addOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

              }else if(availableShippingRegion.shipping.type == "Rate By Weight"){

                this.addOrderShippingRules.rules =  _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
                this.addOrderShippingRules.type = availableShippingRegion.shipping.type;
                this.addOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
                this.addOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

              }else if(availableShippingRegion.shipping.type == "Free Shipping"){

                this.addOrderShippingRules.rules =  _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
                this.addOrderShippingRules.type = availableShippingRegion.shipping.type;
                this.addOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
                this.addOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

              }

            }

          }else if(this.updateOrderForm.isPreview){

            this.updateOrderShippingRules = [];
            this.updateOrderSelectedShipping = "";

            let availableShippingRegion = value[0];

            if(availableShippingRegion.shipping.type == "Flat Rate"){

              this.updateOrderShippingRules.rules = _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
              this.updateOrderShippingRules.type = availableShippingRegion.shipping.type;

              this.updateOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
              this.updateOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

            }else if(availableShippingRegion.shipping.type == "Store Pickup Only"){

              this.updateOrderShippingRules.rules = _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
              this.updateOrderShippingRules.type = availableShippingRegion.shipping.type;

              this.updateOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

            }else if(availableShippingRegion.shipping.type == "Rate By Price"){

              this.updateOrderShippingRules.rules = _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
              this.updateOrderShippingRules.type = availableShippingRegion.shipping.type;
              this.updateOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
              this.updateOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

            }else if(availableShippingRegion.shipping.type == "Rate By Weight"){

              this.updateOrderShippingRules.rules =  _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
              this.updateOrderShippingRules.type = availableShippingRegion.shipping.type;
              this.updateOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
              this.updateOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

            }else if(availableShippingRegion.shipping.type == "Free Shipping"){

              this.updateOrderShippingRules.rules =  _.cloneDeep(availableShippingRegion.shipping.shipping_rules);
              this.updateOrderShippingRules.type = availableShippingRegion.shipping.type;
              this.updateOrderShippingRules.isNonCodDifferent = availableShippingRegion.shipping.is_non_cod_different;
              this.updateOrderShippingRules.shipping_id = availableShippingRegion.shipping_id;

              this.updateOrderShippingRules.is_free_shipping = true;

            }

          }

        }else{

          if(this.addOrderForm.isOpen){

            this.addOrderShippingRules = [];
            this.addOrderSelectedShipping = "";

            // this.addOrderForm.payment.shipping_rule_id = "";
            // this.addOrderForm.payment.shipping_rule_price_id = "";
            // this.addOrderForm.payment.shipping_amount = null;

            this.addOrderForm.payment = _.cloneDeep(this.tempOrder.payment)

          }else if(this.updateOrderForm.isPreview){

            this.updateOrderShippingRules = [];
            this.updateOrderSelectedShipping = "";

            this.updateOrderForm.payment = _.cloneDeep(this.tempOrder.payment)

          }

        }

      },deep:true
    },
    addOrderSelectedShipping:{
      handler:function(value){

        if(value){

          if(this.addOrderShippingRules.type == "Flat Rate"){

            this.customShippingCharges = null;

            this.addOrderForm.payment.is_custom_shipping = false;

            if(this.addOrderForm.payment.payment_method.type != "Cash on Delivery" && this.addOrderShippingRules.isNonCodDifferent){

              this.addOrderForm.payment.shipping_amount = parseFloat(value.rule_prices[0].non_cod_price);

              let foundPriceIndex = value.rule_prices.findIndex((rulePrice) => rulePrice.non_cod_price == this.addOrderForm.payment.shipping_amount)

              if(foundPriceIndex != -1){

                this.addOrderForm.payment.shipping_rule_price_id = value.rule_prices[foundPriceIndex].id;

              }

            }else{

              this.addOrderForm.payment.shipping_amount = parseFloat(value.rule_prices[0].price);

              let foundPriceIndex = value.rule_prices.findIndex((rulePrice) => rulePrice.price == this.addOrderForm.payment.shipping_amount)

              if(foundPriceIndex != -1){

                this.addOrderForm.payment.shipping_rule_price_id = value.rule_prices[foundPriceIndex].id;

              }

            }

            this.addOrderForm.payment.shipping_rule_id = value.id


            this.addOrderForm.payment.amount = 0

          }else if(this.addOrderShippingRules.type == "Store Pickup Only"){

            this.customShippingCharges = null;

            this.addOrderForm.payment.is_custom_shipping = false;

            this.addOrderForm.payment.shipping_rule_id = value.id

          }else if(this.addOrderShippingRules.type == "Rate By Price"){

            this.customShippingCharges = null;

            this.addOrderForm.payment.is_custom_shipping = false;


            let amount = 0;

            if(this.addOrderForm.payment.coupon_discounted_amount){

              amount = (this.addOrderForm.payment.amount - this.addOrderForm.payment.coupon_discounted_amount + this.addOrderForm.payment.tax_amount).toFixed(2);
              
            }else{

              amount = this.addOrderForm.payment.amount + this.addOrderForm.payment.tax_amount;

            }

            // let amount = this.addOrderForm.payment.amount;
            let foundPrice = 0;
            let val = [];

            value.rule.rule_prices.forEach((price) => {

              if(amount >= parseFloat(price.from).toFixed(2) && amount <= parseFloat(price.to).toFixed(2)){

                if(this.addOrderForm.payment.payment_method.type != "Cash on Delivery" && this.addOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = price.id;

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = price.id;

                }

                val = price;

              }else if(amount > parseFloat(price.from) && (price.to == "" || price.to == null)){

                if(this.addOrderForm.payment.payment_method.type != "Cash on Delivery" && this.addOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = price.id;

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = price.id;

                }

                val = price;

              }

            });

            this.addOrderForm.payment.shipping_amount = foundPrice;

            this.addOrderForm.payment.shipping_rule_id = value.rule.id

            // this.addOrderForm.payment.amount = 0

          }else if(this.addOrderShippingRules.type == "Rate By Weight"){

            this.customShippingCharges = null;

            this.addOrderForm.payment.is_custom_shipping = false;


            let amount = parseFloat(this.addOrderForm.total_weight).toFixed(2);

            let foundPrice = 0;
            let val = [];

            value.rule.rule_prices.forEach((value) => {

              if((parseFloat(amount) >= parseFloat(value.from).toFixed(2) && parseFloat(amount) <= parseFloat(value.to).toFixed(2)) && (value.to != "" || value.to != null)){
                
                if(this.addOrderForm.payment.payment_method.type != "Cash on Delivery" && this.addOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(value.non_cod_price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = value.id;

                }else{

                  foundPrice = parseFloat(value.price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = value.id;

                }

                val = value;

              }else if(parseFloat(amount) > parseFloat(value.from).toFixed(2) &&(value.to == "" || value.to == null)){

                if(this.addOrderForm.payment.payment_method.type != "Cash on Delivery" && this.addOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(value.non_cod_price).toFixed(2);
                  
                  this.addOrderForm.payment.shipping_rule_price_id = value.id;

                }else{

                  foundPrice = parseFloat(value.price).toFixed(2);

                  this.addOrderForm.payment.shipping_rule_price_id = value.id;

                }

                val = value;
              }

            });

            this.addOrderForm.payment.shipping_amount = foundPrice;

            this.addOrderForm.payment.shipping_rule_id = value.rule.id

          }else if(this.addOrderShippingRules.type == "Free Shipping"){

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.addOrderShippingRules.is_free_shipping = true;

          }
        
        }

      },deep:true
    },
    updateOrderSelectedShipping:{
      handler:function(value){

        if(value){
        
          if(this.updateOrderShippingRules.type == "Flat Rate"){

            this.customShippingCharges = null;

            this.updateOrderForm.payment.is_custom_shipping = false;

            if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderShippingRules.isNonCodDifferent){

              this.updateOrderForm.payment.shipping_amount = parseFloat(value.rule_prices[0].non_cod_price);

              let foundPriceIndex = value.rule_prices.findIndex((rulePrice) => rulePrice.non_cod_price == this.updateOrderForm.payment.shipping_amount)

              if(foundPriceIndex != -1){

                this.updateOrderForm.payment.shipping_rule_price_id = value.rule_prices[foundPriceIndex].id;

              }

            }else{

              this.updateOrderForm.payment.shipping_amount = parseFloat(value.rule_prices[0].price);

              let foundPriceIndex = value.rule_prices.findIndex((rulePrice) => rulePrice.price == this.updateOrderForm.payment.shipping_amount)

              if(foundPriceIndex != -1){

                this.updateOrderForm.payment.shipping_rule_price_id = value.rule_prices[foundPriceIndex].id;

              }

            }

            this.updateOrderForm.payment.is_free_shipping = false;

            this.updateOrderForm.payment.shipping_rule_id = value.id

          }else if(this.updateOrderShippingRules.type == "Store Pickup Only"){

            this.customShippingCharges = null;

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderForm.payment.is_free_shipping = false;

            this.updateOrderForm.payment.shipping_rule_id = value.id

          }else if(this.updateOrderShippingRules.type == "Rate By Price"){

            this.customShippingCharges = null;

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderForm.payment.is_free_shipping = false;
            
            let amount = 0;

            if(this.updateOrderForm.payment.discounted_amount){

              amount = (this.updateOrderForm.payment.amount - this.updateOrderForm.payment.discounted_amount + this.updateOrderForm.payment.tax_amount).toFixed(2);

            }else{

              amount = this.updateOrderForm.payment.amount + this.updateOrderForm.payment.tax_amount;

            }

            // let amount = this.updateOrderForm.payment.amount;
            let foundPrice = 0;
            let val = [];

            value.rule.rule_prices.forEach((price) => {

              if(amount >= parseFloat(price.from).toFixed(2) && 
              amount <= parseFloat(price.to).toFixed(2)){

                if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }

                val = price;

              }else if(amount > parseFloat(price.from) && 
              (price.to == "" || price.to == null)){

                if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && this.updateOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(price.non_cod_price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }else{

                  foundPrice = parseFloat(price.price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = price.id;

                }

                val = price;

              }

            });

            this.updateOrderForm.payment.shipping_amount = foundPrice;

            this.updateOrderForm.payment.shipping_rule_id = value.rule.id

          }else if(this.updateOrderShippingRules.type == "Rate By Weight"){

            this.customShippingCharges = null;

            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderForm.payment.is_free_shipping = false;

            let amount = parseFloat(this.updateOrderForm.total_weight).toFixed(2);

            let foundPrice = 0;
            let val = [];

            value.rule.rule_prices.forEach((value) => {

              if((parseFloat(amount) >= parseFloat(value.from).toFixed(2) && 
              parseFloat(amount) <= parseFloat(value.to).toFixed(2)) && 
              (value.to != "" || value.to != null)){
                
                if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && 
                this.updateOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(value.non_cod_price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = value.id;

                }else{

                  foundPrice = parseFloat(value.price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = value.id;

                }

                val = value;

              }else if(parseFloat(amount) > parseFloat(value.from).toFixed(2) && 
              (value.to == "" || value.to == null)){

                if(this.updateOrderForm.payment.payment_method.type != "Cash on Delivery" && 
                this.updateOrderShippingRules.isNonCodDifferent){

                  foundPrice = parseFloat(value.non_cod_price).toFixed(2);
                  
                  this.updateOrderForm.payment.shipping_rule_price_id = value.id;

                }else{

                  foundPrice = parseFloat(value.price).toFixed(2);

                  this.updateOrderForm.payment.shipping_rule_price_id = value.id;

                }

                val = value;
              }

            });

            this.updateOrderForm.payment.shipping_amount = foundPrice;

            this.updateOrderForm.payment.shipping_rule_id = value.rule.id

          }else if(this.updateOrderShippingRules.type == "Free Shipping"){
            
            this.updateOrderForm.payment.is_custom_shipping = false;

            this.updateOrderShippingRules.is_free_shipping = true;

          }
        
        }

      },deep:true
    },
    storeOrders:{
      handler:function(val){

        this.orders = val;
        this.ordersLoading = false;

        if(this.isCreateNew){

          this.newOrderOperations('open',null);

          this.isCreateNew = false;

        }

        if(this.isMasterSearched){

          this.searchedOrderPreview(this.orders[0],0);

          this.isMasterSearched = false;

        }

      },
      deep: true,
    },
    storeAllCountries:{
      handler:function(value){

        this.availableCountries = _.cloneDeep(value);

        this.availableCountries.forEach((country)=>{

          this.allowedCountries.push(country.iso2);

        });

      },deep:true
    },
    addOrderForm:{
      handler:function(value){

        if(value.order_date || value.delivery_date  || 
        value.status || value.coupon_code || value.order_notes || 
        value.total_products || value.shipping || value.items || 
        value.customer.first_name || value.total_weight || 
        value.payment.net_amount > 0 || value.payment.coupon_discounted_amount ||
        value.activities.length > 0 || value.notes.length > 0){

          value.isTouched = true;

        }else{

          value.isTouched = false;

        }

      },deep:true
    },
  },
  mounted(){

    this.$refs.newOrdertable.addEventListener("scroll", this.handleScrollEvent);
    
    window.addEventListener("keydown", this.ordersKeyEvent);

    //add form top navigation scroll handle starts here
    const targetObserver = document.querySelector('#order-details-preview');

    const options = {
      root: targetObserver.parentElement, // Use the parent as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the target is visible
    };

    const intersectionCallback = (entries, observer) => {

      entries.forEach(entry => {

        if(entry.isIntersecting){

          if(entry.target.id == 'orderActivity' || entry.target.id == 'shipping-options-section' ||
          entry.target.id == 'orderActivitySection'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#order-activity-navbtn').classList.add('active');

          }else if(entry.target.id == 'customerDetail' || entry.target.id == 'customerSection'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#customer-details-navbtn').classList.add('active');

          }else if(entry.target.id == 'orderDetail' || entry.target.id == 'order-total-section'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#order-details-navbtn').classList.add('active');

          }else{

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

          }

        }

      });

    };

    const observer = new IntersectionObserver(intersectionCallback, options);

    const productTableInfoElements = document.querySelectorAll('.productTableInfoWhiteBox');

    productTableInfoElements.forEach(element => {

      observer.observe(element);

    });
    //add form top navigation scroll handle ends here

    //update form top navigation scroll handle starts here
    const updateFormTargetObserver = document.querySelector('#update-order-details-preview');

    const updateFormOptions = {
      root: updateFormTargetObserver.parentElement, // Use the parent as the root
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the target is visible
    };

    const updateFormIntersectionCallback = (entries, observer) => {

      entries.forEach(entry => {

        if(entry.isIntersecting){

          if(entry.target.id == 'orderActivity' || entry.target.id == 'update-shipping-options-section' ||
          entry.target.id == 'updateOrderActivitySection'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#update-order-activity-navbtn').classList.add('active');

          }else if(entry.target.id == 'updateOrderCustomerDetail' || entry.target.id == 'updateCustomerSection'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#update-customer-details-navbtn').classList.add('active');

          }else if(entry.target.id == 'updateOrderDetail' || entry.target.id == 'update-order-total-section'){

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

            document.querySelector('#update-order-details-navbtn').classList.add('active');

          }else{

            document.querySelectorAll(".sectionTabsLink ul li a.active")
            .forEach(function (item) {
              item.classList.remove("active");
            });

          }

        }

      });

    };

    const updateObserver = new IntersectionObserver(updateFormIntersectionCallback, updateFormOptions);

    const updateProductTableInfoElements = document.querySelectorAll('.productTableInfoWhiteBox');

    updateProductTableInfoElements.forEach(element => {

      updateObserver.observe(element);

    });
    //update form top navigation scroll handle ends here

  },
  beforeDestroy(){

    window.removeEventListener("keydown", this.ordersKeyEvent);

  },
  async beforeMount(){
    
    try{
      if((!this.storeOrders || this.storeOrders.length == 0 || this.storeOrders.error)){

        if(this.$parent.$options.name && this.$parent.$options.name == 'orders-main'){

          if(this.orderSearchKeyword.length == 0){

            this.ordersLoading = true;

            this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

          }

        }else{

          this.ordersLoading = true;

          this.$store.commit("orders_module/remove_searched_keyword");

          this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

        }

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

      }else if(this.$parent.$options.name == 'dashboard'){

        this.$store.commit("orders_module/remove_searched_keyword");

        this.$store.dispatch("orders_module/fetchStoreOrdersRequest",{size: 15, page: 1, loadData: 'new'});

      }else{

        this.orders = this.storeOrders;
        this.ordersLoading = false;

        if(this.$route.params && this.$route.params.isCreateNew){

          this.isCreateNew = true;

        }

        if(this.isCreateNew){

          this.newOrderOperations('open',null);

          this.isCreateNew = false;

        }

      }

      this.loadCustomers();

      if(!this.storeAllCountries || this.storeAllCountries.length == 0){

        this.$store.dispatch('countries_module/fetchAllCountries');

      }else{

        this.availableCountries = this.storeAllCountries;

        this.availableCountries.forEach((country)=>{

          this.allowedCountries.push(country.iso2);

        });

      }

      this.searchCartItems('products', 'simple', 1);

      let getPaymentMethod = await this.$axios.get("/getPaymentMethods",{
        params:{
          active: true
        }
      });

      this.paymentMethodsList = getPaymentMethod.data.payment_methods.map((pMethod) => {

        if(pMethod.type != 'Cash on Delivery'){

          pMethod.$isDisabled = true;

        }

        return pMethod;

      });

    }catch(error){

      this.ordersLoading = false;
      
      this.$message({
        type: "error",
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }

  },
};
window.myFunction = function (event) {

  if(!this.tourIsActive){

    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active")
    .forEach(function (item) {

      item.classList.remove("active");

    });

    // mark as active selected menu item
    event.target.classList.add("active");

  }

};
</script>
